import React, { useEffect, useRef } from "react";

import styled from "styled-components/native";
import Loader from "@app/components/animated/loader";
import { QuestPublicPreviewView } from "@app/components/screen/quest/QuestPublicPreviewView";

import PlaceholderView from "@app/components/screen/PlaceholderView";
import { useQuestPrototype } from "@app/quest/useQuestPrototype";
import { useAppNavigation, useAppRoute } from "@app/navigation/QMNavigator";
import { Analytics } from "@app/analytics";
import { useAppSelector } from "@app/store";
import { selectQuestPrototypeById } from "@app/store/cache/questPrototypes";
import { ScreenContainer } from "@app/screens/ScreenContainer";
import { PreviewQuestViewContextProvider } from "@app/quest/edit/PreviewQuestViewContextProvider";
import { fetchLibraryTemplate } from "@app/util/client/requests/library";
import { useRequest } from "@app/util/client/requests";

export const PublicTemplatePreviewScreen: React.FC = () => {
  const { questPrototypeId, screenshot } =
    useAppRoute<"PublicTemplatePreview">().params || {};
  const navigation = useAppNavigation<"PublicTemplatePreview">();
  const { questPrototype, error, isLoading, refresh } =
    useQuestPrototype(questPrototypeId);
  const { data: quest } = useRequest(
    fetchLibraryTemplate(questPrototype?.quest.id ?? null)
  );

  const recordPageLoadRef = useRef(false);
  useEffect(() => {
    if (!recordPageLoadRef.current && quest) {
      Analytics.trackEvent("View Public Template Preview", {
        questPrototypeId,
        questId: quest.id,
        templateVisibility: quest.templateVisibility,
      });
      recordPageLoadRef.current = true;
    }
  }, [quest, questPrototypeId]);

  // useEffectOnce(() => {
  //   switch (status) {
  //     case "purchase-template-success":
  //       Analytics.trackEvent("Redirect From Successful Template Purchase");
  //       break;
  //     case "purchase-template-cancelled":
  //       Analytics.trackEvent("Redirect From Cancelled Template Purchase");
  //       break;
  //     case "purchase-subscription-success":
  //       Analytics.trackEvent("Redirect From Successful Subscription Purchase");
  //       break;
  //     default:
  //       break;
  //   }
  //   if (status) {
  //     navigation.setParams({ status: undefined });
  //   }
  // });

  const questName = useAppSelector(
    (state) => selectQuestPrototypeById(state, questPrototypeId)?.name || ""
  );
  useEffect(() => {
    navigation.setOptions({
      headerTitle: questName,
    });
  }, [navigation, questName]);

  if (!questPrototype) {
    if (error) {
      return (
        <StyledView>
          <PlaceholderView
            text="Oops, that didn't quite work."
            actions={[
              {
                type: "primary",
                text: "Reload",
                loading: isLoading,
                onPress: refresh,
              },
            ]}
          />
        </StyledView>
      );
    } else {
      return (
        <StyledView>
          <Loader />
        </StyledView>
      );
    }
  }

  return (
    <ScreenContainer>
      <PreviewQuestViewContextProvider
        questId={questPrototype.quest.id}
        questPrototypeId={questPrototypeId}
      >
        <QuestPublicPreviewView
          viewMode={
            screenshot
              ? "PUBLIC_TEMPLATE_SCREENSHOT"
              : "PUBLIC_TEMPLATE_PREVIEW"
          }
          // initialUseQuestModalPosition={
          //   action === "use-quest" ? "OPEN" : "CLOSED"
          // }
        />
      </PreviewQuestViewContextProvider>
    </ScreenContainer>
  );
};

const StyledView = styled.View`
  background-color: ${({ theme }) => theme.background};
  flex: 1;
  justify-content: center;
  align-items: center;
`;
