import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import styled, { useTheme } from "styled-components/native";
import Loader from "@app/components/ThreeDotsLoader";
import {
  createSideBarItemRowIcon,
  SideBarItem,
} from "@app/navigation/sideBar/items/SideBarItem";
import { fetchWorkspaces } from "@app/util/client/requests/workspaces";
import { AppState, useAppDispatch, useAppSelector } from "@app/store";
import { selectUsersWorkspaces } from "@app/store/cache/workspaces";
import {
  StandardDropdownListItem,
  StandardInlineDropdown,
} from "@app/components/questkit/dropdown/StandardInlineDropdown";
import QKModal from "@app/components/modal";
import CreateWorkspace from "@app/components/modal/createWorkspace";
import { fetchQuests } from "@app/util/client/requests/quests";
import { QMRoute } from "@app/navigation/QMNavigator";
import { selectActiveWorkspaceId, setActiveWorkspace } from "@app/store/UI";
import {
  selectAllQuestPrototypesById,
  selectQuestPrototypeById,
} from "@app/store/cache/questPrototypes";
import { createSelector } from "@reduxjs/toolkit";
import { selectQuestById, selectUsersQuests } from "@app/store/cache/quests";
import { SideBarWorkspaceQuestItem } from "@app/navigation/sideBar/items/SideBarWorkspaceQuestItem";
import { useEffectOnce } from "@app/util/useEffectOnce";
import { navigationRef } from "@app/navigation/QMNavigationContainer";
import { useRequest } from "@app/util/client/requests";
import CreateQuestDialog from "@app/components/modal/createQuest";
import { selectQuestInstanceById } from "@app/store/cache/questInstances";
import isEqual from "react-fast-compare";

// const TEAMS_ENABLED = ENV.featureFlags.enableTeams;

export const SideBarWorkspaceSection: React.FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const activeWorkspaceId = useAppSelector(
    (state) => state.ui.sideBar.activeWorkspaceId
  );

  const { isValidating: isLoadingQuestData, hasData: hasQuestData } =
    useRequest(
      fetchQuests({
        workspaceId: activeWorkspaceId === null ? "null" : activeWorkspaceId,
      })
    );
  const questsLoading = isLoadingQuestData && !hasQuestData;
  const [focusedRoute, setFocusedRoute] = useState<QMRoute | undefined>(
    undefined
  );
  useEffectOnce(() => {
    // update focus route on state change
    return navigationRef.addListener("state", (event) => {
      const state = event.data.state;
      setFocusedRoute(state?.routes?.[state.index ?? 0] as QMRoute);
    });
  });

  const questIdForFocusedRoute = useAppSelector((state) =>
    getQuestIdForRoute(state, focusedRoute)
  );
  const workspaceIdForFocusedQuest = useAppSelector((state) =>
    selectWorkspaceIdForActiveQuest(state, questIdForFocusedRoute)
  );

  const allQuestsForActiveWorkspace = useAppSelector(
    selectQuestDataForActiveWorkspace,
    isEqual
  );
  const questsToList = useMemo(() => {
    return allQuestsForActiveWorkspace.filter(
      (quest) => !quest.archived || quest.id === questIdForFocusedRoute
    );
  }, [allQuestsForActiveWorkspace, questIdForFocusedRoute]);

  useEffect(() => {
    if (
      workspaceIdForFocusedQuest !== undefined &&
      activeWorkspaceId !== workspaceIdForFocusedQuest
    ) {
      // null is a valid value for workspaceIdForActiveQuest
      dispatch(setActiveWorkspace(workspaceIdForFocusedQuest));
    }
    // only run this effect when the workspace for the active quest changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceIdForFocusedQuest]);

  const onWorkspaceChange = useCallback(
    (workspaceId: string | null) => dispatch(setActiveWorkspace(workspaceId)),
    [dispatch]
  );

  const workspaceSettingsScreenParams = useMemo(
    () => ({ workspaceId: activeWorkspaceId! }),
    [activeWorkspaceId]
  );
  return (
    <>
      <WorkspaceDropdown
        activeWorkspaceId={activeWorkspaceId}
        onChange={onWorkspaceChange}
      />
      {questsLoading ? (
        <>
          <WorkspaceLoaderContainer>
            <Loader
              background={theme.sideBar.text}
              activeBackground={theme.sideBar.text}
            />
          </WorkspaceLoaderContainer>
        </>
      ) : (
        <>
          {questsToList.map((quest) => (
            <SideBarWorkspaceQuestItem
              key={`questDrawerItem-${quest.id}`}
              questId={quest.id}
              name={quest.name}
              isOnboardingCompleted={quest.isOnboardingCompleted}
              isActiveOverride={
                questIdForFocusedRoute === quest.id ? true : undefined
              }
            />
          ))}

          <WorkspaceOptions>
            <CreateQuestSideBarItem />
            {activeWorkspaceId ? (
              <SideBarItem
                label="Workspace Settings"
                componentLeft={GroupIcon}
                screenName={"Workspace"}
                screenParams={workspaceSettingsScreenParams}
              />
            ) : null}
          </WorkspaceOptions>
        </>
      )}
    </>
  );
};

const WorkspaceDropdown: React.FC<{
  activeWorkspaceId: string | null;
  onChange: (selectedWorkspaceId: string | null) => void;
}> = ({ activeWorkspaceId, onChange }) => {
  const theme = useTheme();
  const [showCreateWorkspaceModal, setShowCreateWorkspaceModal] =
    useState(false);
  const { hasData: hasWorkspaceListData, isValidating: isLoadingWorkspaces } =
    useRequest(fetchWorkspaces());
  const workspaces = useAppSelector(selectUsersWorkspaces, isEqual);

  let workspaceOptions: StandardDropdownListItem<string | null>[];
  let waitingForFirstLoad: boolean;
  let isLoadingTeams: boolean;

  // TODO: Uncomment and re-enable grouped options in dropdown
  // if (TEAMS_ENABLED) {
  //   const { hasData: hasTeamData, isValidating: _isLoadingTeams } = useRequest(
  //     fetchTeams()
  //   );
  //   isLoadingTeams = _isLoadingTeams;
  //   const teams = useAppSelector(selectUsersTeams, isEqual);
  //   waitingForFirstLoad =
  //     (isLoadingWorkspaces &&
  //       !hasWorkspaceListData &&
  //       workspaces.length === 0) ||
  //     (isLoadingTeams && !hasTeamData && teams.length === 0);
  //
  //   workspaceOptions = useMemo((): StandardDropdownListItem<
  //     string | null
  //   >[] => {
  //     if (!workspaces || !teams) {
  //       return [];
  //     }
  //
  //     const teamOptionGroups = teams.map(
  //       ({ id, name }): OptionGroup<string> & { id: string } => ({
  //         id,
  //         label: name,
  //         options: [],
  //       })
  //     );
  //
  //     return workspaces
  //       ? [
  //           { label: "Personal", value: null },
  //           ...(workspaces || []).reduce((acc, workspace) => {
  //             if (workspace.teamId) {
  //               const existingTeamOptionGroup = acc.find(
  //                 (optionGroup) =>
  //                   (optionGroup as unknown as { id: string }).id ===
  //                   workspace.teamId
  //               );
  //               if (existingTeamOptionGroup) {
  //                 (existingTeamOptionGroup as OptionGroup<string>).options.push(
  //                   {
  //                     label: workspace.name,
  //                     value: workspace.id,
  //                   }
  //                 );
  //               } else {
  //                 acc.push({
  //                   label: workspace.name,
  //                   value: workspace.id,
  //                 });
  //               }
  //             }
  //
  //             return acc;
  //           }, teamOptionGroups as StandardDropdownListItem<string>[]),
  //         ]
  //       : [];
  //   }, [teams, workspaces]);
  // } else {
  // TODO: FINISH_TEAMS_FEATURE - Remove this block when teams are enabled
  // eslint-disable-next-line prefer-const
  waitingForFirstLoad =
    isLoadingWorkspaces && !hasWorkspaceListData && workspaces.length === 0;

  // eslint-disable-next-line prefer-const
  isLoadingTeams = false;

  // eslint-disable-next-line prefer-const
  workspaceOptions = useMemo(
    () =>
      workspaces
        ? [
            { label: "Personal", value: null },
            ...(workspaces || []).map(({ id, name }) => ({
              label: name,
              value: id,
            })),
          ]
        : [],
    [workspaces]
  );
  // }

  useEffect(() => {
    if (activeWorkspaceId !== null) {
      if (!waitingForFirstLoad) {
        const workspaceIsValid = workspaces.some(
          ({ id }) => id === activeWorkspaceId
        );
        if (!workspaceIsValid) {
          const firstWorkspace = workspaces.sort((a, b) =>
            a.name.localeCompare(b.name, undefined, {})
          )[0];
          if (firstWorkspace) {
            onChange(firstWorkspace.id);
          } else if (!isLoadingWorkspaces && hasWorkspaceListData) {
            // if user has no workspaces then choose their personal workspace.
            onChange(null);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaces, activeWorkspaceId, waitingForFirstLoad]);

  const onCreate = useCallback(() => {
    setShowCreateWorkspaceModal(true);
  }, []);

  const themeOverride = useMemo(
    () => ({
      primary: theme.sideBar.text,
      background: theme.sideBar.scrollerBackground,
      groupHeaderTextColor: theme.inactive,
    }),
    [theme]
  );

  const onSelectWorkspace = useCallback(
    (selection?: StandardDropdownListItem<string | null>) => {
      onChange(selection?.value ?? null);
    },
    [onChange]
  );
  return (
    <>
      <WorkspaceDropdownContainer>
        {!waitingForFirstLoad ? (
          <StandardInlineDropdown
            value={activeWorkspaceId}
            options={workspaceOptions}
            optionNoun={"Workspace"}
            optionPluralNoun={"Workspaces"}
            loadingOptions={isLoadingWorkspaces || isLoadingTeams}
            onSelect={onSelectWorkspace}
            inlineStyle={true}
            onCreate={onCreate}
            themeOverride={themeOverride}
          />
        ) : null}
      </WorkspaceDropdownContainer>
      <QKModal
        showModal={showCreateWorkspaceModal}
        setShowModal={setShowCreateWorkspaceModal}
        title="Create Workspace"
      >
        <CreateWorkspace setShowModal={setShowCreateWorkspaceModal} />
      </QKModal>
    </>
  );
};

const WorkspaceDropdownContainer = styled.View`
  height: 50px;
`;

const WorkspaceLoaderContainer = styled.View`
  margin-top: 16px;
`;
const WorkspaceOptions = styled.View`
  margin-top: 20px;
`;

const PlusIcon = createSideBarItemRowIcon("plus");
const GroupIcon = createSideBarItemRowIcon("group");

const CreateQuestSideBarItem: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const onPress = useCallback(() => setShowModal(true), []);
  return (
    <>
      <SideBarItem
        label="Create Quest"
        componentLeft={PlusIcon}
        onPress={onPress}
      />
      <QKModal
        showModal={showModal}
        setShowModal={setShowModal}
        title="New Quest"
      >
        <CreateQuestDialog
          createButtonText="Create Quest"
          setShowModal={setShowModal}
        />
      </QKModal>
    </>
  );
};

function getQuestIdForRoute(
  appState: AppState,
  route: QMRoute | undefined
): string | undefined {
  switch (route?.name) {
    case "AdminQuestRun":
      return route.params?.templateId;
    case "PublicTemplatePreview":
      return selectQuestPrototypeById(appState, route.params?.questPrototypeId)
        ?.questId;
    case "Quest":
      return route.params?.questId;
    case "QuestInstance": {
      const questInstanceId = route.params?.id;
      if (!questInstanceId) {
        return undefined;
      }
      const questPrototypeId = selectQuestInstanceById(
        appState,
        questInstanceId
      )?.prototypeId;
      if (!questPrototypeId) {
        return undefined;
      }
      return selectQuestPrototypeById(appState, questPrototypeId)?.questId;
    }
  }

  return undefined;
}

const selectWorkspaceIdForActiveQuest = createSelector(
  [
    (state: AppState, activeQuestId: string | undefined) => {
      return activeQuestId ? selectQuestById(state, activeQuestId) : undefined;
    },
    (state) => selectUsersQuests(state),
  ],
  (activeQuest, usersQuests) => {
    if (activeQuest) {
      // Only set workspace id when Quest is shown in the sidebar
      const activeQuestIsOneOfUsersQuests = usersQuests.some(
        ({ id }) => id === activeQuest.id
      );
      if (activeQuestIsOneOfUsersQuests) {
        return activeQuest?.workspaceId;
      }
    }
    return undefined;
  }
);

const selectQuestDataForActiveWorkspace = createSelector(
  [
    (state) => selectUsersQuests(state),
    (state) => selectAllQuestPrototypesById(state),
    (state) => selectActiveWorkspaceId(state),
  ],
  (quests, questPrototypes, activeWorkspaceId) =>
    (
      quests
        .filter((quest) => quest.workspaceId === activeWorkspaceId)
        .map((quest) => {
          const prototypeId = quest.currentQuestPrototypeId;
          const name = prototypeId
            ? questPrototypes[prototypeId]?.name
            : undefined;
          return {
            id: quest.id,
            name,
            isOnboardingCompleted: quest.lastRunAt !== null,
            archived: quest.archived,
          };
        })
        .filter((quest) => Boolean(quest.name)) as {
        id: string;
        name: string;
        isOnboardingCompleted: boolean;
        archived: boolean;
      }[]
    ).sort((a, b) => a.name.localeCompare(b.name, undefined, {}))
);
