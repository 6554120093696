import React, { useCallback, useRef } from "react";
import { CompletionActionComponentProps as CompletionActionBaseProps } from "@app/components/completionAction";
import Text from "@app/components/questkit/text";
import { ActivityIndicator, View } from "react-native";
import TextInput from "@app/components/questkit/textInput";
import { CompletionActionRenderData } from "@app/types/completionActionRenderData";
import { LinkText } from "@app/components/LinkText";
import styled, { useTheme } from "styled-components/native";
import Icon from "@app/components/icon";
import RewardContainer from "@app/components/completionAction/components/RewardContainer";
import { fetchQuest } from "@app/util/client/requests/quests";
import { noopRequest, useRequest } from "@app/util/client/requests";
import { InfoText } from "@app/components/questkit/InfoText";
import ListItem from "@app/components/questkit/listItem";
import {
  isViewContext,
  useQuestViewContext,
} from "@app/quest/QuestViewContext";

const changeSourceTemplateId = (
  item: CompletionActionRenderData,
  sourceTemplateId: string
): CompletionActionRenderData => ({
  ...item,
  data: {
    ...item.data,
    sourceTemplateId,
  },
  version: item.version + 1,
});

export const CreateQuestReward: React.FC<CompletionActionBaseProps> = ({
  item,
  onCompletionActionChange,
  editMode,
}) => {
  const questViewContext = useQuestViewContext();

  const sourceTemplateId = item.data.sourceTemplateId;

  const {
    data: sourceQuestData,
    error: sourceQuestError,
    isValidating: loadingSourceQuest,
  } = useRequest(
    isViewContext(questViewContext, "RUN")
      ? noopRequest()
      : fetchQuest(sourceTemplateId)
  );

  const itemRef = useRef<CompletionActionRenderData>(item);
  itemRef.current = item;

  const onChangeSourceTemplateId = useCallback(
    (sourceTemplateId: string) =>
      onCompletionActionChange(
        changeSourceTemplateId(itemRef.current, sourceTemplateId)
      ),
    [onCompletionActionChange]
  );

  const theme = useTheme();
  const questName = sourceQuestData?.currentQuestPrototype?.name;
  return (
    <>
      {questViewContext.viewContext === "PREVIEW" ? (
        <RunViewContainer>
          <StatusText size="large">
            Clone and prepare {questName ? `"${questName}"` : "Quest"}
          </StatusText>
        </RunViewContainer>
      ) : questViewContext.viewContext === "MANAGE" ? (
        editMode ? (
          // eslint-disable-next-line deprecation/deprecation
          <RewardContainer
            blockNode={
              <View>
                <Row>
                  <Icon icon="NONE" />
                  <Text size="mediumLargeBold">Source Quest ID</Text>
                </Row>
                <TextInput
                  value={sourceTemplateId}
                  onChangeText={onChangeSourceTemplateId}
                  placeholder="12345678-1234-1234-1234-123456789012"
                />

                {sourceTemplateId && (
                  <>
                    {!sourceQuestData && loadingSourceQuest && (
                      <InfoText text={<ActivityIndicator size="small" />} />
                    )}
                    {sourceQuestError && !loadingSourceQuest && (
                      <ListItem
                        icon="close"
                        text="There was an error retrieving info on the Source Quest. Please ensure the Source Quest ID is valid and that you have permission to access it."
                      />
                    )}
                    {sourceQuestData && (
                      <>
                        <ListItem
                          icon="link"
                          text={
                            <>
                              <Text>Source Quest: </Text>
                              <LinkText
                                to={{
                                  screen: "Quest",
                                  params: {
                                    questId: sourceTemplateId,
                                    screen: "QuestConfiguration",
                                    params: {},
                                  },
                                }}
                              >
                                {questName}
                              </LinkText>
                            </>
                          }
                        />
                        {["PUBLIC", "PUBLIC_UNLISTED"].includes(
                          sourceQuestData.templateVisibility
                        ) ? (
                          <ListItem
                            icon="checkmark"
                            text="The Source Quest provided is publicly accessible."
                          />
                        ) : (
                          <StyledInfoLine>
                            <Icon
                              icon="hand"
                              size={24}
                              style={{ color: theme.warning }}
                            />
                            <Text $warning={true}>
                              The Source Quest provided is not public! Ensure
                              users completing this Quest will have access to
                              clone the Source Quest!
                            </Text>
                          </StyledInfoLine>
                        )}
                      </>
                    )}
                  </>
                )}
              </View>
            }
            title={"Create and configure Quest"}
          />
        ) : (
          <RunViewContainer>
            <StatusText size="large">
              Clone and prepare {questName ? `"${questName}"` : "Quest"}
            </StatusText>
          </RunViewContainer>
        )
      ) : (
        <RunViewContainer>
          <StatusText size="large">
            {item.status === "WAITING_TO_START"
              ? questViewContext.recentlySubmittedByUser
                ? "Waiting to start..."
                : ""
              : item.status === "PENDING"
              ? "Creating Quest..."
              : item.status === "COMPLETED"
              ? "Quest Created Successfully"
              : "Quest Creation Failed"}
          </StatusText>
          {item.status === "COMPLETED" && (
            <LinkText
              to={{
                screen: "Quest",
                params: {
                  questId: item.data.createdQuestId,
                  screen: "QuestConfiguration",
                  params: {},
                },
              }}
            >
              Go to created Quest
            </LinkText>
          )}
        </RunViewContainer>
      )}
    </>
  );
};

const StatusText = styled(Text)`
  margin-bottom: 16px;
`;
const RunViewContainer = styled.View`
  margin-left: 14px;
`;
const StyledInfoLine = styled.View`
  margin-top: 20px;
  flex-direction: row;
`;

const Row = styled.View`
  margin-top: 20px;
  flex-direction: row;
  align-items: center;
`;
