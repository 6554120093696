import React, { useCallback, useMemo, useRef, useState } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import styled from "styled-components/native";
import type { ItemRenderData } from "@app/types/itemRenderData";
import Text from "@app/components/questkit/text";
import { FadeInView } from "@app/components/animated/fadeInView";
import { Platform } from "react-native";
import { MediaContext } from "@app/context/MediaContext";
import { QuestInstanceDetail, ReviewResult } from "@questmate/openapi-spec";
import { CompletionActionRenderData } from "@app/types/completionActionRenderData";
import { StackActions } from "@react-navigation/native";
import {
  EditLevel,
  QuestView,
  ReviewButtonBehavior,
  StatusMessageBehavior,
  SubmitButtonBehaviour,
} from "./common/questView";
import { useAppNavigation } from "@app/navigation/QMNavigator";
import QKModal, { MODAL_CLOSE_DELAY } from "@app/components/modal";
import { ReviewDialog } from "@app/components/modal/reviewDialog";
import { updateQuests } from "@app/util/cacheMod";
import {
  getQuestCompletionNavigationConfig,
  QuestCompletionNavigationConfig,
} from "@app/util/client/questCompletionNavigation";
import QKScrollView, {
  QKScrollViewController,
} from "@app/components/questkit/ScrollView";
import { QuestInfo } from "./QuestInfo";
import { sentry } from "@app/util/sentry";
import { cleanAxiosError } from "@questmate/common";
import { useAppSelector } from "@app/store";
import { useQuestViewContext } from "@app/quest/QuestViewContext";
import { selectQuestInstanceById } from "@app/store/cache/questInstances";
import { RestartQuestRunButton } from "@app/screens/quest/RestartQuestRunButton";
import { restartQuestInstance } from "@app/util/client/requests/quests";
import { OnItemChangeHandler } from "@app/util/client/hooks/useQuestInstance";

export type QuestInstanceViewProps = {
  questInstanceId: string;
  questPrototypeId: string;
  status: QuestInstanceDetail["status"];
  slideMode: boolean;
  introText: string;
  name: string;
  dueAt: string | null;
  startedByUser: {
    id: string;
    displayName: string;
    avatarSmallUrl: string | null;
  };
  completionNavigationConfig?: QuestCompletionNavigationConfig;
  ignoreQuestSubmissionRestartBehavior?: boolean;
  completeQuestLoading: boolean;
  runNotYetStarted?: boolean;
  requestingUser: QuestInstanceDetail["requestingUser"];
  items: ItemRenderData[];
  completionActions: CompletionActionRenderData[];
  onItemChange?: OnItemChangeHandler;
  onItemValidate: (itemId: string) => void;
  onSubmit?: (itemData: ItemRenderData[]) => Promise<QuestInstanceDetail>;
  onReview: (reviewResult: ReviewResult) => void;
};

export function getButtonAndMessageBehaviors(
  status: "OPEN" | "IN_REVIEW" | "COMPLETED" | undefined,
  requestingUser: QuestInstanceDetail["requestingUser"] | undefined,
  runNotYetStarted: boolean
) {
  let submitButtonBehavior = SubmitButtonBehaviour.Hidden;
  let reviewButtonBehavior = ReviewButtonBehavior.Hidden;
  let statusMessageBehavior = StatusMessageBehavior.Hidden;
  if (status && requestingUser) {
    switch (status) {
      case "OPEN":
        if (
          requestingUser.instanceRoles.includes("ASSIGNEE") ||
          requestingUser.instanceRoles.includes("OWNER")
        ) {
          submitButtonBehavior = runNotYetStarted
            ? SubmitButtonBehaviour.Disabled
            : SubmitButtonBehaviour.Submittable;
        } else {
          statusMessageBehavior = StatusMessageBehavior.AwaitingSubmission;
        }
        break;
      case "IN_REVIEW":
        if (requestingUser.instanceRoles.includes("REVIEWER")) {
          if (!requestingUser.reviewResult) {
            reviewButtonBehavior = ReviewButtonBehavior.RequiresReview;
          } else {
            statusMessageBehavior = StatusMessageBehavior.AwaitingOthersReview;
          }
        } else {
          statusMessageBehavior = StatusMessageBehavior.AwaitingReview;
        }
        break;
      case "COMPLETED":
        submitButtonBehavior = SubmitButtonBehaviour.Submittable;
        break;
    }
  }
  return { submitButtonBehavior, reviewButtonBehavior, statusMessageBehavior };
}

export const QuestInstanceView: React.FC<QuestInstanceViewProps> = ({
  questInstanceId,
  questPrototypeId,
  status,
  slideMode,
  introText,
  name,
  dueAt,
  startedByUser,
  runNotYetStarted,
  requestingUser,
  items,
  completionActions,
  completionNavigationConfig,
  ignoreQuestSubmissionRestartBehavior,
  completeQuestLoading,
  onItemChange,
  onItemValidate,
  onSubmit,
  onReview,
}) => {
  const useSlideMode =
    Boolean(
      completionActions.find(
        (action) => action.prototype.type === "CREATE_QUEST"
      )
    ) || slideMode;

  const { recentlySubmittedByUser, publicQuestSessionToken } =
    useQuestViewContext(["RUN"]);

  const [showReviewModal, setShowReviewModal] = useState(false);

  const scrollViewControllerRef = useRef<QKScrollViewController>(null);

  const introTextAvailable = Boolean(introText);
  const fadeInDelay = introTextAvailable ? 650 : 250;

  const navigation = useAppNavigation();

  const submissionBehavior = useAppSelector(
    (state) =>
      selectQuestInstanceById(state, questInstanceId)?.submissionBehavior
  );
  const [showRestartButton, setShowRestartButton] = useState(false);
  const restartButton = useMemo(() => {
    if (
      !showRestartButton ||
      !submissionBehavior ||
      submissionBehavior.type !== "RESTART"
    ) {
      return null;
    }
    return (
      <RestartQuestRunButton restartSubmissionBehavior={submissionBehavior} />
    );
  }, [showRestartButton, submissionBehavior]);

  const onSuccess = useCallback(
    (updatedQuestInstance: QuestInstanceDetail) => {
      const scrollView = scrollViewControllerRef.current;
      if (scrollView) {
        setTimeout(scrollView.scrollToEnd, 0);
      }

      void updateQuests();

      if (
        updatedQuestInstance.submissionBehavior.type === "RESTART" &&
        !ignoreQuestSubmissionRestartBehavior
      ) {
        setShowRestartButton(true);
        // perform restart
        void restartQuestInstance(
          updatedQuestInstance.id,
          publicQuestSessionToken
        ).catch((error) => {
          setShowRestartButton(false);
          sentry.captureException(
            new Error("Failed to restart Quest after submission"),
            { contexts: { Details: { response: cleanAxiosError(error) } } }
          );
        });
      } else {
        // do previous

        const instanceCompleted = updatedQuestInstance.status === "COMPLETED";

        if (instanceCompleted) {
          let navigationConfig: QuestCompletionNavigationConfig;
          if (completionNavigationConfig) {
            navigationConfig = completionNavigationConfig;
          } else {
            const defaultNavigationAction = StackActions.pop();
            navigationConfig = getQuestCompletionNavigationConfig(
              updatedQuestInstance,
              defaultNavigationAction
            );
          }

          if (navigationConfig.navigationAction) {
            const navigationTimeout = setTimeout(() => {
              navigation.dispatch(navigationConfig.navigationAction!);
            }, navigationConfig.navigationDelay);
            return () => {
              // if the user navigates away before the timeout triggers
              // we should cancel the timeout to not navigate
              clearTimeout(navigationTimeout);
            };
          }
        }
      }
    },
    [
      completionNavigationConfig,
      ignoreQuestSubmissionRestartBehavior,
      navigation,
      publicQuestSessionToken,
    ]
  );
  const { submitButtonBehavior, reviewButtonBehavior, statusMessageBehavior } =
    getButtonAndMessageBehaviors(status, requestingUser, !!runNotYetStarted);

  return (
    <MediaContext.Provider
      value={{
        uploadContextType: "questInstance",
        uploadContextId: questInstanceId,
        contexts: [
          {
            id: questInstanceId,
            type: "questInstance",
          },
          {
            id: questPrototypeId,
            type: "questPrototype",
          },
        ],
      }}
    >
      <StyledScrollView
        keyboardShouldPersistTaps="always"
        ref={scrollViewControllerRef}
      >
        <SafeAreaView edges={["top", "bottom"]} style={{ flex: 1 }}>
          {!useSlideMode && (
            <>
              <FadeInView delay={fadeInDelay}>
                <Header questName={name} />
                <QuestInfoWrapper>
                  <QuestInfo
                    ownerName={startedByUser.displayName}
                    ownerAvatarUrl={startedByUser.avatarSmallUrl}
                    dueAt={dueAt}
                  />
                </QuestInfoWrapper>
              </FadeInView>
              {runNotYetStarted ? (
                <PreviewText size="small">
                  Preview only, Quest Run has not been started yet.
                </PreviewText>
              ) : null}
            </>
          )}
          <QuestView
            introText={introText}
            items={items}
            onItemChange={onItemChange}
            onItemValidate={onItemValidate}
            completionActions={completionActions}
            onSubmit={() => onSubmit?.(items)}
            onSuccess={onSuccess}
            loading={completeQuestLoading}
            completed={status === "COMPLETED"}
            canEditIntroText={false}
            itemsEditLevel={
              runNotYetStarted ||
              submitButtonBehavior === SubmitButtonBehaviour.Hidden ||
              status === "COMPLETED"
                ? EditLevel.Readonly
                : EditLevel.Interactive
            }
            completionActionsEditLevel={EditLevel.Interactive}
            reviewButtonBehavior={reviewButtonBehavior}
            statusMessageBehavior={statusMessageBehavior}
            onReviewStart={() => {
              setShowReviewModal(true);
            }}
            submitButtonBehaviour={
              recentlySubmittedByUser && restartButton
                ? SubmitButtonBehaviour.Hidden
                : submitButtonBehavior
            }
            restartButton={recentlySubmittedByUser ? restartButton : null}
            slideMode={useSlideMode}
          />
        </SafeAreaView>
      </StyledScrollView>
      <QKModal
        showModal={showReviewModal}
        setShowModal={setShowReviewModal}
        title="Review"
      >
        <ReviewDialog
          questInstanceId={questInstanceId}
          onSuccess={(reviewResult: ReviewResult) => {
            onReview(reviewResult);
            setTimeout(() => setShowReviewModal(false), MODAL_CLOSE_DELAY);
          }}
        />
      </QKModal>
    </MediaContext.Provider>
  );
};

let paddingTop;

switch (Platform.OS) {
  case "ios":
    paddingTop = 11;
    break;
  case "android":
    paddingTop = 16;
    break;
  case "web":
    paddingTop = 20;
    break;
  default:
    paddingTop = 20;
}

const StyledScrollView = styled(QKScrollView).attrs({
  contentContainerStyle: {
    paddingTop: paddingTop,
    paddingBottom: 16,
    flexGrow: 1,
  },
})`
  background-color: ${({ theme }) => theme.background};
`;

const Header = ({ questName }: { questName: string }) => {
  return (
    <StyledView>
      <TitleText size="mediumBold">{questName}</TitleText>
    </StyledView>
  );
};

const PreviewText = styled(Text)`
  color: ${({ theme }) => theme.warning};
  text-align: center;
  padding-top: 80px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 6px;
  align-self: center;
`;

const StyledView = styled.View`
  margin-horizontal: 64px;
`;

const TitleText = styled(Text)`
  text-align: center;
`;

const QuestInfoWrapper = styled.View`
  margin-top: 25px;
`;
