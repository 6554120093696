import React, { useMemo, useState } from "react";
import {
  NativeSyntheticEvent,
  Platform,
  Pressable,
  TextInputKeyPressEventData,
} from "react-native";
import styled from "styled-components/native";
import { ItemInfo as ItemInfoType } from "@questmate/openapi-spec";
import { useFocusableRef } from "@app/util/focus";

import { Text } from "@app/components/questkit";
import Icon, { IconProps } from "@app/components/icon";
import { TextInput } from "@app/components/questkit/text";
import TextInputModal from "../modal/textInputModal";
import equal from "react-fast-compare";
import { Analytics } from "@app/analytics";
import DragPanel, { DragHandlers } from "../questkit/dragPanel";
import { createLink, isExternalLink } from "@app/util/link.utils";
import BasePressable from "@app/components/questkit/BasePressable";
import { useHover } from "@app/util/useHover";

interface ItemInfoProps {
  itemInfo: ItemInfoType;
  editMode: boolean;
  onItemInfoSubmit?: () => void;
  onItemInfoChange?: (itemInfo: ItemInfoType) => void;
  onItemInfoDelete?: () => void;
  dragHandlers: DragHandlers;
}

const ItemInfo: React.FC<ItemInfoProps> = ({
  itemInfo,
  editMode,
  onItemInfoChange,
  onItemInfoDelete,
  onItemInfoSubmit,
  dragHandlers,
}) => {
  const textInputRef = useFocusableRef(itemInfo.id);
  const infoText = itemInfo.text;

  const [showSetLinkDialog, setShowSetLinkDialog] = useState(false);
  const [infoInputActive, setInfoInputActive] = useState(false);
  const { isHovered, hoverProps } = useHover();

  const onKeyPress = (
    event: NativeSyntheticEvent<TextInputKeyPressEventData>
  ) => {
    if (event.nativeEvent.key === "Backspace" && infoText === "") {
      event.preventDefault(); // prevent backspace from triggering other actions, e.g. navigating backwards on web
      onItemInfoDelete?.();
    }
  };

  // Info linking

  const onOpenLinkConfiguration = () => {
    Analytics.trackEvent("Open Item Info Link Dialog");
    setShowSetLinkDialog(true);
  };

  const onSetLink = (link: string) => {
    Analytics.trackEvent("Set Item Info Link");

    onItemInfoChange?.({
      ...itemInfo,
      link: link,
    } as ItemInfoType);
    setShowSetLinkDialog(false);
  };

  const showInfoActionPanel = editMode && (isHovered || infoInputActive);

  const onPressLink = useMemo(() => {
    if (editMode) {
      return;
    }
    const link = itemInfo.link?.trim();
    return link
      ? createLink(link, isExternalLink(link) ? { newTabOnWeb: true } : {})
      : undefined;
  }, [editMode, itemInfo.link]);

  return (
    <>
      <ItemInfoEntry
        {...hoverProps}
        onPress={onPressLink}
        // @ts-expect-error Missing valid RNW types. https://github.com/necolas/react-native-web/issues/832
        tabIndex={
          -1 /* TODO: Make focusable when onPressLink is present. It does not work currently... */
        }
      >
        {showInfoActionPanel && Boolean(infoText) ? (
          <Pressable onPress={() => onItemInfoDelete?.()}>
            <ItemInfoIcon icon="trash" />
          </Pressable>
        ) : (
          <ItemInfoIcon icon="info" />
        )}
        <ItemInfoTextWrap>
          {editMode ? (
            <TextInput
              ref={textInputRef}
              size="medium"
              value={infoText}
              scrollEnabled={false}
              onChangeText={(text: string) => {
                const singleLineText = text.replace(/[\r\n]+/g, "");
                onItemInfoChange?.({
                  ...itemInfo,
                  text: singleLineText,
                });
                return singleLineText;
              }}
              onSubmitEditing={() => {
                if (onItemInfoSubmit) {
                  onItemInfoSubmit();
                }
              }}
              onBlur={() => {
                setInfoInputActive(false);
              }}
              onFocus={() => {
                setInfoInputActive(true);
              }}
              blurOnSubmit={Platform.OS === "web"}
              onKeyPress={onKeyPress}
              multiline={true}
              placeholder={"Type here to add a note (optional)"}
              style={{
                textDecorationLine: itemInfo.link ? "underline" : "none",
              }}
            />
          ) : (
            <Text
              size="medium"
              style={{
                textDecorationLine: itemInfo.link ? "underline" : "none",
              }}
            >
              {infoText}
            </Text>
          )}
        </ItemInfoTextWrap>
        {showInfoActionPanel && Boolean(infoText) && (
          <ActionIconContainer onPress={onOpenLinkConfiguration}>
            <Icon icon="link" />
          </ActionIconContainer>
        )}
        {showInfoActionPanel && (
          <DragPanelContainer>
            <DragPanel dragHandlers={dragHandlers} />
          </DragPanelContainer>
        )}
        {!editMode && Boolean(itemInfo.link) && (
          <ActionIconContainer onPress={onPressLink} omitLinkProps={true}>
            <Icon icon="chevron-right" />
          </ActionIconContainer>
        )}
      </ItemInfoEntry>
      <TextInputModal
        initialValue={itemInfo.link}
        showTextInputModal={showSetLinkDialog}
        setShowTextInputModal={setShowSetLinkDialog}
        headerTitle="Set Info Link"
        placeholder={"https:// ..."}
        onSetValue={onSetLink}
        submitButtonText="Save"
      />
    </>
  );
};

const ActionIconContainer = styled(BasePressable)`
  height: 24px;
  justify-content: center;
`;

const DragPanelContainer = styled(BasePressable)`
  height: 24px;
  justify-content: center;
`;

const ItemInfoEntry = styled(BasePressable)<BrowserViewProps>`
  flex-direction: row;
  flex: 1;
  ${({ onPress }) =>
    !onPress && Platform.OS === "web" ? `cursor: default` : ``}
`;

export const ItemInfoIcon = (
  props: IconProps & { infoType?: "info" | "warning" }
) => (
  <ItemInfoIconContainer>
    <StyledInfoIcon
      offsetX={-2}
      infoType={props.infoType ?? "info"}
      container="COLLAPSED"
      {...props}
    />
  </ItemInfoIconContainer>
);
const StyledInfoIcon = styled(Icon)<{ infoType: "info" | "warning" }>`
  ${({ theme, infoType }) =>
    infoType === "warning" ? `color: ${theme.button.warning.background}` : ""};
`;

const ItemInfoIconContainer = styled.View`
  margin-right: 8px;
`;

export type BrowserViewProps = {
  // onClick?: (e: unknown) => void;
  onMouseEnter?: (e: unknown) => void;
  onMouseLeave?: (e: unknown) => void;
  // target?: string;
};

const ItemInfoTextWrap = styled.View`
  flex: 1;
  justify-content: center;
`;

export default React.memo(ItemInfo, (prev, next) => {
  return (
    equal(prev.itemInfo, next.itemInfo) &&
    prev.editMode === next.editMode &&
    prev.dragHandlers?.onDragStart === next.dragHandlers?.onDragStart
  );
});
