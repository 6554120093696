import {
  getPathFromState,
  getStateFromPath,
  LinkingOptions,
  getActionFromState,
  findFocusedRoute,
} from "@react-navigation/native";
import * as Linking from "expo-linking";
import * as Notifications from "expo-notifications";
import { Analytics } from "@app/analytics";
import { getLoggedInUserId } from "@app/util/getLoggedInUserId";
import { setPostLoginTargetUrl } from "@app/store/UI";
import { store } from "@app/store";
import { QMRoute, QMStackParamList } from "@app/navigation/QMNavigator";
import { PathConfigMap } from "@react-navigation/core/src/types";
import {
  NavigationState,
  PartialState,
} from "@react-navigation/routers/src/types";

type StandardLinkingConfigOptions = {
  path?: string;
  initialRouteName?: string;
  screens?: PathConfigMap<QMStackParamList>;
};
type QMLinkingConfigOptions = {
  pathConfigurationOverride?: "LOGGED_IN" | "UNAUTHENTICATED";
};

// The StandardLinkingConfigOptions are the same as the ones in the react-navigation types
type LinkingConfigOptions = StandardLinkingConfigOptions &
  QMLinkingConfigOptions;

export const linkingConfig = {
  prefixes: [
    "https://app.questmate.com",
    "questmate://",
    "exp://127.0.0.1:19000",
    "http://localhost:19006",
    "http://10.0.0.9:19006",
    "https://testing.questmate.com",
  ],
  getStateFromPath: (path, options?: LinkingConfigOptions) => {
    const userIsLoggedIn = !!getLoggedInUserId();
    const pathConfig = options?.pathConfigurationOverride
      ? options?.pathConfigurationOverride === "LOGGED_IN"
        ? loggedInPathConfiguration
        : unauthenticatedPathConfiguration
      : userIsLoggedIn
      ? loggedInPathConfiguration
      : unauthenticatedPathConfiguration;

    let stateFromPath = getStateFromPath(path, pathConfig);

    if (!stateFromPath) {
      if (userIsLoggedIn) {
        // check if they are trying to visit the login route with a target URL
        // if so, take them to the target URL and skip login
        const unauthPathState = getStateFromPath(
          path,
          unauthenticatedPathConfiguration
        );
        if (unauthPathState) {
          const focusedRoute = findFocusedRoute(unauthPathState) as QMRoute;
          if (focusedRoute?.name === "Login") {
            const targetUrl = focusedRoute.params?.url;
            if (targetUrl) {
              stateFromPath = getStateFromPath(targetUrl, pathConfig);
            }
          }
        }
      } else if (!userIsLoggedIn && path !== "/") {
        const isValidLoggedInPath = !!getStateFromPath(
          path,
          loggedInPathConfiguration
        );
        if (isValidLoggedInPath) {
          store.dispatch(setPostLoginTargetUrl(path));
        } else {
          console.warn(`Provided path '${path}' did not match any screens.`);
        }
      }
    } else {
      const focusedRoute = findFocusedRoute(stateFromPath) as QMRoute;
      if (focusedRoute?.name === "Logout") {
        // do not let people navigate to the logout screen via deep link or browser URL link
        return undefined;
      }
    }

    return stateFromPath;
  },
  getActionFromState: (state) => {
    const pathConfig = getLoggedInUserId()
      ? loggedInPathConfiguration
      : unauthenticatedPathConfiguration;

    return getActionFromState(state, pathConfig);
  },
  getPathFromState: (state, options?: LinkingConfigOptions) => {
    // NOTE: This method does not "validate" the state and will always produce a path result even when the screen does not exist.
    //       Use `validatePath` helper function to check if the path points to a valid screen in our app.
    const pathConfig = options?.pathConfigurationOverride
      ? options?.pathConfigurationOverride === "LOGGED_IN"
        ? loggedInPathConfiguration
        : unauthenticatedPathConfiguration
      : getLoggedInUserId()
      ? loggedInPathConfiguration
      : unauthenticatedPathConfiguration;

    // TODO:
    //  Remove this deep clone that omits `undefined` optional parameters once this react-navigation bug is resolved:
    //  https://github.com/react-navigation/react-navigation/issues/12528
    return getPathFromState(JSON.parse(JSON.stringify(state)), pathConfig);
  },
  async getInitialURL() {
    // First, you may want to do the default deep link handling
    // Check if app was opened from a deep link
    const deepLinkUrl = await Linking.getInitialURL();

    if (deepLinkUrl != null) {
      return deepLinkUrl;
    }

    // Check if the user interacted with a push notification when the app was killed
    const response = await Notifications.getLastNotificationResponseAsync();
    const pushNotificationUrl = response?.notification.request.content.data
      ?.url as string;
    if (pushNotificationUrl) {
      Analytics.trackEvent("Link Into App", {
        from: "Push Notification",
      });
    }
    return pushNotificationUrl;
  },
  subscribe(listener) {
    // Listen to incoming links from deep linking
    const deepLinkListener = Linking.addEventListener(
      "url",
      ({ url }: { url: string }) => {
        const baseUrl = url?.split("?")?.[0];

        Analytics.trackEvent("Link Into App", {
          from: "Deep Link",
          url: baseUrl,
        });
        listener(url);
      }
    );

    // Listen to expo push notifications
    const subscription = Notifications.addNotificationResponseReceivedListener(
      (response) => {
        const url = response.notification.request.content.data.url as string;
        const baseUrl = url?.split("?")?.[0];

        Analytics.trackEvent("Link Into App", {
          from: "Push Notification",
          url: baseUrl,
        });

        // Any custom logic to see whether the URL needs to be handled
        //...
        // Let React Navigation handle the URL
        if (url) {
          listener(url);
        }
      }
    );

    return () => {
      // Clean up the event listeners
      deepLinkListener.remove();
      subscription.remove();
    };
  },
} satisfies LinkingOptions<QMStackParamList>;

const loggedInPathConfiguration: LinkingOptions<QMStackParamList>["config"] = {
  initialRouteName: "Home",
  screens: {
    Home: "home",
    Logout: "logout",
    Redirect: "r",
    QuestCreate: "createQuest",
    CreateQuestLoading: "questCreation",
    AdminQuestRun: {
      path: "quests/:templateId/runs/:questInstanceId",
    },
    QuestList: {
      path: "quests",
      parse: {
        filters: (filters) => JSON.parse(filters),
      },
      stringify: {
        filters: (filters) => {
          return JSON.stringify(filters);
        },
      },
    },
    Quest: {
      path: "quests/:questId",
      initialRouteName: "QuestRuns",
      screens: {
        QuestEditStart: "edit/:questPrototypeId/start/:startTriggerId",
        QuestEdit: "edit/:questPrototypeId?",
        QuestConfiguration: {
          path: "configuration/:questPrototypeId?",
          alias: ["template/:questPrototypeId?"],
        },
        QuestRuns: {
          path: "",
          alias: ["runs"],
          parse: {
            filters: (filters) => JSON.parse(filters),
          },
          stringify: {
            filters: (filters) => {
              return JSON.stringify(filters);
            },
          },
        },
      },
    },
    TemplateCreate: "createTemplate",
    TemplateNew: "new",
    PublicTemplatePreview: "library/templates/:questPrototypeId/preview",
    Assignments: {
      path: "assignments",
      parse: {
        filters: (filters) => JSON.parse(filters),
      },
      stringify: {
        filters: (filters) => {
          return JSON.stringify(filters);
        },
      },
    },
    PublicAssignment: "p/:id",
    QuestInstance: "quest/instance/:id",

    Settings: "settings",
    Account: "settings/account",
    Billing: "settings/billing",
    Workspace: "settings/workspaces/:workspaceId",
    Team: "settings/teams/:teamId",
    Apps: "settings/apps",
    App: "settings/apps/:appId",
    Support: "settings/support",
    AppDebugInformation: "settings/support/debug",

    IconList: "icons",
  },
};
const unauthenticatedPathConfiguration: LinkingOptions<QMStackParamList>["config"] =
  {
    initialRouteName: "Login",
    screens: {
      Login: "login",
      ...extractScreensFromConfig(
        loggedInPathConfiguration,
        "Logout",
        "PublicAssignment",
        "PublicTemplatePreview",
        "QuestInstance",
        "Redirect",
        "AppDebugInformation"
      ),
    },
  };

function extractScreensFromConfig(
  pathConfig: LinkingOptions<QMStackParamList>["config"],
  ...screenNames: (keyof QMStackParamList)[]
) {
  const screenConfig: Record<string, unknown> = {};
  screenNames.forEach((screenName) => {
    screenConfig[screenName] = (
      pathConfig?.screens as Record<string, unknown>
    )?.[screenName];
  });
  return screenConfig;
}

export function getRouteFromPath(
  path: string | undefined
): QMRoute | undefined {
  if (!path) {
    return undefined;
  }

  const state = getStateFromPath(path, loggedInPathConfiguration);
  if (!state) {
    return undefined;
  }

  return findFocusedRoute(state) as QMRoute;
}

export const getPathForScreen = (
  screenConfig: {
    screen: string;
    params?: Record<string, unknown>;
  },
  options?: LinkingConfigOptions
): string | undefined => {
  const path = linkingConfig.getPathFromState(
    {
      routes: [
        {
          name: screenConfig.screen,
          params: screenConfig.params,
          state: getStateFromParams(screenConfig.params),
        },
      ],
    },
    options
  );
  return validatePath(path, options) ? path : undefined;
};

/**
 * This method borrowed from react-navigation. node_modules/@react-navigation/native/lib/module/useLinkProps.js:6
 */
const getStateFromParams = (
  params: Record<string, unknown> | undefined
): PartialState<NavigationState> | undefined => {
  if (params !== null && params !== void 0 && params.state) {
    return params.state as PartialState<NavigationState> | undefined;
  }
  if (params !== null && params !== void 0 && params.screen) {
    return {
      routes: [
        {
          name: params.screen,
          params: params.params,
          // @ts-expect-error code copied from react-navigation
          state: params.screen ? getStateFromParams(params.params) : undefined,
        },
      ],
    } as PartialState<NavigationState> | undefined;
  }
  return undefined;
};

export const validatePath = (path: string, option?: LinkingConfigOptions) => {
  const state = linkingConfig.getStateFromPath(path, option);
  return Boolean(state);
};

export default linkingConfig;
