import * as React from "react";
import { useCallback, useContext } from "react";
import { useChangeTracker } from "@app/quest/edit/useChangeTracker";
import {
  SnackbarContext,
  SnackbarSeverity,
} from "@app/components/snackbar/SnackbarContext";
import { usePromise } from "@app/util/usePromise";
import { mutate } from "swr";
import { updateQuests } from "@app/util/cacheMod";
import { Analytics } from "@app/analytics";
import Switch from "@app/components/questkit/switch";
import styled from "styled-components/native";
import Button from "@app/components/questkit/button";
import QKTextInput from "@app/components/questkit/textInput";
import ListItem from "@app/components/questkit/listItem";
import {
  StartTriggerCard,
  StartTriggerSummary,
} from "@app/quest/start/StartTriggerCard";
import { useLink } from "@app/util/link.utils";
import PressableOpacity from "@app/components/questkit/PressableOpacity";
import { QUEST_TYPES } from "@app/quest/start/QuestTypes";
import { StartTriggerEditFieldsForType } from "@app/store/cache/questStartTriggers";
import { updateQuestStartTrigger } from "@app/util/client/requests/questStartTriggers";
import { useAppSelector } from "@app/store";
import { selectQuestPrototypeById } from "@app/store/cache/questPrototypes";
import { useQuestViewContext } from "@app/quest/QuestViewContext";
import { useQrCodeModal } from "@app/quest/start/useQrCodeModal";
import { useCopyLinkToKeyboard } from "@app/quest/start/useCopyLinkToKeyboard";

interface PublicSubmissionStartViewProps {
  startTrigger: StartTriggerEditFieldsForType<"PUBLIC">;
  openStartQuestModal: () => void;
  startChangingQuestType: () => void;
  readOnly: boolean;
}

export const PublicSubmissionsStartView: React.FC<
  PublicSubmissionStartViewProps
> = ({
  startTrigger: startTriggerFromServer,
  startChangingQuestType,
  readOnly,
}) => {
  const { questPrototypeId } = useQuestViewContext(["MANAGE", "PREVIEW"]);
  const questName = useAppSelector(
    (state) => selectQuestPrototypeById(state, questPrototypeId)?.name
  );

  const { useValueWithChanges, addChange, getChangeSet } = useChangeTracker(
    startTriggerFromServer
  );
  const startTrigger = useValueWithChanges();

  const snackbar = useContext(SnackbarContext);

  const { execute: save, isLoading: isSaving } = usePromise(async () => {
    const changeSet = getChangeSet();
    const changedStartTrigger = changeSet.valueWithChanges;

    if (changeSet.hasUnsavedChanges) {
      changeSet.markPending();
      return await updateQuestStartTrigger(questPrototypeId, startTrigger.id, {
        enabled: changedStartTrigger.enabled,
      })
        .then((response) => {
          changeSet.markSaved();
          void mutate(["get", `/quests/${questPrototypeId}`]);
          void updateQuests();
          return response;
        })
        .catch((e) => {
          changeSet.rollbackChanges(changeSet.changesByStatus.pending);
          console.log(e);
          snackbar.sendMessage(
            `We unfortunately couldn't save. Please try again later.`,
            SnackbarSeverity.WARNING
          );
        });
    }
  });

  const onTogglePublicSubmissionsEnabled = useCallback(() => {
    addChange((st) => (st.enabled = !st.enabled));
    void save();
  }, [addChange, save]);

  const questPublicUrl = startTrigger.config.publicUrl;
  const copyPublicUrlToClipboard = useCopyLinkToKeyboard(questPublicUrl);

  const onPressTextInput = useCallback(() => {
    Analytics.trackEvent("Copy Public Url to Clipboard", {
      questPrototypeId: questPrototypeId,
      actionLocation: "Press on Text Input",
    });
    copyPublicUrlToClipboard();
  }, [copyPublicUrlToClipboard, questPrototypeId]);
  const onPressCopyLink = useCallback(() => {
    Analytics.trackEvent("Copy Public Url to Clipboard", {
      questPrototypeId: questPrototypeId,
      actionLocation: "Copy Link Primary Button",
    });
    copyPublicUrlToClipboard();
  }, [copyPublicUrlToClipboard, questPrototypeId]);

  const goToPublicUrl = useLink(
    {
      screen: "PublicAssignment",
      params: { id: startTrigger.config.publicId! },
    },
    {
      onPressHook: () =>
        Analytics.trackEvent("Open Public Url", {
          questPrototypeId,
        }),
    }
  );
  const setShowQrCodeModal = useQrCodeModal(
    questPrototypeId,
    questName,
    questPublicUrl
  );

  const openPublicUrlQRCode = useCallback(() => {
    Analytics.trackEvent("Open Public QR Code Dialog", { questPrototypeId });
    setShowQrCodeModal(true);
  }, [questPrototypeId, setShowQrCodeModal]);

  if (readOnly) {
    return (
      <StartTriggerSummary
        icon={QUEST_TYPES["PUBLIC"].icon}
        text={startTrigger.enabled ? `Share via Link` : "Share Link Disabled"}
      />
    );
  } else {
    return (
      <StartTriggerCard
        title={QUEST_TYPES["PUBLIC"].name}
        icon={QUEST_TYPES["PUBLIC"].icon}
        startChangingQuestType={startChangingQuestType}
      >
        <ListItem
          icon="turn-off"
          text="Enable Share Link"
          actionComponent={
            <Switch
              testID="public-url-toggle"
              switchOn={startTrigger.enabled}
              onSwitch={onTogglePublicSubmissionsEnabled}
              loading={isSaving}
            />
          }
        />

        {startTrigger.enabled ? (
          <PublicLinkContainer>
            <PressableOpacity activeOpacity={0.8} onPress={onPressTextInput}>
              <StyledQKTextInput
                value={questPublicUrl}
                editable={false}
                numberOfLines={1}
                leftIcon="link"
              />
            </PressableOpacity>
            <CopyLinkButton title="Copy Share Link" onPress={onPressCopyLink} />
            <ButtonRow>
              <ButtonRowButtonButton
                title="Show QR Code"
                onPress={openPublicUrlQRCode}
                buttonType="secondary"
              />
              <ButtonRowButtonButton
                title="Run once now"
                onPress={goToPublicUrl}
                buttonType="secondary"
              />
            </ButtonRow>
          </PublicLinkContainer>
        ) : (
          <>
            <ListItem
              icon="link-off"
              text={"Share Link Disabled. No submissions allowed."}
            />
          </>
        )}
      </StartTriggerCard>
    );
  }
};

const PublicLinkContainer = styled.View`
  margin-top: 10px;
`;

const CopyLinkButton = styled(Button)`
  margin-top: 20px;
`;
const ButtonRowButtonButton = styled(Button)`
  margin-top: 20px;
  flex: 1;
`;

const ButtonRow = styled.View`
  flex-direction: row;
  gap: 10px;
`;

const StyledQKTextInput = styled(QKTextInput)`
  margin-bottom: 10px;
`;
