import { useRef } from "react";

/**
 * @description Hook that provides a stable singleton value across renders.
 * @param initializer function to provide the singleton value. Only called once.
 *
 * `useRef` with a value passed as a parameter is not ideal as it re-instantiates
 * every render even though the ref value does stay as a stable singleton.
 *
 * `useMemo` with an empty dependency array still recalculates its values in some
 * situations (unsure exactly when, but I noticed this when used in a scroll
 * view of a modal that was not rendered until the modal opened.)
 *
 */
export const useSingleton = <T>(initializer: () => T) => {
  const ref = useRef<T | null>(null);
  if (ref.current === null) {
    ref.current = initializer();
  }
  return ref.current;
};
