import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  type QMStackParamList,
  useAppRoute,
} from "@app/navigation/QMNavigator";
import { QuestConfigurationScreen } from "@app/quest/edit/QuestConfigurationScreen";
import {
  type FilterIdsByGroupId,
  QuestRunsScreen,
} from "@app/quest/run/QuestRunsScreen";
import QKModal from "@app/components/modal";
import TemplateOptionsDialog from "@app/components/modal/templateOptionsDialog";
import { fetchQuest } from "@app/util/client/requests/quests";
import { useQuestPrototype } from "@app/quest/useQuestPrototype";
import CreateQuestDialog from "@app/components/modal/createQuest";
import { View } from "react-native";
import { useMultiTapToggler } from "@app/quest/edit/useMultiTapToggler";
import { SnackbarContext } from "@app/components/snackbar/SnackbarContext";
import {
  type FullQuestContext,
  type LoadingQuestContext,
  QuestContext,
  useQuestContext,
} from "./QuestContext";
import { useAppSelector } from "@app/store";
import { selectQuestPrototypeById } from "@app/store/cache/questPrototypes";
import Loader from "@app/components/animated/loader";
import { selectQuestById } from "@app/store/cache/quests";
import { useRequest } from "@app/util/client/requests";
import { useScreenHasBeenFocusedAtLeastOnce } from "@app/util/useScreenHasBeenFocusedAtLeastOnce";
import { QuestEditScreen } from "@app/quest/edit/QuestEditScreen";
import type { NavigationProp } from "@react-navigation/native";
import type { NavigationState } from "@react-navigation/routers";
import {
  StackNavigationProp,
  createStackNavigator,
} from "@react-navigation/stack";
import {
  HeaderIcon,
  PlaceholderHeaderIcon,
} from "@app/navigation/components/HeaderIcon";
import { Analytics } from "@app/analytics";
import Icon from "@app/components/icon";
import { OpenSideBarButton } from "@app/navigation/components/OpenSideBarButton";
import { createLink } from "@app/util/link.utils";
import { QuestEditStartConfigurationScreen } from "@app/quest/edit/QuestEditStartConfigurationScreen";
import {
  StackNavigationOptions,
  StackNavigationEventMap,
} from "@react-navigation/stack/src/types";
import { getDefaultScreenOptions } from "@app/navigation/defaultScreenOptions";
import { useTheme } from "styled-components/native";
import { selectLoggedInUserId } from "@app/store/auth";

export type QuestStackParamList = {
  QuestEditStart: {
    questPrototypeId: string;
    startTriggerId: string;
    isChangingType?: boolean;
    advancedMode?: boolean;
  };
  QuestEdit: {
    questPrototypeId?: string;
    advancedMode?: boolean;
  };
  QuestConfiguration: {
    questPrototypeId?: string;
    advancedMode?: boolean;
  };
  QuestRuns: {
    filters?: FilterIdsByGroupId;
  };
};
export type QMStackNavigationProp<T extends keyof QuestStackParamList> = Omit<
  NavigationProp<
    QuestStackParamList,
    T,
    undefined,
    NavigationState<QuestStackParamList>,
    StackNavigationOptions,
    StackNavigationEventMap
  >,
  "getParent"
> & {
  getParent: () => StackNavigationProp<QMStackParamList, "Quest">;
};

const QuestStack = createStackNavigator<QuestStackParamList>();

export const MainQuestScreen: React.FC = () => {
  const route = useAppRoute<"Quest">();
  const { questId, advancedMode: advancedModeParam } = route.params;
  // Get Template Data
  useRequest(fetchQuest(questId));
  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [advancedMode, setAdvancedMode] = useState(advancedModeParam ?? false);
  const [onboarding, setOnboarding] = useState(false);

  const hasStartedAQuestRun = useAppSelector((state) => {
    return Boolean(selectQuestById(state, questId)?.lastRunAt);
  });
  useEffect(() => {
    // Stay in onboarding mode until the user starts a Quest Run.
    setOnboarding(!hasStartedAQuestRun);
  }, [hasStartedAQuestRun]);

  const openQuestOptionsModal = useCallback(
    () => setShowOptionsModal(true),
    []
  );

  const rootQuestPrototypeId = useAppSelector((state) =>
    questId
      ? selectQuestById(state, questId)?.currentQuestPrototypeId ?? undefined
      : undefined
  );

  useQuestPrototype(rootQuestPrototypeId);

  const questName = useAppSelector((state) => {
    if (!rootQuestPrototypeId) {
      return undefined;
    }
    return selectQuestPrototypeById(state, rootQuestPrototypeId)!.name;
  });

  const snackbar = useContext(SnackbarContext);

  const { onStartShouldSetResponderCapture } = useMultiTapToggler({
    tapCount: 10,
    timeWindow: 2.5 * 1000,
    onToggle: () => {
      setAdvancedMode((prev) => !prev);
      snackbar.sendMessage(
        `Advanced mode ${advancedMode ? "disabled" : "enabled"}.`
      );
    },
  });

  const questContext = useMemo(() => {
    if (!rootQuestPrototypeId) {
      return {
        isReady: false,
        questId,
        advancedMode,
      } satisfies LoadingQuestContext;
    }
    return {
      isReady: true,
      questId,
      advancedMode,
      onboarding,
      openQuestOptionsModal,
      currentQuestPrototypeId: rootQuestPrototypeId,
      name: questName!,
    } satisfies FullQuestContext;
  }, [
    advancedMode,
    onboarding,
    openQuestOptionsModal,
    rootQuestPrototypeId,
    questName,
    questId,
  ]);

  const theme = useTheme();
  const currentUserId = useAppSelector(selectLoggedInUserId);
  const userIsLoggedIn = currentUserId !== null;
  return (
    <View
      style={{ flex: 1 }}
      onStartShouldSetResponderCapture={onStartShouldSetResponderCapture}
    >
      <QuestContext.Provider value={questContext}>
        <QuestStack.Navigator
          screenOptions={getDefaultScreenOptions(theme, userIsLoggedIn)}
          initialRouteName={"QuestRuns"}
        >
          <QuestStack.Group navigationKey={questId}>
            <QuestStack.Screen
              name="QuestRuns"
              component={QuestRunsScreen}
              options={{
                // browser tab title
                title: questName ?? "Quest Runs",
                headerLeft: (props) => <OpenSideBarButton {...props} />,
                headerRight: () =>
                  rootQuestPrototypeId ? (
                    <HeaderIcon
                      testID="open-quest-options-dialog"
                      onPress={() => {
                        Analytics.trackEvent("Open Quest Options Dialog");
                        setShowOptionsModal(true);
                      }}
                    >
                      <Icon icon="gear" />
                    </HeaderIcon>
                  ) : (
                    <PlaceholderHeaderIcon />
                  ),
              }}
            />
            <QuestStack.Screen
              name="QuestEdit"
              component={
                questContext.isReady
                  ? QuestEditScreenWrapper
                  : CenteredLoadingIndicator
              }
              options={({ route }) => {
                const questPrototypeIdFromRoute = (
                  route.params as QuestStackParamList["QuestEdit"] | undefined
                )?.questPrototypeId;

                const onPress =
                  questPrototypeIdFromRoute &&
                  questPrototypeIdFromRoute !== rootQuestPrototypeId
                    ? createLink({
                        screen: "Quest",
                        params: {
                          questId,
                          screen: "QuestEdit",
                          params: { questPrototypeId: rootQuestPrototypeId },
                        },
                      })
                    : createLink({
                        screen: "Quest",
                        params: {
                          questId,
                          screen: "QuestConfiguration",
                          params: {},
                        },
                      });
                return {
                  headerLeft: () => (
                    <HeaderIcon onPress={onPress}>
                      <Icon icon="chevron-left" size={32} />
                    </HeaderIcon>
                  ),
                  title: questName ?? "Quest Edit",
                };
              }}
            />
            <QuestStack.Screen
              name="QuestConfiguration"
              component={
                questContext.isReady
                  ? QuestConfigurationScreenWrapper
                  : CenteredLoadingIndicator
              }
              options={{
                // browser tab title
                title: questName ?? "Quest",
              }}
            />
            <QuestStack.Screen
              name="QuestEditStart"
              component={
                questContext.isReady
                  ? QuestEditStartConfigurationScreenWrapper
                  : CenteredLoadingIndicator
              }
              options={({ route }) => {
                return {
                  // browser tab title
                  title: route.params.isChangingType
                    ? "Quest mode"
                    : "Settings",
                };
              }}
            />
          </QuestStack.Group>
        </QuestStack.Navigator>

        {!questContext.isReady ? null : (
          <>
            <QKModal
              showModal={showOptionsModal}
              setShowModal={setShowOptionsModal}
              title="Default Run Options"
            >
              <TemplateOptionsDialog
                setShowOptionsModal={setShowOptionsModal}
                setShowDuplicateModal={setShowDuplicateModal}
              />
            </QKModal>

            <QKModal
              showModal={showDuplicateModal}
              setShowModal={setShowDuplicateModal}
              title="Duplicate Quest"
            >
              <CreateQuestDialog
                createButtonText={"Clone"}
                defaultName={questName}
                sourceTemplateId={questId}
                setShowModal={setShowDuplicateModal}
              />
            </QKModal>
          </>
        )}
      </QuestContext.Provider>
    </View>
  );
};

const CenteredLoadingIndicator: React.FC = () => (
  <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
    <Loader />
  </View>
);

const QuestConfigurationScreenWrapper: React.FC = () => {
  const { currentQuestPrototypeId } = useQuestContext();
  const questPrototypeId =
    useAppRoute<"QuestConfiguration">().params?.questPrototypeId;
  const hasBeenFocused = useScreenHasBeenFocusedAtLeastOnce();

  const key = questPrototypeId ?? currentQuestPrototypeId;
  return hasBeenFocused ? (
    <QuestConfigurationScreen key={key} />
  ) : (
    <CenteredLoadingIndicator />
  );
};

const QuestEditScreenWrapper: React.FC = () => {
  const { currentQuestPrototypeId } = useQuestContext();
  const questPrototypeId = useAppRoute<"QuestEdit">().params?.questPrototypeId;
  const hasBeenFocused = useScreenHasBeenFocusedAtLeastOnce();

  const key = questPrototypeId ?? currentQuestPrototypeId;
  return hasBeenFocused ? (
    <QuestEditScreen key={key} />
  ) : (
    <CenteredLoadingIndicator />
  );
};

const QuestEditStartConfigurationScreenWrapper: React.FC = () => {
  const { questPrototypeId, startTriggerId } =
    useAppRoute<"QuestEditStart">().params ?? {};
  const hasBeenFocused = useScreenHasBeenFocusedAtLeastOnce();

  const key = `${questPrototypeId}_${startTriggerId}`;
  return hasBeenFocused ? (
    <QuestEditStartConfigurationScreen key={key} />
  ) : (
    <CenteredLoadingIndicator />
  );
};
