import { Text } from "@app/components/questkit";
import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import SafeAreaView from "react-native-safe-area-view";
import styled from "styled-components/native";
import { Keyboard, View } from "react-native";
import { MediaContext } from "@app/context/MediaContext";
import QKModal, { MODAL_CLOSE_DELAY } from "@app/components/modal";
import ItemOptionsDialog, {
  itemTypeViewDataList,
  itemTypeViewDataMap,
} from "@app/components/modal/itemOptionsDialog";
import {
  EditLevel,
  QuestSectionDivider,
  QuestView,
  ReviewButtonBehavior,
  StatusMessageBehavior,
  SubmitButtonBehaviour,
} from "@app/components/screen/quest/common/questView";
import { Analytics } from "@app/analytics";
import { useQuestViewContext } from "@app/quest/QuestViewContext";
import Icon, { IconIdentifier } from "@app/components/icon";
import {
  PartialCompletionActionData,
  useCompletionActions,
} from "@app/quest/edit/completionActions";
import { PartialItemData, useItems } from "@app/quest/edit/items";
import Button from "@app/components/questkit/button";
import { Boundary } from "../boundary";
import QKScrollView, {
  type QKScrollViewController,
} from "@app/components/questkit/ScrollView";
import { ItemType } from "@questmate/openapi-spec";
import { useModal } from "@app/components/modal/ModalManager";
import { useEffectOnce } from "@app/util/useEffectOnce";
import StartQuestDialog from "@app/components/modal/startQuestDialog";
import { useFocusableRef, useFocusController } from "@app/util/focus";
import {
  DropdownOption,
  ModalCard,
} from "@app/components/questkit/dropdownWithModal";
import {
  completionActionTypeViewDataList,
  completionActionTypeViewDataMap,
} from "@app/components/modal/completionActionOptionsDialog";
import { CompletionActionType } from "@app/types/completionActionRenderData";
import { Image } from "expo-image";
import { useRequest } from "@app/util/client/requests";
import { fetchLibraryQuestScripts } from "@app/util/client/requests/library";
import {
  QuestSectionHeader,
  QuestSectionHeaderDescriptionText,
} from "@app/components/screen/quest/questSectionHeader";
import { ItemInfoIcon } from "@app/components/item/itemInfoEntry";
import { useAppNavigation } from "@app/navigation/QMNavigator";
import { HeaderIcon } from "@app/navigation/components/HeaderIcon";
import { useQuestContext } from "@app/quest/QuestContext";
import { usePromise } from "@app/util/usePromise";
import { SnackbarContext } from "@app/components/snackbar/SnackbarContext";
import { createLink } from "@app/util/link.utils";
import { UserListSyncKey } from "@app/components/questkit/UserList/UserList.controller";
import { useStateWithRef } from "@app/components/questkit/useStateWithRef";

interface QuestEditViewProps {}
/**
 *
 * Likely duplication with QuestConfigurationView
 * as there is quite some overlap currently.
 *
 */
export const QuestEditView: React.FC<QuestEditViewProps> = () => {
  const { data: libraryQuestScripts } = useRequest(fetchLibraryQuestScripts());
  useEffect(() => {
    if (Array.isArray(libraryQuestScripts)) {
      const logoIconUrls = libraryQuestScripts
        .flatMap(({ integrations }) => integrations ?? [])
        .map(({ logoIcon }) => logoIcon?.url)
        .filter((url) => !!url);
      Image.prefetch(logoIconUrls);
    }
  }, [libraryQuestScripts]);

  const { currentQuestPrototypeId: rootQuestPrototypeId, onboarding } =
    useQuestContext();
  const {
    questId,
    questPrototypeId,
    useQuestPrototypeWithChanges,
    addChange,
    markAllFieldsAsTouched,
    useScopedValidationErrors,
    save,
    advancedMode,
  } = useQuestViewContext(["MANAGE"]);
  const questPrototype = useQuestPrototypeWithChanges((qp) => {
    return {
      id: qp.id,
      name: qp.name,
      startTriggers: qp.startTriggerIds?.map(
        (id) => qp.startTriggersById![id]!
      ),
      hasItems: qp.itemIds && qp.itemIds.length > 0,
      introText: qp.introText,
      parentItemPrototypeId: qp.parentItemPrototypeId,
    };
  });

  const firstStartTriggerType = questPrototype.startTriggers?.[0]?.type;

  const assigneeSynchronizationKey = useMemo(
    () => new UserListSyncKey("Assignee Sync Key For This Quest"),
    []
  );
  const { openModal: openStartQuestModal, addListener: addStartModalListener } =
    useModal(({ showModal, setShowModal }) => (
      <QKModal
        testID="start-quest-modal"
        showModal={showModal}
        setShowModal={setShowModal}
        title={
          firstStartTriggerType === "KIOSK"
            ? "Logout & Start Kiosk Mode"
            : "New Quest Run"
        }
      >
        <StartQuestDialog
          questId={questId}
          rootQuestPrototypeId={questPrototypeId}
          name={questPrototype.name}
          setShowStartQuestModal={setShowModal}
          assigneeSynchronizationKey={assigneeSynchronizationKey}
        />
      </QKModal>
    ));

  useEffectOnce(() =>
    addStartModalListener((event) => {
      if ("showModal" in event.updatedFields) {
        if (event.updatedFields.showModal) {
          Analytics.trackEvent("Open Start New Run Dialog", {
            screen: "Quest Template",
            questPrototypeId,
            questType: firstStartTriggerType!,
          });
        } else {
          Analytics.trackEvent("Close Start New Run Dialog");
        }
      }
    })
  );

  const [showItemOptionsModal, setShowItemOptionsModal] = useState<
    number | undefined
  >();

  const onIntroTextChange = useCallback(
    (updatedIntroText: string) => {
      addChange((draft) => {
        draft.introText = updatedIntroText;
      });
    },
    [addChange]
  );

  const {
    completionActions,
    onCompletionActionAdded,
    onCompletionActionDelete,
    onCompletionActionReorder,
    onCompletionActionChange,
    onCompletionActionValidationContextChange,
    onCompletionActionTouched,
  } = useCompletionActions();

  const { items, onItemAdded, onItemDelete, onItemReorder, onItemChange } =
    useItems();

  const onShowItemOptions = useCallback(
    (index: number) => {
      Analytics.trackEvent("Open Item Options Dialog");
      setShowItemOptionsModal(index);
    },
    [setShowItemOptionsModal]
  );

  const { openModal: showAddItemModal } = useModal(
    ({ setShowModal, showModal }) => {
      const modalSearchInputRef = useFocusableRef("item-picker-search-input");
      const focusController = useFocusController();
      useEffect(() => {
        focusController.focus("item-picker-search-input");
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
      const options = useMemo(
        () => [
          ...itemTypeViewDataList
            .filter(({ isAdvanced }) => !isAdvanced || advancedMode)
            .map(({ value, label, icon, searchTerms }) => {
              return {
                key: value,
                value,
                name: label,
                icon,
                searchTerms,
              };
            }),
          ...(libraryQuestScripts ?? [])
            .filter(({ useType }) => useType === "item")
            .map(({ id, name, integrations }) => {
              const iconUrl = integrations?.[0]?.logoIcon?.url;
              return {
                key: id,
                value: `SCRIPT_REF:${id}`,
                name,
                icon: iconUrl
                  ? {
                      url: iconUrl,
                    }
                  : ("terminal-prompt" as const),
                searchTerms: integrations?.map(({ name }) => name) ?? [],
              };
            })
            .sort((a, b) => a.name.localeCompare(b.name)),
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [libraryQuestScripts, advancedMode]
      );
      const newItemPosition = items.length;
      const onSelectOption = useCallback(
        (option: DropdownOption) => {
          const itemData: PartialItemData = {};
          if (`${option.value}`.startsWith("SCRIPT_REF:")) {
            itemData.customScript = option.value as string;
            itemData.type = "CustomV2";
            itemData.name = option.name;
          } else {
            itemData.type = option.value as ItemType;
            if (itemData.type === "CustomV2") {
              itemData.customScript = "SCRIPT_REF:custom-item-initial-script";
            }
          }

          const newItemId = onItemAdded(newItemPosition, itemData);
          setShowModal(false);
          // Unfortunately, this is a bit variable across platforms and can be affected by lag.
          const delayForModalDismissAnimationToComplete = 650;
          setTimeout(
            () => focusController.focus(newItemId),
            delayForModalDismissAnimationToComplete
          );
        },
        [setShowModal, focusController, newItemPosition]
      );
      return (
        <QKModal
          title="Choose Item Type"
          showModal={showModal}
          setShowModal={setShowModal}
        >
          <ModalCard
            selectedOption={null}
            onSelectOption={onSelectOption}
            options={options}
            searchInputRef={modalSearchInputRef}
            initialRenderCount={options.length}
          />
        </QKModal>
      );
    }
  );

  const { openModal: showAddCompletionActionModal } = useModal(
    ({ setShowModal, showModal }) => {
      const modalSearchInputRef = useFocusableRef(
        "completion-action-picker-search-input"
      );
      const focusController = useFocusController();
      useEffectOnce(() => {
        focusController.focus("completion-action-picker-search-input");
      });
      const options = useMemo(
        () => [
          ...completionActionTypeViewDataList
            .filter(({ isAdvanced }) => !isAdvanced || advancedMode)
            .map(({ value, label, icon, searchTerms }) => {
              return {
                key: value,
                value,
                name: label,
                icon,
                searchTerms,
              };
            }),
          ...(libraryQuestScripts ?? [])
            .filter(({ useType }) => useType === "completion_action")
            .map(({ id, name, integrations }) => {
              const iconUrl = integrations?.[0]?.logoIcon?.url;
              return {
                key: id,
                value: `SCRIPT_REF:${id}`,
                name,
                icon: iconUrl
                  ? {
                      url: iconUrl,
                    }
                  : ("terminal-prompt" as const),
                searchTerms: integrations?.map(({ name }) => name) ?? [],
              };
            })
            .sort((a, b) => a.name.localeCompare(b.name)),
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [libraryQuestScripts, advancedMode]
      );

      const newCompletionActionPosition = completionActions.length;
      const onSelectOption = useCallback(
        (option: DropdownOption) => {
          let data: PartialCompletionActionData;
          if (`${option.value}`.startsWith("SCRIPT_REF:")) {
            data = {
              name: option.name,
              type: "CustomV2",
              customScript: option.value as string,
            };
          } else {
            data = { type: option.value as CompletionActionType };
            if (data.type === "CustomV2") {
              data.customScript =
                "SCRIPT_REF:custom-completion-action-initial-script";
            }
          }

          onCompletionActionAdded(newCompletionActionPosition, data);
          setShowModal(false);
        },
        [setShowModal, newCompletionActionPosition]
      );

      return (
        <QKModal
          title="Choose Completion Action Type"
          showModal={showModal}
          setShowModal={setShowModal}
        >
          <ModalCard
            selectedOption={null}
            onSelectOption={onSelectOption}
            options={options}
            searchInputRef={modalSearchInputRef}
          />
        </QKModal>
      );
    }
  );

  // const introTextAvailable = Boolean(questPrototype.introText);
  // const fadeInDelay = introTextAvailable ? 650 : 250;

  const addItemCardIcons = useMemo((): IconIdentifier[] => {
    const featuredItemTypes: ItemType[] = [
      "MultiLineText",
      "Checklist",
      "Rating",
      "DateTime",
      "FileUpload",
      "Signature",
    ];
    const featuredItemIcons = featuredItemTypes.map(
      (itemType) => itemTypeViewDataMap[itemType].icon
    );

    const featuredIntegrationIcons =
      libraryQuestScripts
        ?.filter(({ useType }) => useType === "item")
        .flatMap(({ integrations }) => integrations ?? [])
        .filter(({ featured }) => featured)
        .filter(
          // remove duplicates
          (integration, index, self) =>
            self.findIndex(({ appId }) => appId === integration.appId) === index
        )
        .map(({ logoIcon }) => ({ url: logoIcon?.url } as IconIdentifier)) ??
      [];

    return [...featuredItemIcons, ...featuredIntegrationIcons];
  }, [libraryQuestScripts]);

  const addCompletionActionCardIcons = useMemo((): IconIdentifier[] => {
    const featuredCompletionActionTypes: CompletionActionType[] = [
      "TEXT",
      "TREMENDOUS_REWARD",
      "POST_SLACK_MESSAGE",
      "CustomV2",
    ];
    const featuredCompletionActionIcons = featuredCompletionActionTypes.map(
      (itemType) => completionActionTypeViewDataMap[itemType].icon
    );

    const featuredIntegrationIcons =
      libraryQuestScripts
        ?.filter(({ useType }) => useType === "completion_action")
        .flatMap(({ integrations }) => integrations ?? [])
        .filter(({ featured }) => featured)
        .filter(
          // remove duplicates
          (integration, index, self) =>
            self.findIndex(({ appId }) => appId === integration.appId) === index
        )
        .map(({ logoIcon }) => ({ url: logoIcon?.url } as IconIdentifier)) ??
      [];

    return [...featuredCompletionActionIcons, ...featuredIntegrationIcons];
  }, [libraryQuestScripts]);

  const scrollViewControllerRef = useRef<QKScrollViewController>(null);
  const navigation = useAppNavigation<"QuestEdit" | "QuestConfiguration">();

  const snackbar = useContext(SnackbarContext);

  const firstStartTriggerId = questPrototype.startTriggers?.[0]?.id;

  const [
    inProgressNavigationAction,
    setInProgressNavigationAction,
    inProgressNavigationActionRef,
  ] = useStateWithRef<null | "SAVE" | "BACK">(null);

  const navigateAfterSave = useCallback(() => {
    const navigationAction = inProgressNavigationActionRef.current;
    setInProgressNavigationAction(null);

    if (navigationAction === "BACK") {
      navigation.goBack();
      return;
    } else if (onboarding && firstStartTriggerId) {
      navigation.navigate("QuestEditStart", {
        questPrototypeId,
        startTriggerId: firstStartTriggerId,
        isChangingType: true,
      });
    } else {
      navigation.navigate("QuestConfiguration", {});
    }
  }, [
    inProgressNavigationActionRef,
    setInProgressNavigationAction,
    onboarding,
    firstStartTriggerId,
    navigation,
    questPrototypeId,
  ]);

  const validationErrors = useScopedValidationErrors([]);
  const { openModal: openSaveOverrideModal } = useModal(
    ({ setShowModal, showModal }) => {
      const [saveOverrideSuccess, setSaveOverrideSuccess] = useState(false);
      useLayoutEffect(() => {
        if (showModal) {
          setSaveOverrideSuccess(false);
        }
      }, [showModal]);
      const { isLoading: isSaving, execute: onIgnoreErrors } = usePromise(
        async () => {
          setSaveOverrideSuccess(false);
          Analytics.trackEvent("Save Quest Template Ignoring Errors");
          const saveResult = await save(true);
          if (saveResult.success) {
            setSaveOverrideSuccess(true);

            setTimeout(() => {
              setShowModal(false);
              navigateAfterSave();
            }, MODAL_CLOSE_DELAY);
          } else {
            snackbar.sendMessage("Failed to save changes. Try again later.");
          }
        }
      );
      const onContinueEditing = useCallback(() => {
        Analytics.trackEvent("Continue Editing Quest Template");
        setShowModal(false);
        setInProgressNavigationAction(null);
      }, [setShowModal]);

      const missingItems = validationErrors.some(
        (error) => error.errorCode === "NO_ITEMS"
      );
      const canIgnoreErrors =
        !missingItems ||
        // Do not trap the user on this screen, especially when onboarding.
        // They may just want to abort creating a Quest.
        inProgressNavigationAction === "BACK";
      const warningText = useMemo(() => {
        if (missingItems) {
          return "This Quest has no items. It will not be usable until you add at least one item.";
        } else {
          return "Warning: This Quest has errors that require your attention!";
        }
      }, [missingItems]);

      return (
        <QKModal
          showModal={showModal}
          setShowModal={setShowModal}
          title={"Quest Not Valid"}
        >
          <SaveOverrideDialogContainer>
            <ErrorWrapper>
              <ItemInfoIcon icon="info" />
              <Text size="medium">{warningText}</Text>
            </ErrorWrapper>
            {canIgnoreErrors ? (
              <StyledButton
                title="Ignore errors"
                buttonType="warning"
                onPress={onIgnoreErrors}
                loading={isSaving}
                success={saveOverrideSuccess}
              />
            ) : null}
            <StyledButton
              title="Continue editing"
              onPress={onContinueEditing}
              disabled={isSaving || saveOverrideSuccess}
            />
          </SaveOverrideDialogContainer>
        </QKModal>
      );
    }
  );
  const [saveSuccessful, setSaveSuccessful] = useState(false);
  const { isLoading: isSaving, execute: onSaveChanges } = usePromise(
    async () => {
      Analytics.trackEvent("Save Quest Template");
      setSaveSuccessful(false);
      Keyboard.dismiss();
      const saveResult = await save();
      markAllFieldsAsTouched();
      if (saveResult.success) {
        setSaveSuccessful(true);
        setTimeout(() => {
          navigateAfterSave();
          setSaveSuccessful(false);
        }, MODAL_CLOSE_DELAY);
      } else if (saveResult.result === "SAVE_FAILED") {
        snackbar.sendMessage("Failed to save changes. Try again later.");
      } else if (saveResult.result === "NOT_VALID") {
        openSaveOverrideModal();
      }
    }
  );
  useEffect(() => {
    navigation.setOptions({
      headerRight: () => {
        return (
          <HeaderButtonContainer>
            <Button
              title={"Save"}
              buttonType={"action"}
              testID="save-template-button"
              onPress={() => {
                setInProgressNavigationAction("SAVE");
                void onSaveChanges();
              }}
              loading={isSaving}
              success={saveSuccessful}
              loadingPosition={"right"}
            />
          </HeaderButtonContainer>
        );
      },
    });
  }, [
    navigation,
    onSaveChanges,
    isSaving,
    saveSuccessful,
    setInProgressNavigationAction,
  ]);
  useEffect(() => {
    const onPress =
      questPrototypeId === rootQuestPrototypeId
        ? () => {
            setInProgressNavigationAction("BACK");
            void onSaveChanges();
          }
        : createLink({
            screen: "Quest",
            params: {
              questId,
              screen: "QuestEdit",
              params: { questPrototypeId: rootQuestPrototypeId },
            },
          });
    navigation.setOptions({
      headerLeft: () => (
        <HeaderIcon onPress={onPress}>
          <Icon icon="chevron-left" size={32} />
        </HeaderIcon>
      ),
    });
  }, [
    navigation,
    questPrototypeId,
    rootQuestPrototypeId,
    questId,
    onSaveChanges,
    setInProgressNavigationAction,
  ]);

  const onSubmitInPreviewView = useCallback(() => {
    snackbar.sendMessage("Start a Quest run to submit.");
    return undefined;
  }, [snackbar]);

  return (
    <MediaContext.Provider
      value={{
        uploadContextType: "questPrototype",
        uploadContextId: questPrototypeId,
        contexts: [
          {
            id: questPrototypeId,
            type: "questPrototype",
          },
        ],
      }}
    >
      <View style={{ flex: 1, position: "relative" }}>
        <StyledScrollView
          keyboardShouldPersistTaps="always"
          ref={scrollViewControllerRef}
        >
          <SafeAreaView
            forceInset={{
              top: "never",
              bottom: "always",
            }}
          >
            <QuestView
              startTriggers={[]}
              openStartQuestModal={openStartQuestModal}
              introText={questPrototype.introText}
              onIntroTextChange={onIntroTextChange}
              items={items}
              onItemChange={onItemChange}
              onItemAdded={onItemAdded}
              onItemDelete={onItemDelete}
              onItemReorder={onItemReorder}
              onShowItemOptions={onShowItemOptions}
              onAddItemPressed={showAddItemModal}
              addItemCardIcons={addItemCardIcons}
              loading={false}
              completed={false}
              submitButtonTitle={"Submit"}
              itemsEditLevel={
                // viewMode === "EDIT"
                //   ?
                EditLevel.Editable
                // : EditLevel.Readonly
              }
              completionActionsEditLevel={
                // viewMode === "EDIT"
                //   ?
                EditLevel.Editable
                // : EditLevel.Interactive
              }
              submitButtonBehaviour={
                // viewMode === "READ_ONLY"
                //   ? SubmitButtonBehaviour.Submittable
                //   :
                SubmitButtonBehaviour.Hidden
              }
              onSubmit={onSubmitInPreviewView}
              reviewButtonBehavior={ReviewButtonBehavior.Hidden}
              statusMessageBehavior={StatusMessageBehavior.Hidden}
              canEditIntroText={true}
              sectionHeaders={{
                startTriggers: "HIDDEN",
                items: "DETAILED",
                completionActions: "DETAILED",
              }}
              completionActions={completionActions}
              onCompletionActionChange={onCompletionActionChange}
              onCompletionActionAdded={onCompletionActionAdded}
              onCompletionActionDelete={onCompletionActionDelete}
              onCompletionActionReorder={onCompletionActionReorder}
              onCompletionActionValidationContextChange={
                onCompletionActionValidationContextChange
              }
              onCompletionActionTouched={onCompletionActionTouched}
              onAddCompletionActionPressed={showAddCompletionActionModal}
              addCompletionActionCardIcons={addCompletionActionCardIcons}
            />

            <Boundary paddingHorizontal={20}>
              <QuestSectionDivider />
              <QuestSectionHeader
                text={`3. Use Your Quest`}
                description={
                  <QuestSectionHeaderDescriptionText size="small">
                    Press{" "}
                    <QuestSectionHeaderDescriptionText size="smallMedium">
                      Save & Continue
                    </QuestSectionHeaderDescriptionText>{" "}
                    to start using this Quest. You can then choose to{" "}
                    <QuestSectionHeaderDescriptionText size="smallMedium">
                      start & send directly,{" "}
                    </QuestSectionHeaderDescriptionText>
                    automatically start on a{" "}
                    <QuestSectionHeaderDescriptionText size="smallMedium">
                      schedule
                    </QuestSectionHeaderDescriptionText>
                    , via a{" "}
                    <QuestSectionHeaderDescriptionText size="smallMedium">
                      public share link
                    </QuestSectionHeaderDescriptionText>{" "}
                    or by{" "}
                    <QuestSectionHeaderDescriptionText size="smallMedium">
                      another app
                    </QuestSectionHeaderDescriptionText>
                    .
                  </QuestSectionHeaderDescriptionText>
                }
              />
              <View style={{ height: 20 }} />
              <Button
                title={"Save & Continue"}
                buttonType={"action"}
                onPress={onSaveChanges}
                loading={isSaving}
                success={saveSuccessful}
              />
            </Boundary>
            <View style={{ height: 40 }} />
          </SafeAreaView>
        </StyledScrollView>
      </View>

      <QKModal
        showModal={showItemOptionsModal !== undefined}
        setShowModal={() => setShowItemOptionsModal(undefined)}
        title="Item Options"
      >
        <ItemOptionsDialog
          item={items[showItemOptionsModal!]}
          parentQuestType={firstStartTriggerType ?? null}
          onItemChange={onItemChange}
          onModalClose={() => setShowItemOptionsModal(undefined)}
        />
      </QKModal>
    </MediaContext.Provider>
  );
};

const StyledScrollView = styled(QKScrollView).attrs({
  contentContainerStyle: {
    paddingBottom: 80,
  },
})`
  background-color: ${({ theme }) => theme.background};
`;

const HeaderButtonContainer = styled.View`
  margin-right: 8px;
`;

const ErrorWrapper = styled.View`
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  align-self: center;
`;

const SaveOverrideDialogContainer = styled(View)`
  padding: 16px;
`;
const StyledButton = styled(Button)`
  margin-top: 16px;
`;
