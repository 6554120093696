import React, {
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
} from "react";
import SafeAreaView from "react-native-safe-area-view";
import styled from "styled-components/native";
import { View } from "react-native";
import { MediaContext } from "@app/context/MediaContext";
import {
  EditLevel,
  QuestView,
  ReviewButtonBehavior,
  StatusMessageBehavior,
  SubmitButtonBehaviour,
} from "@app/components/screen/quest/common/questView";
import { Analytics } from "@app/analytics";
import { useQuestViewContext } from "@app/quest/QuestViewContext";
import { SnackbarContext } from "@app/components/snackbar/SnackbarContext";
import Icon from "@app/components/icon";
import { useCompletionActions } from "@app/quest/edit/completionActions";
import { useItems } from "@app/quest/edit/items";
import Button from "@app/components/questkit/button";
import { Boundary } from "../boundary";
import { HeaderIcon } from "@app/navigation/components/HeaderIcon";
import { useAppNavigation } from "@app/navigation/QMNavigator";
import QKScrollView from "@app/components/questkit/ScrollView";
import { getLoggedInUserId } from "@app/util/getLoggedInUserId";
// import QKModal from "@app/components/modal";
// import CreateQuest from "@app/components/modal/createQuest";
// import { useAppSelector } from "@app/store";
// import { selectQuestById } from "@app/store/cache/quests";
// import { selectQuestPrototypeById } from "@app/store/cache/questPrototypes";
// import {
//   fetchBillingSummary,
//   fetchQuestTemplatePurchaseStatus,
// } from "@app/util/client/requests/billing";
// import { useRequest } from "@app/util/client/requests";
// import Text from "@app/components/questkit/text";
// import { useModal } from "@app/components/modal/ModalManager";
// import { getPathForScreen } from "@app/navigation/linkingConfig";
// import { usePromise } from "@app/util/usePromise";
// import { apiRequest } from "@app/util/client";
// import { useLink, visitLink } from "@app/util/link.utils";
// import { sentry } from "@app/util/sentry";
// import type { OnPress } from "@app/components/questkit/BasePressable";
// import { useEffectOnce } from "@app/util/useEffectOnce";

type PublicPreviewQuestViewMode =
  // For public template clone screen
  | "PUBLIC_TEMPLATE_PREVIEW"
  // For creating template library screenshots
  | "PUBLIC_TEMPLATE_SCREENSHOT";

interface QuestPublicPreviewViewProps {
  viewMode?: PublicPreviewQuestViewMode;
  // initialUseQuestModalPosition?: "OPEN" | "CLOSED";
}

export const QuestPublicPreviewView: React.FC<QuestPublicPreviewViewProps> = ({
  viewMode = "PUBLIC_TEMPLATE_PREVIEW",
  // initialUseQuestModalPosition = "CLOSED",
}) => {
  const userIsLoggedIn = Boolean(getLoggedInUserId());

  const { questId, questPrototypeId, useQuestPrototypeWithChanges } =
    useQuestViewContext(["PREVIEW"]);
  const questPrototype = useQuestPrototypeWithChanges((qp) => {
    return {
      id: qp.id,
      name: qp.name,
      startTriggers: qp.startTriggerIds?.map(
        (id) => qp.startTriggersById![id]!
      ),
      introText: qp.introText,
      parentItemPrototypeId: qp.parentItemPrototypeId,
    };
  });
  // const rootQuestPrototypeName = useAppSelector((state) => {
  //   const currentQuestPrototypeId = selectQuestById(
  //     state,
  //     questId
  //   )?.currentQuestPrototypeId;
  //   if (!currentQuestPrototypeId) {
  //     return questPrototype.name;
  //   }
  //   const qp = selectQuestPrototypeById(state, currentQuestPrototypeId);
  //
  //   return qp ? qp.name : questPrototype.name;
  // });

  const navigation = useAppNavigation();
  // const [showCreateTemplateModal, setShowCreateTemplateModal] = useState(false);

  const snackbarContext = useContext(SnackbarContext);
  const onBackPress = useCallback(async () => {
    navigation.goBack();
  }, [navigation]);

  const headerLeft = useMemo(
    () =>
      function HeaderLeft() {
        return (
          <HeaderIcon
            activeOpacity={0.8}
            onPress={onBackPress}
            testID="back-button"
          >
            <Icon icon={"chevron-left"} size={32} />
          </HeaderIcon>
        );
      },
    [onBackPress]
  );

  useLayoutEffect(() => {
    if (viewMode === "PUBLIC_TEMPLATE_PREVIEW") {
      navigation.setOptions({
        headerLeft: userIsLoggedIn ? headerLeft : () => null,
      });
    } else if (viewMode === "PUBLIC_TEMPLATE_SCREENSHOT") {
      navigation.setOptions({
        headerShown: false,
      });
    }
  }, [navigation, viewMode, headerLeft, userIsLoggedIn]);

  const {
    completionActions,
    onCompletionActionAdded,
    onCompletionActionDelete,
    onCompletionActionReorder,
    onCompletionActionChange,
    onCompletionActionValidationContextChange,
    onCompletionActionTouched,
  } = useCompletionActions();

  const { items, onItemAdded, onItemDelete, onItemReorder, onItemChange } =
    useItems();

  const startTriggers = useMemo(() => {
    if (questPrototype.parentItemPrototypeId) {
      // is subquest
      return;
    }
    return questPrototype.startTriggers;
  }, [questPrototype.parentItemPrototypeId, questPrototype.startTriggers]);

  // const { data: billingSummaryData, hasData: billingSummaryDataHasLoaded } =
  //   useRequest(fetchBillingSummary(), {
  //     refreshInterval: 10000,
  //   });
  // const {
  //   data: questTemplatePurchaseStatus,
  //   hasData: questTemplatePurchaseStatusHasLoaded,
  // } = useRequest(fetchQuestTemplatePurchaseStatus(questId), {
  //   refreshInterval: 10000,
  // });

  // const { openModal: openCreateQuestModal } = useModal(
  //   ({ showModal, setShowModal }) => {
  //     return (
  //       <QKModal
  //         showModal={showModal}
  //         setShowModal={setShowModal}
  //         title="Create blank Quest"
  //       >
  //         <CreateQuest
  //           createButtonText="Create Quest"
  //           defaultName={rootQuestPrototypeName}
  //           setShowModal={setShowModal}
  //         />
  //       </QKModal>
  //     );
  //   }
  // );

  // const { openModal: openPurchaseDialog } = useModal(
  //   ({ showModal, setShowModal }) => {
  //     return (
  //       <QKModal
  //         title={"Unlock Template"}
  //         displayMode={ModalDisplayMode.FULL_SCREEN}
  //         showModal={showModal}
  //         setShowModal={setShowModal}
  //       >
  //         <PurchaseTemplateOrSubscribeDialog
  //           questId={questId}
  //           setShowModal={setShowModal}
  //           openCreateQuestModal={openCreateQuestModal}
  //         />
  //       </QKModal>
  //     );
  //   }
  // );

  // const [isWaitingToOpenUseQuestModal, setIsWaitingToOpenUseQuestModal] =
  //   useState(false);

  // const userDoesNotHaveASubscription =
  //   billingSummaryData?.subscriptionStatus === "INACTIVE";
  // const questIsPurchasable =
  //   questTemplatePurchaseStatus?.status === "PURCHASABLE";
  // const openCorrectDialogForUseQuest = useCallback(() => {
  //   if (
  //     userDoesNotHaveASubscription &&
  //     questIsPurchasable &&
  //     Platform.OS === "web"
  //   ) {
  //     Analytics.trackEvent("Template Unlock Modal Opened", { questId });
  //     openPurchaseDialog();
  //     // navigation.navigate("Billing", {
  //     //   returnUrl: getPathForScreen({
  //     //     screen: route.name,
  //     //     params: route.params,
  //     //   }),
  //     // });
  //   } else {
  //     setShowCreateTemplateModal(true);
  //   }
  // }, [
  //   userDoesNotHaveASubscription,
  //   questIsPurchasable,
  //   openPurchaseDialog,
  //   questId,
  // ]);

  const onUseQuest = useCallback(() => {
    if (userIsLoggedIn) {
      navigation.navigate("TemplateCreate", {
        sourceTemplateId: questId,
      });
      // if (
      //   !billingSummaryDataHasLoaded ||
      //   !questTemplatePurchaseStatusHasLoaded
      // ) {
      //   setIsWaitingToOpenUseQuestModal(true);
      // } else {
      //   openCorrectDialogForUseQuest();
      // }
    } else {
      // navigation.navigateToLoginThenRedirectBackHere();
      navigation.navigateToLoginThenRedirectTo("TemplateCreate", {
        sourceTemplateId: questId,
      });
    }
  }, [
    navigation,
    userIsLoggedIn,
    questId,
    // billingSummaryDataHasLoaded,
    // questTemplatePurchaseStatusHasLoaded,
    // openCorrectDialogForUseQuest,
  ]);

  // useEffectOnce(() => {
  //   if (initialUseQuestModalPosition === "OPEN") {
  //     onUseQuest();
  //   }
  // });

  const onPressUseQuest = useCallback(() => {
    Analytics.trackEvent("Press Use Template", {
      templateId: questId,
      questPrototypeId: questPrototypeId,
      userIsLoggedIn,
    });
    onUseQuest();
  }, [questId, questPrototypeId, userIsLoggedIn, onUseQuest]);

  // useEffect(() => {
  //   if (
  //     isWaitingToOpenUseQuestModal &&
  //     billingSummaryDataHasLoaded &&
  //     questTemplatePurchaseStatusHasLoaded
  //   ) {
  //     setIsWaitingToOpenUseQuestModal(false);
  //     openCorrectDialogForUseQuest();
  //   }
  // }, [
  //   isWaitingToOpenUseQuestModal,
  //   billingSummaryDataHasLoaded,
  //   questTemplatePurchaseStatusHasLoaded,
  //   openCorrectDialogForUseQuest,
  // ]);

  return (
    <MediaContext.Provider
      value={{
        uploadContextType: "questPrototype",
        uploadContextId: questPrototypeId,
        contexts: [
          {
            id: questPrototypeId,
            type: "questPrototype",
          },
        ],
      }}
    >
      <StyledScrollView keyboardShouldPersistTaps="always">
        <SafeAreaView
          forceInset={{
            top: viewMode === "PUBLIC_TEMPLATE_PREVIEW" ? "always" : "never",
            bottom: "always",
          }}
        >
          <View
            onStartShouldSetResponderCapture={() => true}
            onResponderTerminationRequest={
              () => true /* do not show snackbar message when touch scrolling */
            }
            onResponderEnd={() => {
              snackbarContext.sendMessage('Press "Use Quest" to continue.');
            }}
          >
            <QuestView
              startTriggers={startTriggers}
              introText={questPrototype.introText}
              items={items}
              onItemChange={onItemChange}
              onItemAdded={onItemAdded}
              onItemDelete={onItemDelete}
              onItemReorder={onItemReorder}
              loading={false}
              completed={false}
              submitButtonTitle={"Submit"}
              itemsEditLevel={EditLevel.Readonly}
              completionActionsEditLevel={EditLevel.Readonly}
              submitButtonBehaviour={
                viewMode === "PUBLIC_TEMPLATE_SCREENSHOT"
                  ? SubmitButtonBehaviour.Submittable
                  : SubmitButtonBehaviour.Hidden
              }
              reviewButtonBehavior={ReviewButtonBehavior.Hidden}
              statusMessageBehavior={StatusMessageBehavior.Hidden}
              canEditIntroText={false}
              sectionHeaders={{
                startTriggers: "HIDDEN",
                items: "HIDDEN",
                completionActions:
                  completionActions.length > 0 ? "MINIMAL" : "HIDDEN",
              }}
              completionActions={completionActions}
              onCompletionActionChange={onCompletionActionChange}
              onCompletionActionAdded={onCompletionActionAdded}
              onCompletionActionDelete={onCompletionActionDelete}
              onCompletionActionReorder={onCompletionActionReorder}
              onCompletionActionValidationContextChange={
                onCompletionActionValidationContextChange
              }
              onCompletionActionTouched={onCompletionActionTouched}
            />
            <View style={{ height: 80 }} />
          </View>
        </SafeAreaView>
      </StyledScrollView>

      {viewMode === "PUBLIC_TEMPLATE_SCREENSHOT" ? null : (
        <ActionButtonBottomPanel mode={viewMode}>
          <Boundary>
            <ActionButtonRow>
              {viewMode === "PUBLIC_TEMPLATE_PREVIEW" ? (
                <ActionButton
                  onPress={onPressUseQuest}
                  title={"Use Quest"}
                  // loading={isWaitingToOpenUseQuestModal}
                  // disabled={isWaitingToOpenUseQuestModal}
                />
              ) : null}
            </ActionButtonRow>
          </Boundary>
        </ActionButtonBottomPanel>
      )}

      {/*<QKModal*/}
      {/*  showModal={showCreateTemplateModal}*/}
      {/*  setShowModal={setShowCreateTemplateModal}*/}
      {/*  title="Create Quest From Template"*/}
      {/*>*/}
      {/*  <CreateQuest*/}
      {/*    createButtonText="Create Quest"*/}
      {/*    setShowModal={setShowCreateTemplateModal}*/}
      {/*    sourceTemplateId={questId}*/}
      {/*    defaultName={questPrototype.name}*/}
      {/*    recommendedStartTriggerType={questPrototype.startTriggers?.[0]?.type}*/}
      {/*  />*/}
      {/*</QKModal>*/}
    </MediaContext.Provider>
  );
};

const StyledScrollView = styled(QKScrollView).attrs({
  contentContainerStyle: {
    paddingBottom: 80,
  },
})`
  background-color: ${({ theme }) => theme.background};
`;

const ActionButtonBottomPanel = styled.View<{
  mode: PublicPreviewQuestViewMode;
}>`
  position: absolute;
  justify-content: center;
  bottom: 0;
  height: 80px;
  width: 100%;
  background-color: ${({ theme }) => theme.actionBar.background};
`;

const ActionButton = styled(Button)`
  flex: 1;
`;

const ActionButtonRow = styled.View`
  flex-direction: row;
  justify-content: center;
  margin-horizontal: 20px;
  gap: 10px;
`;

// type PurchaseTemplateOrSubscribeDialogProps = {
//   questId: string;
//   setShowModal: (show: boolean) => void;
//   openCreateQuestModal: () => void;
// };
// export const PurchaseTemplateOrSubscribeDialog: React.FC<
//   PurchaseTemplateOrSubscribeDialogProps
// > = ({ questId, setShowModal, openCreateQuestModal }) => {
//   const closeModal = useCallback(() => setShowModal(false), [setShowModal]);
//
//   const { data: questTemplatePurchaseStatus } = useRequest(
//     fetchQuestTemplatePurchaseStatus(questId)
//   );
//
//   const rootQuestPrototypeId = useAppSelector((state) => {
//     return selectQuestById(state, questId)?.currentQuestPrototypeId;
//   });
//
//   const onPressSubscriptionCTA = useLink(
//     {
//       screen: "Billing",
//       params: {
//         returnUrl: encodeURIComponent(
//           getPathForScreen({
//             screen: "PublicTemplatePreview",
//             params: {
//               questPrototypeId: rootQuestPrototypeId,
//               status: "purchase-subscription-success",
//               action: "use-quest",
//             },
//           })!
//         ),
//       },
//     },
//     { onPressHook: closeModal }
//   );
//
//   const {
//     execute: onPressOneOffPurchaseCTA,
//     isLoading: isLoadingOneOffPurchaseCheckoutSession,
//   } = usePromise(async () => {
//     Analytics.trackEvent("Start New Template Purchase Checkout Session", {
//       questId,
//     });
//     await apiRequest<{ url: string }>(
//       "post",
//       "/internal/billing/stripe/checkout/session",
//       {
//         successUrl: getPathForScreen({
//           screen: "PublicTemplatePreview",
//           params: {
//             questPrototypeId: rootQuestPrototypeId,
//             status: "purchase-template-success",
//             action: "use-quest",
//           },
//         }),
//         cancelUrl: getPathForScreen({
//           screen: "PublicTemplatePreview",
//           params: {
//             questPrototypeId: rootQuestPrototypeId,
//             status: "purchase-template-cancelled",
//           },
//         }),
//         type: "QUEST_TEMPLATE",
//         questId,
//       }
//     )
//       .then((result) => {
//         visitLink(result.url);
//       })
//       .catch((error) => {
//         console.error("Failed to start new one-off checkout session", error);
//         sentry.captureException(error);
//       });
//   });
//
//   const onPressCreateQuestCTA = useCallback(() => {
//     Analytics.trackEvent("Create New Quest Modal Opened");
//     closeModal();
//     openCreateQuestModal();
//   }, [closeModal, openCreateQuestModal]);
//
//   return (
//     <DialogContainer>
//       <OptionsList>
//         {questTemplatePurchaseStatus?.status === "PURCHASABLE" ? (
//           <OptionCard
//             title={"One-time purchase"}
//             priceInCents={questTemplatePurchaseStatus.price}
//             features={["Use immediately", "One-time payment"]}
//             ctaLabel={"Purchase"}
//             ctaOnPress={onPressOneOffPurchaseCTA}
//             ctaIsLoading={isLoadingOneOffPurchaseCheckoutSession}
//           />
//         ) : null}
//
//         <OptionCard
//           title={"Subscribe to Questmate"}
//           priceInCents={800}
//           priceIsVariable={true}
//           costFrequency={"per month"}
//           features={["Gain immediate access", "Unlimited Template Use"]}
//           ctaLabel={"See plans"}
//           ctaOnPress={onPressSubscriptionCTA}
//         />
//
//         <OptionCard
//           title={"Create your own"}
//           priceInCents={0}
//           features={["Get started right away"]}
//           ctaLabel={"Start from scratch"}
//           ctaOnPress={onPressCreateQuestCTA}
//         />
//         <View style={{ height: 80, width: "100%" }} />
//       </OptionsList>
//     </DialogContainer>
//   );
// };

// type OptionCardProps = {
//   title: string;
//   priceInCents: number;
//   priceIsVariable?: boolean;
//   costFrequency?: string;
//   features: string[];
//   ctaLabel: string;
//   ctaOnPress: OnPress;
//   ctaIsLoading?: boolean;
// };
// export const OptionCard: React.FC<OptionCardProps> = ({
//   title,
//   priceInCents,
//   priceIsVariable = false,
//   costFrequency,
//   features,
//   ctaLabel,
//   ctaOnPress,
//   ctaIsLoading = false,
// }) => {
//   const dimensions = useWindowDimensions();
//
//   const isMobile = dimensions.width < 650;
//   return (
//     <View>
//       <Option isMobile={isMobile}>
//         <OptionDetails isMobile={isMobile}>
//           <PrimaryColumn isMobile={isMobile}>
//             <PriceContainer>
//               <OptionPrice size="xlarge">
//                 {
//                   priceInCents === 0
//                     ? "FREE"
//                     : // : `$${(priceInCents % 100 === 0 ? Math.trunc(priceInCents / 100) : (priceInCents / 100).toLocaleString(undefined, {maximumFraction}))}`
//                       `$${(priceInCents / 100).toLocaleString(undefined, {
//                         maximumFractionDigits: priceInCents % 100 === 0 ? 0 : 2,
//                         minimumFractionDigits: priceInCents % 100 === 0 ? 0 : 2,
//                       })}`
//                   // (priceInCents / 100).toLocaleString(undefined, {
//                   //   currency: "USD",
//                   //   style: "currency",
//                   //   maximumFractionDigits: priceInCents % 100 === 0 ? 0 : 2,
//                   //   minimumFractionDigits: priceInCents % 100 === 0 ? 0 : 2,
//                   // })
//                 }
//                 {priceIsVariable ? "+" : ""}
//               </OptionPrice>
//               {costFrequency ? (
//                 <CostFequencyText>{costFrequency}</CostFequencyText>
//               ) : null}
//             </PriceContainer>
//           </PrimaryColumn>
//           <SecondaryColumn isMobile={isMobile}>
//             <OptionName size="large">{title}</OptionName>
//             <OptionFeatures>
//               {features.map((feature, index) => (
//                 <PlanFeature key={index}>
//                   <Text size="mediumBold">✨</Text>
//                   <Text size="mediumBold">{feature}</Text>
//                 </PlanFeature>
//               ))}
//             </OptionFeatures>
//           </SecondaryColumn>
//         </OptionDetails>
//         <CTAButton
//           title={ctaLabel}
//           onPress={ctaOnPress}
//           loading={ctaIsLoading}
//         />
//       </Option>
//     </View>
//   );
// };

// const CostFequencyText = styled(Text)`
//   text-align: center;
// `;
// const CTAButton = styled(Button)`
//   //margin-bottom: 20px;
//   width: 240px;
//   //width: 100%;
// `;
//
// const OptionName = styled(Text)`
//   text-align: center;
// `;
//
// const OptionPrice = styled(Text)`
//   color: ${({ theme }) => theme.interaction.primary};
//   text-align: center;
// `;
//
// const PriceContainer = styled.View`
//   align-items: center;
//   flex-direction: column;
// `;
//
// const OptionFeatures = styled.View`
//   width: 100%;
//   padding-horizontal: 40px;
//   padding-vertical: 40px;
//   justify-content: center;
//   gap: 20px;
// `;
//
// const PlanFeature = styled.View`
//   flex-direction: row;
//   gap: 10px;
// `;

// const cardHeight = (isMobile: boolean) => (isMobile ? 360 : 300);
// const BUTTON_HEIGHT = 80;
// const CARD_PADDING_VERTICAL = 20;

// const PrimaryColumn = styled.View<{ isMobile: boolean }>`
//   width: 140px;
//   min-height: ${({ isMobile }) =>
//     (cardHeight(isMobile) - BUTTON_HEIGHT - 2 * CARD_PADDING_VERTICAL) *
//     (isMobile ? 0.3 : 1)}px;
//   max-height: ${({ isMobile }) =>
//     (cardHeight(isMobile) - BUTTON_HEIGHT - 2 * CARD_PADDING_VERTICAL) *
//     (isMobile ? 0.3 : 1)}px;
//   justify-content: space-between;
// `;
// const SecondaryColumn = styled.View<{ isMobile: boolean }>`
//   width: ${({ isMobile }) => (isMobile ? 320 : 360)}px;
//   padding-top: 12px;
//   min-height: ${({ isMobile }) =>
//     (cardHeight(isMobile) - BUTTON_HEIGHT - 2 * CARD_PADDING_VERTICAL) *
//     (isMobile ? 0.7 : 1)}px;
//   max-height: ${({ isMobile }) =>
//     (cardHeight(isMobile) - BUTTON_HEIGHT - 2 * CARD_PADDING_VERTICAL) *
//     (isMobile ? 0.7 : 1)}px;
// `;

// const Option = styled.View<{ isMobile: boolean }>`
//   position: relative;
//   align-items: center;
//   background-color: ${({ theme }) => theme.card.background};
//   padding-vertical: ${CARD_PADDING_VERTICAL}px;
//   padding-horizontal: 20px;
//   border-radius: 10px;
//   min-height: ${({ isMobile }) => cardHeight(isMobile)}px;
//   max-height: ${({ isMobile }) => cardHeight(isMobile)}px;
//   flex: 1;
//   justify-content: space-between;
// `;

// const OptionDetails = styled.View<{ isMobile: boolean }>`
//   padding-top: 20px;
//   flex-direction: ${({ isMobile }) => (isMobile ? "column" : "row")};
//   gap: 20px;
//   align-items: center;
// `;

// const DialogContainer = styled(QKScrollView).attrs({
//   contentContainerStyle: {
//     alignItems: "center",
//   },
// })``;

// const OptionsList = styled.View`
//   flex-direction: row;
//   flex-wrap: wrap;
//   justify-content: center;
//   align-items: start;
//   max-width: 100%;
//   padding: 30px;
//   gap: 20px;
// `;
