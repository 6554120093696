import { SideBarItem } from "@app/navigation/sideBar/items/SideBarItem";
import * as React from "react";
import { useCallback, useMemo } from "react";
import { CombinedRoutesParamList } from "@app/navigation/QMNavigator";

interface SideBarWorkspaceQuestItemProps {
  questId: string;
  name: string;
  isOnboardingCompleted: boolean;
  isActiveOverride: boolean | undefined;
}

export const SideBarWorkspaceQuestItem: React.FC<
  SideBarWorkspaceQuestItemProps
> = ({ questId, name, isOnboardingCompleted, isActiveOverride }) => {
  const screenParams = useMemo(
    (): CombinedRoutesParamList["Quest"] => ({
      questId,
      ...(isOnboardingCompleted
        ? {
            screen: "QuestRuns",
            params: {},
          }
        : {
            // Take them to the start of the onboarding flow.
            screen: "QuestEdit",
            params: {},
          }),
    }),
    [isOnboardingCompleted, questId]
  );
  const screenDifferentiator = useCallback(
    (screenParams: CombinedRoutesParamList["Quest"]) => screenParams?.questId,
    []
  );
  return (
    <SideBarItem<"Quest", CombinedRoutesParamList["Quest"]>
      label={name}
      screenName={"Quest"}
      screenParams={screenParams}
      isActiveOverride={isActiveOverride}
      getSameScreenDifferentiatorValue={screenDifferentiator}
    />
  );
};
