import { Text } from "@app/components/questkit";
import React, { useCallback } from "react";
import SafeAreaView from "react-native-safe-area-view";
import styled from "styled-components/native";
import { View } from "react-native";
import { MediaContext } from "@app/context/MediaContext";
import { Analytics } from "@app/analytics";
import { useQuestViewContext } from "@app/quest/QuestViewContext";
import Button from "@app/components/questkit/button";
import { Boundary } from "../boundary";
import QKScrollView from "@app/components/questkit/ScrollView";
import { useAppNavigation } from "@app/navigation/QMNavigator";
import { useQuestContext } from "@app/quest/QuestContext";
import BasePressable from "@app/components/questkit/BasePressable";
import { useLink } from "@app/util/link.utils";
import { formatCronString } from "@app/quest/start/FormatCronString";
import { UserPicker } from "@app/components/questkit/UserList/UserPicker";
import { StandardUserEntry } from "@app/components/questkit/UserList/StandardUserEntry";
import { useCopyLinkToKeyboard } from "@app/quest/start/useCopyLinkToKeyboard";
import { StartTriggerEditFieldsForType } from "@app/store/cache/questStartTriggers";
import { useQrCodeModal } from "@app/quest/start/useQrCodeModal";
import { useModal } from "@app/components/modal/ModalManager";
import QKModal from "@app/components/modal/index";
import StartQuestDialog from "@app/components/modal/startQuestDialog/index";
import { CommonActions } from "@react-navigation/native";

interface QuestConfigurationViewProps {}

/**
 *
 * Likely duplication with QuestConfigurationView
 * as there is quite some overlap currently.
 *
 */
export const QuestConfigurationView: React.FC<
  QuestConfigurationViewProps
> = () => {
  const { questId, questPrototypeId, useQuestPrototypeWithChanges } =
    useQuestViewContext(["MANAGE"]);
  const questPrototype = useQuestPrototypeWithChanges((qp) => {
    return {
      id: qp.id,
      name: qp.name,
      startTriggers: qp.startTriggerIds?.map(
        (id) => qp.startTriggersById![id]!
      ),
      hasItems: qp.itemIds && qp.itemIds.length > 0,
      itemNames: qp.itemIds?.map((id) => qp.itemsById[id]!.name) ?? [],
      introText: qp.introText,
      parentItemPrototypeId: qp.parentItemPrototypeId,
    };
  });

  const firstStartTrigger = questPrototype.startTriggers?.[0];

  const navigation = useAppNavigation<"QuestConfiguration">();

  const questContext = useQuestContext();

  const { useScopedValidationErrors } = useQuestViewContext(["MANAGE"]);
  const validationErrors = useScopedValidationErrors([]);

  const hasValidationErrors = validationErrors.length > 0;

  const hasErrors = hasValidationErrors || !questPrototype.hasItems;

  const onEditItems = useLink(
    {
      screen: "Quest",
      params: {
        questId,
        screen: "QuestEdit",
      },
    },
    { onPressHook: () => Analytics.trackEvent("Edit Quest Template") }
  );
  const onEditStartMode = useLink(
    firstStartTrigger
      ? {
          screen: "Quest",
          params: {
            questId,
            screen: "QuestEditStart",
            params: {
              questPrototypeId: firstStartTrigger.questPrototypeId,
              startTriggerId: firstStartTrigger.id,
            },
          },
        }
      : undefined
    // { onPressHook: () => Analytics.trackEvent("Edit Quest Template") }
  );
  const onChangeStartMode = useLink(
    firstStartTrigger
      ? {
          screen: "Quest",
          params: {
            questId,
            screen: "QuestEditStart",
            params: {
              questPrototypeId: firstStartTrigger.questPrototypeId,
              startTriggerId: firstStartTrigger.id,
              isChangingType: true,
            },
          },
        }
      : undefined
    // { onPressHook: () => Analytics.trackEvent("Edit Quest Template") }
  );

  const onDone = useLink({
    screen: "Quest",
    params: {
      questId,
      screen: "QuestRuns",
    },
  });

  // For Basic & Schedule
  const { openModal: openStartQuestModal } = useModal(
    ({ showModal, setShowModal }) =>
      !questPrototype.name ? (
        <></>
      ) : (
        <QKModal
          testID="start-quest-modal"
          showModal={showModal}
          setShowModal={setShowModal}
          title={
            firstStartTrigger?.type === "KIOSK"
              ? "Logout & Start Kiosk Mode"
              : "New Quest Run"
          }
        >
          <StartQuestDialog
            questId={questId}
            rootQuestPrototypeId={questPrototypeId}
            name={questPrototype.name}
            setShowStartQuestModal={setShowModal}
          />
        </QKModal>
      )
  );

  const onTryQuestNow = useCallback(() => {
    Analytics.trackEvent("Try Quest Now", {
      questPrototypeId: questPrototypeId,
      startTriggerType: firstStartTrigger?.type,
    });

    switch (firstStartTrigger?.type) {
      case "BASIC":
      case "SCHEDULE":
        openStartQuestModal();
        break;
      case "KIOSK":
      case "PUBLIC": {
        // For Kiosk & Public Link
        const publicId = firstStartTrigger?.config?.publicId;
        if (publicId) {
          Analytics.trackEvent("Open Public Url", {
            questPrototypeId: questPrototypeId,
          });
          // Reset to just quest runs screen
          navigation.dispatch(
            CommonActions.reset({
              index: 0,
              routes: [{ name: "QuestRuns" }],
            })
          );
          setTimeout(() => {
            navigation.getParent().navigate("PublicAssignment", {
              id: publicId,
              disableLooping: true,
            });
          }, 0);
        }
      }
    }
  }, [firstStartTrigger, navigation, openStartQuestModal, questPrototypeId]);

  return (
    <MediaContext.Provider
      value={{
        uploadContextType: "questPrototype",
        uploadContextId: questPrototypeId,
        contexts: [
          {
            id: questPrototypeId,
            type: "questPrototype",
          },
        ],
      }}
    >
      <View
        style={{ flex: 1, position: "relative" }}
        testID="quest-configuration-view"
      >
        <StyledScrollView keyboardShouldPersistTaps="always">
          <SafeAreaView
            forceInset={{
              top: "never",
              bottom: "always",
            }}
          >
            <Boundary paddingHorizontal={20}>
              <ScreenTitle size={"large"}>Quest configuration</ScreenTitle>
              <QuestConfigurationSectionCard>
                <CardHeader>
                  <Text size={"mediumLargeBold"}>Template items</Text>
                  <BasePressable
                    onPress={onEditItems}
                    testID="edit-template-button"
                  >
                    <CTAText size={"mediumBold"}>Edit</CTAText>
                  </BasePressable>
                </CardHeader>
                {questPrototype.itemNames.length > 0 ? (
                  <CardSummaryText numberOfLines={1}>
                    {questPrototype.itemNames.join(", ")}
                  </CardSummaryText>
                ) : null}
                {hasErrors ? (
                  <ErrorWrapper>
                    <Text size="small" $warning>
                      {questPrototype.hasItems
                        ? `${validationErrors.length} ${
                            validationErrors.length === 1 ? "error" : "errors"
                          }`
                        : "Template is empty. Click Edit to add items."}
                    </Text>
                  </ErrorWrapper>
                ) : null}
              </QuestConfigurationSectionCard>
              {!firstStartTrigger ? (
                <Text>Failed to load Quest mode. Please try again.</Text>
              ) : (
                <>
                  <QuestConfigurationSectionCard>
                    <CardHeader>
                      <Text size={"mediumLargeBold"}>
                        Quest mode&nbsp;
                        <QuestModeText size={"mediumLarge"}>
                          (
                          {firstStartTrigger.type === "BASIC"
                            ? "Start and send directly"
                            : firstStartTrigger.type === "SCHEDULE"
                            ? "Schedule"
                            : firstStartTrigger.type === "PUBLIC"
                            ? "Public share link"
                            : firstStartTrigger.type === "KIOSK"
                            ? "Kiosk"
                            : "N/A"}
                          )
                        </QuestModeText>
                      </Text>
                      <BasePressable
                        onPress={onChangeStartMode}
                        testID="edit-quest-mode-button"
                      >
                        <CTAText size={"mediumBold"}>Edit</CTAText>
                      </BasePressable>
                    </CardHeader>
                    <CardSummaryText>
                      {firstStartTrigger.type === "BASIC"
                        ? "A Quest you start whenever needed. Complete it yourself or assign to others."
                        : firstStartTrigger.type === "SCHEDULE"
                        ? "Ensure things are done on a consistent schedule."
                        : firstStartTrigger.type === "PUBLIC"
                        ? "Share a link or QR code for anyone to fill our your Quest."
                        : firstStartTrigger.type === "KIOSK"
                        ? "Quests that are always ready for someone to walk up and fill out."
                        : "Update Questmate to see new things here!"}
                    </CardSummaryText>
                  </QuestConfigurationSectionCard>
                  {firstStartTrigger.type === "KIOSK" ||
                  firstStartTrigger.type === "BASIC" ? null : (
                    <QuestConfigurationSectionCard>
                      <CardHeader>
                        <Text size={"mediumLargeBold"}>Settings</Text>
                        <BasePressable
                          onPress={onEditStartMode}
                          testID="edit-settings-button"
                        >
                          <CTAText size={"mediumBold"}>Edit</CTAText>
                        </BasePressable>
                      </CardHeader>
                      {firstStartTrigger.type === "SCHEDULE" ? (
                        <ScheduleSettingsContent
                          startTrigger={firstStartTrigger}
                        />
                      ) : firstStartTrigger.type === "PUBLIC" ? (
                        <PublicLinkSettingsContent
                          startTrigger={firstStartTrigger}
                        />
                      ) : null}
                    </QuestConfigurationSectionCard>
                  )}
                </>
              )}
              {questContext.onboarding ? (
                <Button
                  title={"Try Quest now"}
                  onPress={onTryQuestNow}
                  testID="try-quest-now-button"
                />
              ) : (
                <Button title={"Done"} onPress={onDone} testID="done-button" />
              )}
            </Boundary>
          </SafeAreaView>
        </StyledScrollView>
      </View>
    </MediaContext.Provider>
  );
};

type ScheduleSettingsContentProps = {
  startTrigger: StartTriggerEditFieldsForType<"SCHEDULE">;
};
export const ScheduleSettingsContent: React.FC<
  ScheduleSettingsContentProps
> = ({ startTrigger }) => {
  const isConfigured =
    startTrigger.config.runSchedule &&
    (startTrigger.startConfiguration?.assignmentIds.length ?? 0) > 0;
  return (
    <>
      {startTrigger.enabled ? null : (
        <ErrorWrapper>
          <Text size="small" $warning>
            Schedule is disabled.
          </Text>
        </ErrorWrapper>
      )}
      <CardSummaryText>
        {isConfigured
          ? `Next run ${
              startTrigger.enabled ? "" : "would be "
            }${formatCronString(startTrigger.config.runSchedule!, true).replace(
              /^[A-Z]/,
              (firstCapitalCharacter) => firstCapitalCharacter.toLowerCase()
            )}. Assignees will be notified on every run.`
          : "No schedule set. Click Edit to setup the schedule."}
      </CardSummaryText>
      {isConfigured ? (
        <AssigneeSummary>
          <AssigneeSummaryHeader>Assign Quest to</AssigneeSummaryHeader>
          <UserPicker
            readOnly={true}
            users={
              startTrigger.startConfiguration?.assignments.map(
                ({ assignee }) => ({
                  userId: assignee.id,
                })
              ) ?? []
            }
            renderUserEntry={StandardUserEntry}
          />
        </AssigneeSummary>
      ) : null}
    </>
  );
};
const AssigneeSummary = styled.View`
  margin-top: 20px;
`;
const AssigneeSummaryHeader = styled(Text).attrs({ size: "smallMedium" })``;
const CardHeader = styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

type PublicLinkSettingsContentProps = {
  startTrigger: StartTriggerEditFieldsForType<"PUBLIC">;
};
export const PublicLinkSettingsContent: React.FC<
  PublicLinkSettingsContentProps
> = ({ startTrigger }) => {
  const questPublicUrl = startTrigger.config.publicUrl;
  const questPrototypeId = startTrigger.questPrototypeId;

  const { name } = useQuestContext();
  const setShowQrCodeModal = useQrCodeModal(
    questPrototypeId,
    name,
    questPublicUrl
  );
  const onShowQrCodeModal = useCallback(() => {
    setShowQrCodeModal(true);
  }, [setShowQrCodeModal]);

  const copyPublicUrlToClipboard = useCopyLinkToKeyboard(questPublicUrl);
  const onPressCopyLink = useCallback(() => {
    Analytics.trackEvent("Copy Public Url to Clipboard", {
      questPrototypeId,
      actionLocation: "Copy Link Primary Button",
    });
    copyPublicUrlToClipboard();
  }, [copyPublicUrlToClipboard, questPrototypeId]);

  return (
    <PublicLinkSettingsContentContainer>
      {startTrigger.enabled ? null : (
        <ErrorWrapper>
          <Text size="small" $warning>
            Link disabled
          </Text>
        </ErrorWrapper>
      )}
      <ActionButtonRow>
        <SettingsActionButton
          title={"Show QR code"}
          onPress={onShowQrCodeModal}
        />
        <SettingsActionButton title={"Copy link"} onPress={onPressCopyLink} />
      </ActionButtonRow>
    </PublicLinkSettingsContentContainer>
  );
};

const ActionButtonRow = styled.View`
  flex-direction: row;
  gap: 8px;
  margin-top: 20px;
`;

const SettingsActionButton = styled(Button).attrs({ buttonType: "secondary" })`
  flex: 1;
`;
const PublicLinkSettingsContentContainer = styled.View``;

const CTAText = styled(Text)`
  color: #511ead;
`;
const QuestModeText = styled(Text)`
  color: #806ea0;
  margin-left: 4px;
`;
const CardSummaryText = styled(Text).attrs({ size: "small" })`
  color: #806ea0;
  margin-top: 8px;
  padding-right: 60px;
`;

const QuestConfigurationSectionCard = styled.View`
  border-color: #d9d9d9;
  border-width: 1px;
  border-radius: 22px;
  padding: 20px;
  margin-bottom: 12px;
`;
const ScreenTitle = styled(Text)`
  margin-bottom: 12px;
`;

const StyledScrollView = styled(QKScrollView).attrs({
  contentContainerStyle: {
    paddingBottom: 80,
  },
})`
  background-color: ${({ theme }) => theme.background};
`;

const ErrorWrapper = styled.View`
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
`;
