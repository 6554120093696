import * as React from "react";
import { useCallback } from "react";
import { Analytics } from "@app/analytics";
import QKModal from "@app/components/modal";
import { QrCodeModalView } from "@app/quest/start/QrCodeModalView";
import { useModal } from "@app/components/modal/ModalManager";

export function useQrCodeModal(
  questPrototypeId: string,
  questName: string,
  questPublicUrl: string
): (show: boolean) => void {
  const { setShowModal } = useModal(({ showModal, setShowModal }) => {
    return (
      <QKModal
        testID="qr-code-modal"
        showModal={showModal}
        setShowModal={setShowModal}
        title="Scan to Start"
      >
        <QrCodeModalView
          filenamePrefix={questName}
          url={questPublicUrl!}
          onDownload={onDownloadQrCode}
        />
      </QKModal>
    );
  });

  const onDownloadQrCode = useCallback(() => {
    Analytics.trackEvent("Download QR Code", {
      questPrototypeId,
    });
    setShowModal(false);
  }, [questPrototypeId, setShowModal]);
  return setShowModal;
}
