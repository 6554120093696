import React, { useEffect, useMemo } from "react";
import styled from "styled-components/native";
import Loader from "@app/components/animated/loader";
import {
  QuestInstanceView,
  QuestInstanceViewProps,
} from "@app/components/screen/quest/questInstanceView";
import PlaceholderView from "@app/components/screen/PlaceholderView";

import { useQuestInstance } from "@app/util/client/hooks/useQuestInstance";
import Icon from "@app/components/icon";
import { RunQuestViewContextProvider } from "@app/quest/run/RunQuestViewContextProvider";
import { useAppNavigation, useAppRoute } from "@app/navigation/QMNavigator";
import {
  HeaderIcon,
  PlaceholderHeaderIcon,
} from "@app/navigation/components/HeaderIcon";
import { useAppSelector } from "@app/store";
import { selectLoggedInUserId, selectUserIsLoggedIn } from "@app/store/auth";
import { useEffectOnce } from "@app/util/useEffectOnce";
import { selectLoggedInUser } from "@app/store/cache/users";
import { AccessDeniedView } from "@app/components/screen/AccessDeniedView";
import { QuestCompletionNavigationConfig } from "@app/util/client/questCompletionNavigation";
import { CommonActions } from "@react-navigation/native";
import { getRouteFromPath } from "@app/navigation/linkingConfig";
import { createLink } from "@app/util/link.utils";
import { ScreenContainer } from "@app/screens/ScreenContainer";

export const QuestInstanceScreen: React.FC = () => {
  const route = useAppRoute<"QuestInstance">();
  const {
    id: questInstanceId,
    publicId,
    loginId,
    completionRedirect,
    preventBack,
    disableLooping,
  } = route.params;

  return (
    <ScreenContainer>
      <RunQuestViewContextProvider
        questInstanceId={questInstanceId}
        allowedAuthStrategies={["PUBLIC_QUEST", "AUTH0"]}
      >
        <QuestInstanceViewWrapper
          questInstanceId={questInstanceId}
          publicId={publicId}
          loginId={loginId}
          completionRedirect={completionRedirect}
          preventBack={preventBack}
          disableLooping={disableLooping}
        />
      </RunQuestViewContextProvider>
    </ScreenContainer>
  );
};

type QuestInstanceViewWrapperProps = {
  questInstanceId: string;
  publicId?: string;
  loginId?: string;
  completionRedirect?: string;
  preventBack?: boolean;
  disableLooping?: boolean;
};
const QuestInstanceViewWrapper: React.FC<QuestInstanceViewWrapperProps> = ({
  questInstanceId,
  publicId,
  loginId,
  completionRedirect,
  preventBack,
  disableLooping,
}) => {
  const navigation = useAppNavigation<"QuestInstance">();

  const userHasAuthentication = useAppSelector((state) => {
    const session = state.publicQuestAuth.sessions[questInstanceId];
    const hasPublicSessionTokenForThisQuest =
      questInstanceId && Boolean(session);
    const userIsLoggedIn = selectUserIsLoggedIn(state);
    return userIsLoggedIn || hasPublicSessionTokenForThisQuest;
  });
  useEffectOnce(() => {
    // Do not wait to make a failing request, instead navigate immediately to the correct screen.
    if (!userHasAuthentication) {
      if (publicId) {
        navigation.navigate("PublicAssignment", { id: publicId });
      } else {
        navigation.navigateToLoginThenRedirectBackHere();
      }
    }
  });

  const {
    completionActions,
    items,
    onItemChange,
    onItemValidate,
    onSubmit,
    onReview,
    submitQuestLoading,
    SWR: { data: questInstance, error, isValidating, refresh },
  } = useQuestInstance(questInstanceId);

  const loggedInUserId = useAppSelector(selectLoggedInUserId);
  const isLoggedInUserDataLoaded = useAppSelector(
    (state) =>
      selectLoggedInUser(state)?.email || selectLoggedInUser(state)?.phone
  );

  useEffect(() => {
    // @todo remove 401 and have API return 403 for this situation
    // 404 will be returned if the instance happens to be deleted (verify)
    const userNotAuthorized = error && [403, 404, 401].includes(error.status);
    if (userNotAuthorized) {
      if (publicId) {
        navigation.navigate("PublicAssignment", { id: publicId });
      } else if (!loggedInUserId) {
        navigation.navigateToLoginThenRedirectBackHere();
      }
    }
  }, [publicId, error, navigation, loggedInUserId]);

  const userIsQuestAdmin = Boolean(
    questInstance?.requestingUser.instanceRoles.some((role) => role === "OWNER")
  );
  const prototypeId = questInstance?.prototype.id;

  const templateId = questInstance?.quest.id;
  useEffect(() => {
    navigation.setOptions({
      ...(preventBack
        ? { headerLeft: (props) => <PlaceholderHeaderIcon {...props} /> }
        : {}),
      headerRight: () => {
        if (userIsQuestAdmin && templateId) {
          return (
            <HeaderIcon
              activeOpacity={0.8}
              onPress={createLink({
                screen: "AdminQuestRun",
                params: {
                  templateId,
                  questInstanceId,
                },
              })}
            >
              <Icon icon={"item"} />
            </HeaderIcon>
          );
        } else if (publicId) {
          return (
            <HeaderIcon
              activeOpacity={0.8}
              onPress={createLink({
                screen: "PublicAssignment",
                params: {
                  id: publicId,
                },
              })}
            >
              <Icon icon="refresh" />
            </HeaderIcon>
          );
        }
      },
    });
  }, [
    templateId,
    questInstanceId,
    userIsQuestAdmin,
    prototypeId,
    navigation,
    publicId,
    loggedInUserId,
    preventBack,
  ]);

  const completionNavigationConfig = useMemo(():
    | QuestCompletionNavigationConfig
    | undefined => {
    if (completionRedirect) {
      const route = getRouteFromPath(completionRedirect);
      if (route) {
        return {
          navigationAction: CommonActions.navigate(route),
          navigationDelay: 2000,
        };
      }
    }
    return undefined;
  }, [completionRedirect]);

  if (!questInstance) {
    const loggedOut = !loggedInUserId;
    const readyToShowError = loggedOut || isLoggedInUserDataLoaded;
    if (error && readyToShowError) {
      console.error("Error received while loading Quest instance", error);
      const userDoesNotHavePermission = error.status === 403;
      return (
        <StyledView>
          {userDoesNotHavePermission ? (
            <AccessDeniedView reload={refresh} loginId={loginId} />
          ) : (
            <PlaceholderView
              text="Oops, that didn't quite work."
              actions={[
                {
                  type: "primary",
                  text: "Reload",
                  loading: isValidating,
                  onPress: refresh,
                },
              ]}
            />
          )}
        </StyledView>
      );
    } else {
      return (
        <StyledView>
          <Loader />
        </StyledView>
      );
    }
  }

  return (
    <QuestInstanceView
      questInstanceId={questInstance.id}
      questPrototypeId={questInstance.prototype.id}
      name={questInstance.name}
      status={questInstance.status}
      slideMode={questInstance.slideMode}
      introText={questInstance.introText}
      dueAt={questInstance.dueAt}
      startedByUser={questInstance.startedByUser}
      items={items}
      completionActions={completionActions}
      requestingUser={questInstance.requestingUser}
      onItemChange={onItemChange}
      onItemValidate={onItemValidate}
      onSubmit={onSubmit as unknown as QuestInstanceViewProps["onSubmit"]}
      completionNavigationConfig={completionNavigationConfig}
      ignoreQuestSubmissionRestartBehavior={disableLooping ?? false}
      onReview={onReview}
      completeQuestLoading={submitQuestLoading}
      runNotYetStarted={questInstance.prototype.status === "DRAFT"}
    />
  );
};

const StyledView = styled.View`
  background-color: ${({ theme }) => theme.background};
  flex: 1;
  justify-content: center;
  align-items: center;
`;
