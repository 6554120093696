import * as React from "react";
import { useState } from "react";
import styled from "styled-components/native";
import Text from "@app/components/questkit/text";
import Icon from "@app/components/icon";
import { QuestStartTriggerType } from "@questmate/openapi-spec";
import QKModal, { MODAL_CLOSE_DELAY } from "@app/components/modal";
import { Platform, Pressable, ScrollView, View } from "react-native";
import { QuestSectionHeader } from "@app/components/screen/quest/questSectionHeader";
import Button from "@app/components/questkit/button";
import { usePromise } from "@app/util/usePromise";
import { useModal } from "@app/components/modal/ModalManager";
import { QUEST_TYPES } from "@app/quest/start/QuestTypes";
import { useEffectOnce } from "@app/util/useEffectOnce";
import { Analytics } from "@app/analytics";
import { useHover } from "@app/util/useHover";
import { type OnPress } from "@app/components/questkit/BasePressable";

interface ChooseQuestStartTriggerProps {
  context: "CHANGE_TYPE";
  onCancelChangeQuestType: OnPress;
  onQuestTypeSelected: (questType: QuestStartTriggerType) => Promise<void>;
  currentType: QuestStartTriggerType;
}
export const ChooseQuestStartTriggerView: React.FC<
  ChooseQuestStartTriggerProps
> = (props) => {
  const orderedQuestTypes: QuestStartTriggerType[] = [
    "BASIC",
    "SCHEDULE",
    "PUBLIC",
    "KIOSK",
  ];
  return (
    <ChooseQuestTypeStartViewContainer>
      <SupportText size="medium" testID="select-start-type-support-text">
        How do you plan to use this Quest?
      </SupportText>
      {orderedQuestTypes.map((questType) => {
        return (
          <QuestTypeListItem
            key={questType}
            questType={questType}
            onQuestTypeSelected={props.onQuestTypeSelected}
            isSelected={props.currentType === questType}
          />
        );
      })}
    </ChooseQuestTypeStartViewContainer>
  );
};

const SupportText = styled(Text)`
  text-align: center;
  margin-bottom: 50px;
`;
const ChooseQuestTypeStartViewContainer = styled.View`
  margin-vertical: 40px;
`;

type QuestTypeListItemProps = {
  questType: QuestStartTriggerType;
  onQuestTypeSelected: (questType: QuestStartTriggerType) => Promise<void>;
  isSelected: boolean;
};
export const QuestTypeListItem: React.FC<QuestTypeListItemProps> = ({
  questType,
  onQuestTypeSelected,
  isSelected,
}) => {
  const { icon, name, shortDescription, useCases } = QUEST_TYPES[questType];
  const { hoverProps } = useHover();

  const { openModal, closeModal, addListener } = useModal(
    ({ showModal, setShowModal }) => {
      return (
        <QKModal
          title={`Select Quest Mode`}
          showModal={showModal}
          setShowModal={setShowModal}
        >
          <QuestTypeModalView
            questType={questType}
            closeModal={closeModal}
            onQuestTypeSelected={onQuestTypeSelected}
          />
        </QKModal>
      );
    }
  );

  useEffectOnce(() =>
    addListener((event) => {
      if ("showModal" in event.updatedFields) {
        const modalOpened = event.updatedFields.showModal;
        if (modalOpened) {
          Analytics.trackEvent("Open Quest Type Modal", { questType });
        } else {
          Analytics.trackEvent("Close Quest Type Modal", { questType });
        }
      }
    })
  );

  return (
    <>
      <QuestTypeCard
        onPress={openModal}
        testID={`quest-type-${questType}`}
        isSelected={isSelected}
        {...hoverProps}
      >
        <QuestTypeIconContainer>
          <Icon icon={icon} size={60} />
        </QuestTypeIconContainer>
        <QuestTypeCardContent>
          <QuestTypeName>{name}</QuestTypeName>
          <QuestTypeDescription>{shortDescription}</QuestTypeDescription>
          <QuestTypeCardUseCasesSection>
            {useCases.map((useCase) => {
              return (
                <QuestTypeUseCasePill key={`${name}-${useCase}`}>
                  <QuestTypeUseCasePillText>{useCase}</QuestTypeUseCasePillText>
                </QuestTypeUseCasePill>
              );
            })}
          </QuestTypeCardUseCasesSection>
        </QuestTypeCardContent>
        <ExpandIconAligner>
          <Icon icon="expand" size={32} />
        </ExpandIconAligner>
      </QuestTypeCard>
    </>
  );
};
const ExpandIconAligner = styled.View`
  position: absolute;
  top: 10px;
  right: 10px;
`;
const QuestTypeName = styled(Text).attrs({ size: "mediumLargeBold" })`
  margin-bottom: 10px;
  ${Platform.OS === "web" ? "user-select: none" : ""}
`;

const QuestTypeDescription = styled(Text).attrs({
  size: "small",
})`
  ${Platform.OS === "web" ? "user-select: none" : ""}
`;

const QuestTypeCardUseCasesSection = styled.View`
  margin-top: 20px;
  margin-bottom: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;
  height: 30px;
`;

const QuestTypeModalUseCasesSection = styled.View`
  margin-bottom: 20px;
  flex-direction: row;
  flex-wrap: wrap;
`;

const QuestTypeUseCasePillText = styled(Text).attrs({
  size: "small",
  numberOfLines: 1,
})`
  color: ${({ theme }) => theme.background};
  ${Platform.OS === "web" ? "user-select: none" : ""}
`;
const QuestTypeUseCasePill = styled.View`
  padding: 5px 10px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.primary};
  margin-right: 10px;
  margin-bottom: 10px;
  height: 30px;
`;

const QuestTypeCardContent = styled.View`
  flex: 1;
  justify-content: center;
  padding-top: 20px;
  padding-right: 40px;
`;
const QuestTypeIconContainer = styled.View`
  width: 80px;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  border-style: solid;
  border-color: ${({ theme }) => theme.primary};
  border-right-width: 1px;
`;
const QuestTypeCard = styled(Pressable)<{
  isHovered: boolean;
  isSelected: boolean;
}>`
  flex-direction: row;
  border-radius: 20px;
  background-color: ${({ theme, isHovered }) =>
    isHovered ? theme.cardInCard : theme.card.background};
  overflow: hidden;
  max-width: 540px;
  width: 100%;
  margin-bottom: 20px;
  min-height: 120px;
  transition: background-color 0.15s ease-in-out;
  border: 1px solid
    ${({ isHovered, isSelected, theme }) =>
      isHovered || isSelected
        ? theme.textInput.focused.border
        : theme.textInput.normal.border};
`;

type QuestTypeModalViewProps = {
  questType: QuestStartTriggerType;
  closeModal: () => void;
  onQuestTypeSelected: (questType: QuestStartTriggerType) => Promise<void>;
};
export const QuestTypeModalView: React.FC<QuestTypeModalViewProps> = ({
  questType,
  closeModal,
  onQuestTypeSelected,
}) => {
  const [typeChangedSuccessfully, setTypeChangedSuccessfully] = useState(false);
  const { execute: onSelectQuestType, isLoading } = usePromise(async () => {
    setTypeChangedSuccessfully(false);
    return onQuestTypeSelected(questType).then(() => {
      setTypeChangedSuccessfully(true);
      setTimeout(() => {
        closeModal();
      }, MODAL_CLOSE_DELAY);
    });
  });

  const { icon, useCases, features, name, longDescription } =
    QUEST_TYPES[questType];

  return (
    <QuestTypeModalViewContainer>
      <ScrollView>
        <View onStartShouldSetResponder={() => true}>
          <QuestTypeIconModalContainer>
            <Icon icon={icon} size={80} />
          </QuestTypeIconModalContainer>
          <QuestTypeNameInModal>{name}</QuestTypeNameInModal>
          <QuestTypeDescriptionInModal>
            {longDescription}
          </QuestTypeDescriptionInModal>
          <FeaturesSectionHeader text="Features" />
          <FeaturesListContainer>
            {features.map((feature, index) => {
              return (
                <FeatureListItemRow key={`${name}-feature-${index}`}>
                  <FeatureListBulletPointAligner>
                    <FeatureListBulletPointIcon icon="star-filled-in" />
                  </FeatureListBulletPointAligner>

                  <FeatureListText>{feature}</FeatureListText>
                </FeatureListItemRow>
              );
            })}
          </FeaturesListContainer>
          <FeaturesSectionHeader text="Common Use Cases" />
          <QuestTypeModalUseCasesSection>
            {useCases.map((useCase) => {
              return (
                <QuestTypeUseCasePill key={`${name}-${useCase}`}>
                  <QuestTypeUseCasePillText>{useCase}</QuestTypeUseCasePillText>
                </QuestTypeUseCasePill>
              );
            })}
          </QuestTypeModalUseCasesSection>
        </View>
      </ScrollView>
      <SelectQuestTypeButton
        title="Choose this Start Mode"
        testID="choose-quest-type"
        onPress={onSelectQuestType}
        loading={isLoading}
        disabled={isLoading}
        success={typeChangedSuccessfully}
      />
      <CloseModalLink onPress={closeModal}>
        <CloseModalLinkText>Close</CloseModalLinkText>
      </CloseModalLink>
    </QuestTypeModalViewContainer>
  );
};

const SelectQuestTypeButton = styled(Button)`
  margin-top: 20px;
`;
const CloseModalLink = styled(Pressable)`
  margin-top: 20px;
  align-items: center;
`;
const FeatureListBulletPointIcon = styled(Icon)``;
const CloseModalLinkText = styled(Text).attrs({ $underlined: true })`
  ${Platform.OS === "web" ? "user-select: none" : ""}
`;
const FeaturesListContainer = styled.View`
  margin-bottom: 30px;
`;

const FeatureListItemRow = styled.View`
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 20px;
`;

const FeatureListText = styled(Text).attrs({ size: "small" })``;
const FeatureListBulletPointAligner = styled.View`
  justify-content: center;
  align-items: center;
  height: 19px;
  width: 19px;
  margin-left: -1px;
  margin-right: 5px;
`;

const FeaturesSectionHeader = styled(QuestSectionHeader)`
  margin-horizontal: 0;
`;

const QuestTypeNameInModal = styled(Text).attrs({ size: "large" })`
  margin-bottom: 20px;
  text-align: center;
`;

const QuestTypeDescriptionInModal = styled(Text).attrs({
  size: "medium",
})`
  margin-top: 10px;
  margin-bottom: 30px;
  text-align: center;
`;

const QuestTypeIconModalContainer = styled.View`
  align-items: center;
`;
const QuestTypeModalViewContainer = styled.View`
  flex-shrink: 1;
  padding: 0 20px 20px 20px;
`;
