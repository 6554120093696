import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components/native";
import { HeaderIcon } from "@app/navigation/components/HeaderIcon";
import { BackButton } from "@app/navigation/components/BackButton";
import { useAppNavigation, useAppRoute } from "@app/navigation/QMNavigator";
import { StackActions } from "@react-navigation/native";
import Text from "@app/components/questkit/text";
import { createPollerForRewardInstance } from "@app/util/client/hooks/useQuestInstance";
import PlaceholderView from "@app/components/screen/PlaceholderView";
import { LottiePlayer } from "@app/components/Animation";
import { colors } from "@app/themes/Colors";
import { useEffectOnce } from "@app/util/useEffectOnce";

export const CreateQuestLoadingScreen: React.FC = () => {
  const route = useAppRoute<"CreateQuestLoading">();
  const navigation = useAppNavigation();
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { questInstanceId, createQuestActionId } = route.params;

  const pollForReward = useCallback(() => {
    setIsLoading(true);
    const pollUntilCompleted = createPollerForRewardInstance(
      questInstanceId,
      createQuestActionId,
      true
    );

    pollUntilCompleted
      .then((reward) => {
        const rewardData = reward.data as
          | { createdQuestId: string; createdQuestPrototypeId: string }
          | undefined;
        if (!rewardData?.createdQuestId) {
          setHasError(true);
          return;
        }

        navigation.dispatch(
          StackActions.replace("Quest", {
            questId: rewardData.createdQuestId,
            screen: "QuestEdit",
            params: {
              questPrototypeId: rewardData.createdQuestPrototypeId,
            },
          })
        );
      })
      .catch((e) => {
        setHasError(true);
        console.error(
          "Unable to poll for create quest action data successfully.",
          e
        );
      })
      .finally(() => setIsLoading(false));
  }, [createQuestActionId, navigation, questInstanceId]);

  useEffectOnce(() => {
    pollForReward();
  });

  useEffect(() => {
    if (!isLoading && hasError) {
      navigation.setOptions({
        headerLeft: (props) => <BackButton {...props} />,
      });
    } else {
      navigation.setOptions({
        headerLeft: () => <HeaderIcon />,
      });
    }
  }, [isLoading, hasError, navigation]);

  return (
    <>
      {hasError && (
        <PlaceholderView
          text="Oops, that didn't quite work."
          actions={[
            {
              type: "primary",
              text: "Try again",
              loading: isLoading,
              onPress: pollForReward,
            },
          ]}
        />
      )}
      {!hasError && (
        <StyledView>
          <LottiePlayer />
          <StyledText size="xlarge">Setting up your Quest...</StyledText>
        </StyledView>
      )}
    </>
  );
};

const StyledView = styled.View`
  background-color: ${({ theme }) => theme.background};
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const StyledText = styled(Text)`
  color: ${colors.white};
  position: absolute;
  align-self: center;
  text-align: center;
  top: 15%;
  margin-left: 40px;
  margin-right: 40px;
`;
