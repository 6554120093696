import * as CustomItem from "@questmate/questscript";
import {
  ComponentDefinition,
  type InlineComponentDefinition,
  QuestDataIdentifier,
} from "@questmate/questscript";
import {
  CustomItemComponentModel,
  type CustomItemInlineComponentModel,
  CustomItemV2Response,
  ViewName,
} from "@app/components/item/components/custom/types";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { usePromise } from "@app/util/usePromise";
import { mapStaticProps } from "@app/components/item/components/custom/CommonModelMapper";
import { useEffectOnce } from "@app/util/useEffectOnce";
import { CustomItemView } from "@app/components/item/components/custom/edit/CustomItemManageView";
import { EventScheduler } from "@app/components/item/components/custom/v2/EventScheduler";
import { DataSourceCollection } from "@app/components/item/components/custom/v2/DataSourceFacade";
import { ActionHandlerCollection } from "@app/components/item/components/custom/v2/ActionHandlerFacade";
import { DropdownValue } from "@app/components/questkit/dropdownWithModal";
import { useIsFocused } from "@react-navigation/native";
import {
  isViewContext,
  useQuestViewContext,
} from "@app/quest/QuestViewContext";
import { useAppSelector } from "@app/store";
import { selectQuestInstanceById } from "@app/store/cache/questInstances";
import { useCurrentTimeComparison } from "@app/util/useCurrentTImeComparison";
import { sentry } from "@app/util/sentry";
import {
  SnackbarContext,
  SnackbarSeverity,
} from "@app/components/snackbar/SnackbarContext";
import { useSingleton } from "@app/util/useSingleton";

export function useV2View(
  executeCustom: (
    events: CustomItem.CustomItemEvent[]
  ) => Promise<CustomItemV2Response | undefined>,
  viewName: ViewName,
  skipRenderOnMount?: boolean
): CustomItemView<CustomItemV2Response> {
  const snackbar = useContext(SnackbarContext);
  const questContext = useQuestViewContext();
  const [eventsChangeCounter, setEventsChangeCounter] = useState(0); // todo: is there a better way?
  const eventScheduler = useSingleton(() => new EventScheduler());
  const dataSources = useSingleton(
    () => new DataSourceCollection(eventScheduler)
  );
  const actionHandlers = useSingleton(
    () => new ActionHandlerCollection(eventScheduler)
  );
  const { isInPast } = useCurrentTimeComparison();

  const disableActionComponents = useAppSelector((state) => {
    if (!isViewContext(questContext, "RUN")) {
      return false;
    }
    const questInstance = selectQuestInstanceById(
      state,
      questContext.questInstanceId
    );
    if (!questInstance) {
      return true;
    }

    const pastDueDateAndNotAllowedToSubmit =
      !questInstance.allowOverdueSubmissions &&
      questInstance.dueAt &&
      isInPast(new Date(questInstance.dueAt));

    return pastDueDateAndNotAllowedToSubmit || questInstance.archived;
  });

  const screenIsFocused = useIsFocused();
  useEffect(() => {
    if (screenIsFocused) {
      dataSources.resumeRequests();
    } else {
      dataSources.pauseRequests();
    }
    return () => dataSources.pauseRequests();
  }, [dataSources, screenIsFocused]);

  const [response, setResponse] = useState<CustomItemV2Response | undefined>(
    undefined
  );

  const [hasRequestError, setHasRequestError] = useState(false);
  const [hasErrorMappingComponents, setHasErrorMappingComponents] =
    useState(false);

  const [hasErrorMappingInlineComponent, setHasErrorMappingInlineComponent] =
    useState(false);

  const { execute, isLoading } = usePromise(() => {
    const queuedEvents = eventScheduler.nextBatch();
    eventScheduler.setEventsStatus(queuedEvents, "PENDING");
    const promise = executeCustom(queuedEvents.map((e) => e.asRawEvent()))
      .catch((e) => {
        // handle non-2xx responses
        setResponse(e);
        eventScheduler.setEventsStatus(queuedEvents, "ERROR");
        throw e;
      })
      .then((res) => {
        setResponse(res);

        if (res === undefined) {
          // script is empty so `executeCustom` does not call api and just returns undefined
          eventScheduler.setEventsStatus(queuedEvents, "ERROR");
          return;
        }

        if (res.status !== "ok") {
          // script returned an error, could be a syntax error, runtime error, schema error, etc.
          eventScheduler.setEventsStatus(queuedEvents, "ERROR");
          throw new Error(`QuestScript error: ${res.exception?.message}`);
        }

        const { value } = res;
        const eventReports = value?.eventReports ?? [];
        eventScheduler.setEventsStatus((e) => {
          const report = eventReports.find(({ id }) => id === e.id);
          switch (report?.status) {
            case "SUCCESS":
              return "SUCCESS";
            case "ERROR":
              return "ERROR";
            case "UNPROCESSED":
              // TODO: Instead of marking unprocessed DATA_REQUESTED events as SKIPPED, we should requeue them.
              //       it is not guaranteed that we will automatically requeue them, especially when they were
              //       initially queued as part of refreshing the data. Step 2 would be to avoid queuing duplicate
              //       events during processing of dependentDataSources.
              return "SKIPPED";
            default:
              return e.status;
          }
        });

        dataSources.processUpdates(value?.dependentDataSources ?? {});

        eventReports.forEach((report) => {
          // TODO: Consider trimming length of message or somehow
          //  make snackbar messages better for very long messages.
          const message = report.message?.trim().replaceAll(/\s+/g, " ");
          if (message) {
            snackbar.sendMessage(
              message,
              report.status === "SUCCESS"
                ? SnackbarSeverity.NOTICE
                : SnackbarSeverity.WARNING
            );
          }
        });

        setHasRequestError(false);
      })
      .catch((err) => {
        setHasRequestError(true);
        console.warn(`Error when rendering ${viewName}: `, err);
      })
      .finally(() => {
        // if events are in the queue, execute again
        if (eventScheduler.nextBatch().length > 0) {
          void execute().finally(() => undefined);
        }
      });

    if (
      isViewContext(questContext, "RUN") &&
      queuedEvents.some(({ type }) => type === "HANDLER_FIRED")
    ) {
      // ensure any HANDLER_FIRED events have been processed before validating items and continuing the Quest Submit flow
      questContext.preSubmitPromiseTracker.trackPromise(promise);
    }
    return promise;
  });

  const renderViewResult = response?.value?.views?.[viewName];
  let componentDefinitions: ComponentDefinition[] = [];
  let inlineComponentDefinition: InlineComponentDefinition | undefined =
    undefined;
  if (renderViewResult?.isRegistered) {
    componentDefinitions = Array.isArray(renderViewResult?.components)
      ? renderViewResult.components
      : [];
    inlineComponentDefinition = renderViewResult?.inlineComponent;
  }
  const componentModels = useMemo(
    () => {
      try {
        const _componentModels = mapDefinitionsToModels(
          componentDefinitions,
          dataSources,
          actionHandlers,
          disableActionComponents
        );

        if (hasErrorMappingComponents) {
          setHasErrorMappingComponents(false);
        }
        return _componentModels;
      } catch (e) {
        console.error(
          `Failed to map custom item components for ${viewName}:`,
          e
        );
        sentry.captureException(e, {
          extra: {
            componentDefinitions: componentDefinitions.map((d) => ({
              ...d,
              ...("value" in d
                ? { value: !!d.value ? "redacted" : d.value }
                : {}),
            })),
          },
        });
        setHasErrorMappingComponents(true);
        return [];
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [componentDefinitions, eventsChangeCounter, disableActionComponents]
  );

  const inlineComponentModel = useMemo(
    () => {
      if (!inlineComponentDefinition) {
        return undefined;
      }
      try {
        const _inlineComponentModel = mapInlineDefinitionToModel(
          inlineComponentDefinition,
          dataSources,
          actionHandlers,
          disableActionComponents
        );

        if (hasErrorMappingInlineComponent) {
          setHasErrorMappingInlineComponent(false);
        }
        return _inlineComponentModel;
      } catch (e) {
        console.error(
          `Failed to map custom item inline component for ${viewName}:`,
          e
        );
        sentry.captureException(e, {
          extra: {
            inlineComponentDefinition: {
              ...inlineComponentDefinition,
              ...("value" in inlineComponentDefinition
                ? {
                    value: !!inlineComponentDefinition.value
                      ? "redacted"
                      : inlineComponentDefinition.value,
                  }
                : {}),
            },
          },
        });
        setHasErrorMappingInlineComponent(true);
        return undefined;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inlineComponentDefinition, eventsChangeCounter, disableActionComponents]
  );

  const isLoadingRef = useRef(isLoading);
  isLoadingRef.current = isLoading;
  useEffectOnce(() => {
    const listener = () => {
      setEventsChangeCounter((c) => c + 1);
      if (!isLoadingRef.current && eventScheduler.nextBatch().length > 0) {
        void execute().finally(() => undefined);
      }
    };
    eventScheduler.subscribe(listener);
    return () => eventScheduler.unsubscribe(listener);
  });

  useEffectOnce(() => {
    if (!skipRenderOnMount) {
      void execute().finally(() => undefined);
    }
  });

  const reload = useCallback(
    (skipRefreshOfDataSources = false) => {
      /** Clear out any errors that were previously encountered when asked to reload. Some scenarios reload is triggered include:
       *  - There are changes to the script
       *  - When a user clicks the refresh button (in edit mode)
       *  - The user clicks the "Retry" button when they see an error.
       */
      dataSources.clearErrorRetries();

      if (!skipRefreshOfDataSources) {
        /**
         * Reset the data sources that have been refreshed this session. This will trigger them to be refreshed again.
         */
        dataSources.refreshData();
      }

      return execute();
    },
    [dataSources, execute]
  );

  return {
    isRegistered: renderViewResult?.isRegistered,
    rawResponse: response,
    hasError: hasRequestError || hasErrorMappingComponents,
    isLoading,
    components: componentModels,
    inlineComponent: inlineComponentModel,
    reload,
  };
}

function mapInlineDefinitionToModel(
  inlineComponentDefinition: InlineComponentDefinition,
  dataSources: DataSourceCollection,
  actionHandlers: ActionHandlerCollection,
  disableActionComponents: boolean
): CustomItemInlineComponentModel {
  switch (inlineComponentDefinition.type) {
    case "Checkable": {
      const isLoading = (
        inlineComponentDefinition.dependentDataSourceIds ?? []
      ).some((id) => dataSources.get(id).isLoading());
      const onChangeHandlerManager = actionHandlers.get<boolean>(
        inlineComponentDefinition.handlers?.onChange
      );

      const inFlightChangeEvent = onChangeHandlerManager.lastEvent;

      return {
        id: inlineComponentDefinition.id,
        type: "Checkable",
        value: inFlightChangeEvent?.isInFlight()
          ? inFlightChangeEvent.payload!
          : Boolean(inlineComponentDefinition.value),
        onChange: onChangeHandlerManager.handler,

        loading: isLoading,
        disabled: disableActionComponents,
      };
    }
    case "Audio": {
      return {
        id: inlineComponentDefinition.id,
        type: "Audio",
        url: inlineComponentDefinition.url,
        trackTitle: inlineComponentDefinition.trackTitle,
        artist: inlineComponentDefinition.artist,
        artwork: inlineComponentDefinition.artwork,
        loading: false,
        disabled: disableActionComponents,
      };
    }
    default:
      throw createErrorForMissingComponentType(inlineComponentDefinition);
  }
}

function mapDefinitionsToModels(
  componentDefinitions: ComponentDefinition[],
  dataSources: DataSourceCollection,
  actionHandlers: ActionHandlerCollection,
  disableActionComponents: boolean
): CustomItemComponentModel[] {
  return componentDefinitions.map((component) => {
    switch (component.type) {
      case "dropdown": {
        const isLoading = (component.dependentDataSourceIds ?? []).some((id) =>
          dataSources.get(id).isLoading()
        );
        const onSelectHandlerManager = actionHandlers.get<DropdownValue>(
          component.handlers?.onSelect
        );
        const onSearchHandlerManager = actionHandlers.get<string>(
          component.handlers?.onSearch
        );

        const inFlightSelectEvent = onSelectHandlerManager.lastEvent;

        return {
          ...mapStaticProps(component),
          value: inFlightSelectEvent?.isInFlight()
            ? inFlightSelectEvent.payload!
            : component.value,
          options: component.options,
          onSelect: onSelectHandlerManager.handler,
          onSearchChange: onSearchHandlerManager.handler,

          isLoading: isLoading,
          disabled: disableActionComponents,
        };
      }
      case "button": {
        const onPress = actionHandlers.get<void>(component.handlers?.onPress);
        const isLoading = onPress.lastEvent?.isInFlight() ?? false;

        return {
          ...mapStaticProps(component),
          onPress: (_pressEvent: unknown) => onPress.handler(),
          disabled: disableActionComponents,
          loading: isLoading,
          success: component.success ?? false,
        };
      }
      case "switch": {
        const onSwitch = actionHandlers.get<boolean>(
          component.handlers?.onSwitch
        );
        const isLoading = onSwitch.lastEvent?.isInFlight() ?? false;

        return {
          ...mapStaticProps(component),
          value:
            (isLoading ? onSwitch.lastEvent!.payload : component.value) ??
            false,
          onSwitch: onSwitch.handler,
          loading: isLoading,
          readOnly: isLoading || disableActionComponents,
        };
      }
      case "ItemPicker": {
        const onSelect = actionHandlers.get<string>(
          component.handlers?.onSelect
        );
        const isLoading = onSelect.lastEvent?.isInFlight() ?? false;

        return {
          ...mapStaticProps(component),
          value:
            (isLoading ? onSelect.lastEvent!.payload : component.value) ??
            undefined,
          onSelect: onSelect.handler,
          loading: isLoading,
          readOnly: isLoading || disableActionComponents,
        };
      }
      case "QuestDataPicker": {
        const onSelect = actionHandlers.get<
          QuestDataIdentifier | null | undefined
        >(component.handlers?.onSelect);
        const isLoading = onSelect.lastEvent?.isInFlight() ?? false;

        return {
          ...mapStaticProps(component),
          value: isLoading ? onSelect.lastEvent!.payload : component.value,
          onSelect: onSelect.handler,
          loading: isLoading,
          readOnly: isLoading || disableActionComponents,
        };
      }
      case "ShortAnswer":
      case "LongAnswer": {
        const onChange = actionHandlers.get<string>(
          component.handlers?.onChange,
          {
            debounceMs: 500,
          }
        );

        const useLocalValue = onChange.isInFlight || onChange.isDebouncing;

        return {
          ...mapStaticProps(component),
          value: (useLocalValue ? onChange.lastPayload : component.value) ?? "",
          onChange: onChange.handler,
          loading: onChange.isInFlight,
          readOnly: disableActionComponents,
        };
      }
      case "Checkable": {
        const onChange = actionHandlers.get<boolean>(
          component.handlers?.onChange
        );

        const useLocalValue = onChange.isInFlight || onChange.isDebouncing;

        return {
          ...mapStaticProps(component),
          value:
            (useLocalValue ? onChange.lastPayload : component.value) ?? false,
          onChange: onChange.handler,
          loading: onChange.isInFlight,
          disabled: disableActionComponents,
        };
      }
      case "Card": {
        return {
          ...mapStaticProps(component),
          rows: component.rows,
        };
      }
      case "text":
      case "CopyText":
      case "NavigationAction":
        return mapStaticProps(component);
      default:
        throw createErrorForMissingComponentType(component);
    }
  });
}

/**
 * Throws an error for an unknown component type.
 * Specifying the param as `never` ensures that a type error will occur if a new component type is added
 * without updating this function.
 */
function createErrorForMissingComponentType(component: never) {
  return new Error(
    `Unknown component type: ${(component as { type: string }).type}`
  );
}
