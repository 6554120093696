import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import styled, { useTheme } from "styled-components/native";
import Icon from "@app/components/icon";
import { Boundary } from "@app/components/screen/boundary";
import TextInput from "@app/components/questkit/textInput";
import HeaderText from "@app/components/questkit/headerText";
import Loader from "@app/components/animated/loader";
import PlaceholderView from "@app/components/screen/PlaceholderView";
import {
  SnackbarContext,
  SnackbarSeverity,
} from "@app/components/snackbar/SnackbarContext";
import {
  UserPicker,
  UserPickerController,
} from "@app/components/questkit/UserList/UserPicker";
import { useAppNavigation, useAppRoute } from "@app/navigation/QMNavigator";
import {
  addWorkspaceMember,
  deleteWorkspaceMember,
  fetchWorkspace,
  patchWorkspaceMember,
  updateWorkspace,
} from "@app/util/client/requests/workspaces";
import QKScrollView from "@app/components/questkit/ScrollView";
import { selectWorkspaceWithMemberships } from "@app/store/cache/workspaces";
import { useAppSelector } from "@app/store";
import { fetchTeam } from "@app/util/client/requests/teams";
import { noopRequest, useRequest } from "@app/util/client/requests";
import { selectTeamById } from "@app/store/cache/teams";
import { createLink } from "@app/util/link.utils";
import { useStateWithRef } from "@app/components/questkit/useStateWithRef";
import { UserPickerEntryProps } from "@app/components/questkit/UserList/StandardUserEntry";
import {
  MemberEditModalProps,
  MemberUserEntry,
} from "@app/teams/MemberUserEntry";
import { usePromise } from "@app/util/usePromise";
import { PlaceholderHeaderIcon } from "@app/navigation/components/HeaderIcon";
import Modal from "@app/components/modal";
import Button from "@app/components/questkit/button";
import {
  type StandardDropdownListItem,
  StandardInlineDropdown,
} from "@app/components/questkit/dropdown/StandardInlineDropdown";
import { WorkspaceMembershipRole, Workspaces } from "@questmate/openapi-spec";
import { sentry } from "@app/util/sentry";
import ListItem from "@app/components/questkit/listItem";
import { ENV } from "@app/config/env";
import isEqual from "react-fast-compare";

const TEAMS_ENABLED = ENV.featureFlags.enableTeams;

export const WorkspaceSettingsScreen: React.FC = () => {
  const navigation = useAppNavigation();
  const workspaceId = useAppRoute<"Workspace">().params?.workspaceId;

  const workspace = useAppSelector((state) => {
    const workspace = selectWorkspaceWithMemberships(state, workspaceId);
    if (!workspace) {
      return workspace;
    }
    return {
      ...workspace,
      team: workspace.teamId ? selectTeamById(state, workspace.teamId) : null,
    };
  }, isEqual);
  const memberships = workspace?.memberships;
  const membershipsRef = useRef(memberships);
  membershipsRef.current = memberships;
  const cachedName = workspace?.name;
  const [workspaceName, setWorkspaceName, workspaceNameRef] = useStateWithRef(
    cachedName || ""
  );

  const { isValidating, refresh: refreshWorkspace } = useRequest(
    fetchWorkspace(workspaceId)
  );
  if (TEAMS_ENABLED) {
    useRequest(workspace?.teamId ? fetchTeam(workspace.teamId) : noopRequest());
  }

  const userPickerRef = useRef<UserPickerController>(null);

  const theme = useTheme();
  const snackbarContext = useContext(SnackbarContext);

  const onAddWorkspaceMember = useCallback(
    async (userId: string) => {
      return addWorkspaceMember(workspaceId, [userId]).catch((error) => {
        let snackbarMessage: string;
        switch (error?.message) {
          case "FREE_MEMBER_LIMIT_EXCEEDED":
            snackbarMessage =
              "You've reached your free workspace membership limit.";
            break;
          case "NO_ACTIVE_SUBSCRIPTION":
            snackbarMessage =
              "Workspace owner must have an active subscription to add members.";
            break;
          case "Must be Admin or Owner to add workspace members.":
            snackbarMessage = "You do not have permission to add members.";
            break;
          default:
            snackbarMessage = "Could not add member.";
        }

        snackbarContext.sendMessage(snackbarMessage, SnackbarSeverity.WARNING);
        userPickerRef.current?.remove([{ userId }]);
        void refreshWorkspace();
      });
    },
    [refreshWorkspace, snackbarContext, workspaceId]
  );

  const onDeleteWorkspaceMember = useCallback(
    (memberUserId: string) => {
      const membershipId = membershipsRef.current?.find(
        (membership) => membership?.userId === memberUserId
      )?.id;
      if (!membershipId) {
        // This should not happen, but is technically possible.
        sentry.captureMessage(
          "Failed to delete workspace member: Could not find membershipId for memberUserId",
          {
            extra: { workspaceId, memberUserId },
          }
        );
        snackbarContext.sendMessage(
          "Failed to delete workspace member",
          SnackbarSeverity.WARNING
        );
        userPickerRef.current?.add([{ userId: memberUserId }]);
        void refreshWorkspace();
        return Promise.resolve({ success: false });
      }

      return deleteWorkspaceMember(workspaceId, membershipId)
        .then(() => ({ success: true }))
        .catch((error) => {
          let snackbarMessage: string;
          switch (error?.message) {
            case "User may not delete themselves from a workspace.":
              snackbarMessage = "You may not delete yourself from a workspace.";
              break;
            case "You cannot remove the owner of a workspace.":
              snackbarMessage = "You may not delete the owner of a workspace.";
              break;
            case "Must be Admin or Owner to delete workspace members.":
              snackbarMessage = "You do not have permission to delete members.";
              break;
            default:
              snackbarMessage = "Couldn't delete member.";
          }

          snackbarContext.sendMessage(
            snackbarMessage,
            SnackbarSeverity.WARNING
          );
          userPickerRef.current?.add([{ userId: memberUserId }]);
          void refreshWorkspace();
          return { success: false };
        });
    },
    [refreshWorkspace, snackbarContext, workspaceId]
  );

  const updateWorkspaceMember = useCallback(
    (
      memberUserId: string,
      fields: Workspaces.MembershipsPartialUpdate.RequestBody
    ) => {
      const membershipId = membershipsRef.current?.find(
        (membership) => membership?.userId === memberUserId
      )?.id;
      if (!membershipId) {
        // This should not happen, but is technically possible.
        sentry.captureMessage(
          "Failed to update workspace member: Could not find membershipId for memberUserId",
          {
            extra: { workspaceId, memberUserId },
          }
        );
        snackbarContext.sendMessage(
          "Failed to update workspace member",
          SnackbarSeverity.WARNING
        );
        void refreshWorkspace();
        return Promise.resolve({ success: false });
      }
      return patchWorkspaceMember(workspaceId, membershipId, fields)
        .then(() => ({ success: true }))
        .catch((error) => {
          let snackbarMessage: string;
          switch (error?.message) {
            case "Transferring workspace ownership not yet supported.":
              snackbarMessage =
                "Transferring workspace ownership not yet supported.";
              break;
            case "You cannot remove the owner of a workspace.":
              snackbarMessage = "You may not edit the owner of a workspace.";
              break;
            case "Must be Admin to edit workspace members.":
              snackbarMessage = "You do not have permission to edit members.";
              break;
            default:
              snackbarMessage = "Could not save changes.";
          }

          snackbarContext.sendMessage(
            snackbarMessage,
            SnackbarSeverity.WARNING
          );
          void refreshWorkspace();
          return { success: false };
        });
    },
    [refreshWorkspace, snackbarContext, workspaceId]
  );

  const { execute: saveWorkspace, isLoading: isSavingWorkspace } = usePromise(
    async () => {
      const newWorkspaceName = (workspaceName || "")?.trim();
      if (!newWorkspaceName) {
        snackbarContext.sendMessage(
          "Workspace name cannot be empty.",
          SnackbarSeverity.WARNING
        );
        setWorkspaceName(cachedName!);
        return;
      }

      if (workspaceName === cachedName || newWorkspaceName === cachedName) {
        return;
      }

      return updateWorkspace(workspaceId, {
        name: newWorkspaceName,
      })
        .catch((e) => {
          console.error("Failed to update Workspace", e);
          snackbarContext.sendMessage(
            "Couldn't save workspace name. Please try again.",
            SnackbarSeverity.WARNING
          );
          setWorkspaceName(cachedName!);
        })
        .finally(refreshWorkspace);
    }
  );

  useEffect(() => {
    navigation.setOptions({
      headerTitle: workspaceName,
      headerRight: (props) => <PlaceholderHeaderIcon {...props} />,
    });
  }, [navigation, workspaceName]);

  useEffect(() => {
    if (cachedName && cachedName !== workspaceNameRef.current) {
      setWorkspaceName(cachedName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cachedName]);

  const onChangeMembers = useCallback(
    (userIds: string[]) => {
      if (!memberships) {
        return;
      }
      const existingMemberships = memberships || [];
      const previousMembersUserIds = existingMemberships.map(
        (member) => member.user.id
      );
      const membersToDelete = existingMemberships.filter(
        (member) => !userIds.includes(member.user.id)
      );
      const usersToAdd = userIds.filter(
        (userId) => !previousMembersUserIds.includes(userId)
      );

      membersToDelete.forEach((member) =>
        onDeleteWorkspaceMember(member.user.id)
      );
      usersToAdd.forEach((userId) => onAddWorkspaceMember(userId));
    },
    [onAddWorkspaceMember, onDeleteWorkspaceMember, memberships]
  );

  const workspaceMembers = useMemo(
    () =>
      memberships?.map((membership) => {
        return {
          userId: membership.user.id,
        };
      }),
    [memberships]
  );
  const onPressTeamLink = useMemo(
    () =>
      workspace?.teamId
        ? createLink({
            screen: "Team",
            params: {
              teamId: workspace.teamId,
            },
          })
        : undefined,
    [workspace?.teamId]
  );

  const renderEditModal = useCallback(
    (props: MemberEditModalProps) => {
      const updateMember = useCallback(
        (userId: string, role: string | undefined) =>
          updateWorkspaceMember(userId, {
            role: role as WorkspaceMembershipRole,
          }),
        []
      );
      return (
        <EditMemberModal
          {...props}
          deleteMember={onDeleteWorkspaceMember}
          updateMember={updateMember}
          roles={WorkspaceRoles}
        />
      );
    },
    [onDeleteWorkspaceMember, updateWorkspaceMember]
  );

  const renderMemberEntry = useCallback(
    (props: UserPickerEntryProps) => {
      const userId =
        props.entry.status === "LOOKING_UP_USER"
          ? undefined
          : props.entry.userId;
      const role = memberships?.find(
        (member) => member.userId === userId
      )?.role;
      return (
        <MemberUserEntry
          {...props}
          role={role}
          renderEditModal={renderEditModal}
        />
      );
    },
    [memberships, renderEditModal]
  );

  const rightIcon = useCallback(
    (isFocused: boolean) =>
      isSavingWorkspace ? (
        <StyledActivityIndicator
          color={isFocused ? theme.background : theme.primary}
        />
      ) : null,
    [isSavingWorkspace, theme]
  );
  if (!workspace) {
    if (isValidating) {
      return (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      );
    } else {
      return (
        <>
          <PlaceholderView
            text="Couldn't load your workspace :("
            actions={[
              {
                type: "primary",
                text: "Retry",
                loading: isValidating,
                onPress: refreshWorkspace,
              },
            ]}
          />
        </>
      );
    }
  }

  return (
    <WorkspaceSettingsScreenWrapper>
      <SafeAreaView edges={["bottom"]}>
        <AvatarContainer>
          <AvatarPlaceholder icon="group" size={80} />
        </AvatarContainer>
        <Boundary>
          <HeaderText icon="group" title="Workspace Name" />
          <WorkspaceNameInput
            placeholder="Acme Inc."
            rightIcon={rightIcon}
            value={workspaceName}
            onChangeText={setWorkspaceName}
            onBlur={saveWorkspace}
            blurOnSubmit={true}
          />
          <ActionsContainer>
            <ListItem
              text="Transfer Workspace Ownership"
              icon="move-folder"
              onPress={() => {
                snackbarContext.sendMessage(
                  "Please contact us at hello@questmate.com\nto have your workspace ownership transferred."
                );
              }}
            />
            <ListItem
              text="Delete Workspace"
              icon="trash"
              onPress={() => {
                snackbarContext.sendMessage(
                  "Please contact us at hello@questmate.com to have your workspace deleted."
                );
              }}
            />
            {TEAMS_ENABLED && workspace.teamId ? (
              <ListItem
                text="Go to Team"
                icon="group"
                onPress={onPressTeamLink}
              />
            ) : null}
          </ActionsContainer>
          {workspaceMembers === undefined ? (
            <InlineLoaderContainer>
              <Loader />
            </InlineLoaderContainer>
          ) : (
            <>
              <UserPicker
                title="Members"
                ref={userPickerRef}
                onChange={onChangeMembers}
                users={workspaceMembers}
                renderUserEntry={renderMemberEntry}
              />
            </>
          )}
        </Boundary>
      </SafeAreaView>
    </WorkspaceSettingsScreenWrapper>
  );
};

const ActionsContainer = styled.View`
  margin-bottom: 42px;
`;

const AvatarPlaceholder = styled(Icon)`
  color: ${({ theme }) => theme.background};
`;

const WorkspaceSettingsScreenWrapper = styled(QKScrollView).attrs({
  contentContainerStyle: {
    padding: 20,
  },
})`
  background-color: ${({ theme }) => theme.background};
  flex: 1;
`;

const WorkspaceNameInput = styled(TextInput)`
  margin-bottom: 10px;
`;

const AvatarContainer = styled.View`
  background-color: ${({ theme }) => theme.primary};
  width: 140px;
  height: 140px;
  border-radius: 70px;
  border-color: ${({ theme }) => theme.primary};
  border-width: 1px;
  align-self: center;
  margin-top: 25px;
  margin-bottom: 40px;
  justify-content: center;
  align-items: center;
`;

const LoaderContainer = styled.View`
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.background};
  flex: 1;
`;
const InlineLoaderContainer = styled(LoaderContainer)`
  margin-top: 40px;
`;

const StyledActivityIndicator = styled.ActivityIndicator`
  height: 40px;
  width: 40px;
  align-self: center;
`;

const Section = styled.View`
  margin-bottom: 24px;
`;
const EditMemberDialog = styled.View`
  padding: 16px;
`;

const WorkspaceRoles: Record<WorkspaceMembershipRole, string> = {
  ADMIN: "Admin",
  MEMBER: "Member",
  OWNER: "Owner",
};

export const EditMemberModal: React.FC<
  MemberEditModalProps & {
    deleteMember: (userId: string) => Promise<{
      success: boolean;
    }>;
    updateMember: (
      userId: string,
      role: string
    ) => Promise<{
      success: boolean;
    }>;
    roles: Record<string, string>;
  }
> = ({
  showModal,
  setShowModal,
  entry,
  role: roleFromCache,
  onRemove: parentOnRemove,
  deleteMember,
  updateMember,
  roles,
}) => {
  const [success, setSuccess] = useState(false);
  useEffect(() => {
    if (!showModal) {
      setSuccess(false);
    }
  }, [showModal]);
  const closeModalWithDelay = useCallback(() => {
    setTimeout(() => setShowModal(false), 400);
  }, [setShowModal]);
  const userId = entry.status === "READY" ? entry.userId : "";
  const { execute: onRemove, isLoading: isRemovingMember } = usePromise(
    async () => {
      if (entry.status !== "READY") {
        return;
      }
      return deleteMember(userId).then(({ success }) => {
        if (success) {
          setSuccess(true);
          parentOnRemove();
          closeModalWithDelay();
        }
      });
    }
  );

  const [role, setRole, roleRef] = useStateWithRef<string | undefined>(
    roleFromCache
  );

  useEffect(() => {
    if (roleFromCache && roleFromCache !== roleRef.current) {
      setRole(roleFromCache);
    }
  }, [roleFromCache, roleRef, setRole]);

  const { execute: onSave, isLoading: isSaving } = usePromise(async () => {
    if (entry.status !== "READY") {
      return;
    }
    return updateMember(userId, roleRef.current!).then(({ success }) => {
      if (success) {
        setSuccess(true);
        closeModalWithDelay();
      }
    });
  });
  const onSelectRole = useCallback(
    (selection: StandardDropdownListItem<string>) => {
      setRole(selection?.value);
    },
    [setRole]
  );

  const isLoading = isRemovingMember || isSaving;
  return (
    <Modal
      showModal={showModal}
      setShowModal={setShowModal}
      title="Edit Member"
    >
      <EditMemberDialog>
        <Section>
          <HeaderText title="Member" />
          <TextInput
            editable={false}
            value={entry.status === "READY" ? entry.displayName : "unknown"}
          />
        </Section>
        <Section>
          <HeaderText title="Role" />
          <StandardInlineDropdown
            optionNoun="Role"
            optionPluralNoun="Roles"
            value={role}
            options={Object.entries(roles).map(([role, label]) => ({
              label,
              value: role,
            }))}
            loadingOptions={false}
            onSelect={onSelectRole}
          />
        </Section>
        <Section>
          <HeaderText title="Actions" />
          <ListItem
            text="Remove Member"
            icon="trash"
            onPress={onRemove}
            disabled={isLoading}
          />
        </Section>
        <Button
          loading={isLoading}
          success={success}
          disabled={isLoading}
          title="Save"
          onPress={onSave}
        />
      </EditMemberDialog>
    </Modal>
  );
};
