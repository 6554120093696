import * as React from "react";
import styled from "styled-components/native";
import Text from "@app/components/questkit/text";
import BasePressable from "@app/components/questkit/BasePressable";
import { useLink } from "@app/util/link.utils";
import { useQuestContext } from "@app/quest/QuestContext";
import { useAppSelector } from "@app/store/index";
import { selectQuestStartTriggerEditFields } from "@app/store/cache/questStartTriggers";
import { selectQuestPrototypeById } from "@app/store/cache/questPrototypes";
import { formatCronString } from "@app/quest/start/FormatCronString";

export const ConfigureQuestTemplateCard: React.FC = () => {
  const questContext = useQuestContext({ okIfNotReady: true });
  const { questId } = questContext;
  const onConfigure = useLink({
    screen: "Quest",
    params: {
      questId,
      screen: "QuestConfiguration",
    },
  });

  const itemCount = useAppSelector((state) => {
    if (!questContext.isReady) {
      return null;
    }
    const { currentQuestPrototypeId } = questContext;
    const qp = selectQuestPrototypeById(state, currentQuestPrototypeId);
    if (!Array.isArray(qp?.itemIds)) {
      return null;
    }

    return qp.itemIds.length;
  });

  return (
    <Container>
      <HeaderRow>
        <Text size={"mediumLargeBold"}>
          Quest summary
          {itemCount !== null ? (
            <ItemCountText> ({itemCount} items)</ItemCountText>
          ) : null}
        </Text>
        <BasePressable onPress={onConfigure}>
          <CTAText size={"mediumBold"}>Configure</CTAText>
        </BasePressable>
      </HeaderRow>
      <QuestModeSummary />
    </Container>
  );
};
const ItemCountText = styled(Text).attrs({ size: "mediumLarge" })`
  color: #806ea0;
`;

export const QuestModeSummary: React.FC = () => {
  const questContext = useQuestContext({ okIfNotReady: true });

  const startTrigger = useAppSelector((state) => {
    if (!questContext.isReady) {
      return null;
    }
    const { currentQuestPrototypeId } = questContext;

    const qp = selectQuestPrototypeById(state, currentQuestPrototypeId);
    const firstStartTriggerId = qp?.startTriggerIds?.[0];

    if (!firstStartTriggerId) {
      return null;
    }

    return selectQuestStartTriggerEditFields(
      state,
      currentQuestPrototypeId,
      firstStartTriggerId
    );
  });

  if (!startTrigger) {
    return null; // TODO: Show spinner or at least take up the expected height of text.
  }

  const assignmentCount =
    startTrigger.startConfiguration?.assignmentIds.length ?? 0;
  return (
    <SummaryText>
      {startTrigger.type === "BASIC" ? (
        "Run quest when you need, complete yourself or assign to others."
      ) : startTrigger.type === "SCHEDULE" ? (
        startTrigger.config.runSchedule && assignmentCount > 0 ? (
          <>
            Runs{" "}
            <HighlightedText>
              {formatCronString(startTrigger.config.runSchedule, true)}
            </HighlightedText>
            . Assigned to&nbsp;
            <HighlightedText>
              {assignmentCount === 1 ? "1 person" : `${assignmentCount} people`}
            </HighlightedText>
            .
          </>
        ) : (
          "Configure the schedule for this Quest to run automatically."
        )
      ) : startTrigger.type === "PUBLIC" ? (
        <>Share a public link with anyone: {startTrigger.config.publicUrl}.</>
      ) : startTrigger.type === "KIOSK" ? (
        "Use kiosk mode for quests that are always ready for someone to walk up and fill out."
      ) : (
        "Update your app to see the latest features."
      )}
    </SummaryText>
  );
};

const HighlightedText = styled(Text).attrs({ size: "smallBold" })`
  text-decoration: underline;
  color: #806ea0;
`;

const SummaryText = styled(Text).attrs({ size: "small" })`
  color: #806ea0;
  margin-top: 12px;
`;
const CTAText = styled(Text)`
  color: #511ead;
`;
const HeaderRow = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const Container = styled.View`
  border-color: #d9d9d9;
  border-width: 1px;
  border-radius: 22px;
  padding: 25px 20px 20px;
`;
