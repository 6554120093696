import {
  SnackbarContext,
  SnackbarSeverity,
} from "@app/components/snackbar/SnackbarContext";
import * as Clipboard from "expo-clipboard";
import { sentry } from "@app/util/sentry";
import { useContext, useCallback } from "react";

export const useCopyLinkToKeyboard = (linkText: string) => {
  const snackbar = useContext(SnackbarContext);

  return useCallback(() => {
    Clipboard.setStringAsync(linkText!)
      .then(() => {
        snackbar.sendMessage("Link copied to clipboard");
      })
      .catch((e) => {
        snackbar.sendMessage(
          "Failed to copy link to clipboard",
          SnackbarSeverity.WARNING
        );
        sentry.captureException(e, {
          extra: {
            message: "Failed to copy link to clipboard",
            linkText,
          },
        });
      });
  }, [snackbar, linkText]);
};
