import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useRef,
  useState,
  useLayoutEffect,
} from "react";

import styled from "styled-components/native";
import { Platform, type ViewProps } from "react-native";
import {
  SchedulerInput,
  DEFAULT_INITIAL_SCHEDULE,
} from "@app/components/scheduler";
import ListItem from "@app/components/questkit/listItem";
import Switch from "@app/components/questkit/switch";
import {
  SnackbarContext,
  SnackbarSeverity,
} from "@app/components/snackbar/SnackbarContext";
import { Analytics } from "@app/analytics";
import {
  extractUnsavedFields,
  isChangeSetTrackingValidValue,
  useChangeTracker,
  UseChangeTrackerResult,
} from "@app/quest/edit/useChangeTracker";
import { usePromise } from "@app/util/usePromise";
import { AssigneePicker } from "@app/components/questkit/AssigneePicker";
import Button from "@app/components/questkit/button";
import { UserPickerController } from "@app/components/questkit/UserList/UserPicker";
import {
  selectQuestStartTriggerByComboId,
  type StartTriggerWithConfig,
} from "@app/store/cache/questStartTriggers";
import { updateQuestStartTrigger } from "@app/util/client/requests/questStartTriggers";
import Text from "@app/components/questkit/text";
import { useQuestViewContext } from "@app/quest/QuestViewContext";
import { useAppNavigation, useAppRoute } from "@app/navigation/QMNavigator";
import { QuestStartTriggerType } from "@questmate/openapi-spec";
import { useQuestContext } from "@app/quest/QuestContext";
import { useQuestPrototype } from "@app/quest/useQuestPrototype";
import PlaceholderView from "@app/components/screen/PlaceholderView";
import Loader from "@app/components/animated/loader";
import { ScreenContainer } from "@app/screens/ScreenContainer";
import { ManageQuestViewContextProvider } from "@app/quest/edit/ManageQuestViewContextProvider";
import { Boundary } from "@app/components/screen/boundary";
import BasePressable, {
  type OnPress,
} from "@app/components/questkit/BasePressable";
import { useAppSelector } from "@app/store";
import { sentry } from "@app/util/sentry";
import isEqual from "react-fast-compare";
import { createSelector } from "@reduxjs/toolkit";
import { selectQuestStartConfigurationById } from "@app/store/cache/questStartConfigurations";
import { selectAssignmentById } from "@app/store/cache/assignments";
import { isNotUndefined } from "@questmate/common";
import { selectReviewerById } from "@app/store/cache/reviewers";
import { useLink } from "@app/util/link.utils";
import { ChooseQuestStartTriggerView } from "@app/quest/start/ChooseQuestStartTriggerView";
import QKScrollView from "@app/components/questkit/ScrollView";
import { StackActions } from "@react-navigation/native";
import * as Localization from "expo-localization";
import SafeAreaView from "react-native-safe-area-view";

export const QuestEditStartConfigurationScreen: React.FC = () => {
  const {
    questId,
    currentQuestPrototypeId: rootQuestPrototypeId,
    advancedMode,
    onboarding,
  } = useQuestContext();
  const {
    questPrototypeId: questPrototypeIdFromRoute,
    startTriggerId,
    isChangingType,
  } = useAppRoute<"QuestEditStart">().params || {};
  const navigation = useAppNavigation<"QuestEditStart">();

  const questPrototypeId = questPrototypeIdFromRoute ?? rootQuestPrototypeId;
  const { questPrototype, error, isLoading, refresh } =
    useQuestPrototype(questPrototypeId);
  const snackbar = useContext(SnackbarContext);

  const startTriggerType = useAppSelector((state) => {
    return selectQuestStartTriggerByComboId(
      state,
      `${questPrototypeId}:${startTriggerId}`
    )?.type;
  });

  const onQuestTypeSelected = useCallback(
    (newType: QuestStartTriggerType) => {
      Analytics.trackEvent("Choose Quest Type", {
        questPrototypeId: questPrototypeId,
        questType: newType,
      });
      return updateQuestStartTrigger(questPrototypeId, startTriggerId, {
        type: newType,
      })
        .then(() => {
          if (newType === "SCHEDULE") {
            // Only schedule is critical for users to configure immediately.
            navigation.dispatch(
              StackActions.push("QuestEditStart", {
                questPrototypeId,
                startTriggerId,
                isChangingType: false,
              })
            );
          } else {
            navigation.navigate("QuestConfiguration", {});
          }
        })
        .catch((e) => {
          console.error("Failed to update Quest Type", e);
          snackbar.sendMessage(
            `Failed to update Quest Type. Please try again later.`,
            SnackbarSeverity.WARNING
          );
          sentry.captureException(e);
        });
    },
    [questPrototypeId, startTriggerId, navigation, snackbar]
  );

  const onCancelChangingMode = useLink({
    screen: "Quest",
    params: {
      questId,
      ...(onboarding && startTriggerType === "SCHEDULE"
        ? {
            screen: "QuestEditStart",
            params: {
              questPrototypeId,
              startTriggerId,
              isChangingType: false,
            },
          }
        : { screen: "QuestConfiguration" }),
    },
    ...(onboarding && startTriggerType === "SCHEDULE"
      ? {
          type: "PUSH",
        }
      : {}),
  });

  if (!questPrototype) {
    if (error) {
      return (
        <StyledView>
          <PlaceholderView
            text="Oops, that didn't quite work."
            actions={[
              {
                type: "primary",
                text: "Reload",
                loading: isLoading,
                onPress: refresh,
              },
            ]}
          />
        </StyledView>
      );
    } else {
      return (
        <StyledView>
          <Loader />
        </StyledView>
      );
    }
  }

  return (
    <ScreenContainer>
      <ManageQuestViewContextProvider
        questId={questId}
        questPrototypeId={questPrototypeId}
        advancedMode={advancedMode}
      >
        <StyledScrollView keyboardShouldPersistTaps="always">
          <SafeAreaView testID="quest-start-settings">
            <Boundary paddingHorizontal={20}>
              {isChangingType ? (
                <ChooseQuestStartTriggerView
                  context="CHANGE_TYPE"
                  onQuestTypeSelected={onQuestTypeSelected}
                  onCancelChangeQuestType={onCancelChangingMode}
                  currentType={startTriggerType}
                />
              ) : (
                <EditStartConfigurationView startTriggerId={startTriggerId} />
              )}
            </Boundary>
          </SafeAreaView>
        </StyledScrollView>
      </ManageQuestViewContextProvider>
    </ScreenContainer>
  );
};

const StyledScrollView = styled(QKScrollView).attrs({
  contentContainerStyle: {
    ...(Platform.OS === "ios" ? { paddingTop: 8 } : {}),
    paddingBottom: 80,
  },
})`
  background-color: ${({ theme }) => theme.background};
`;

const StyledView = styled.View`
  background-color: ${({ theme }) => theme.background};
  flex: 1;
  justify-content: center;
  align-items: center;
`;

interface EditStartConfigurationViewProps {
  startTriggerId: string;
}

export const EditStartConfigurationView: React.FC<
  EditStartConfigurationViewProps
> = ({ startTriggerId }) => {
  const navigation = useAppNavigation();

  const { questId, questPrototypeId } = useQuestViewContext(["MANAGE"]);

  const startTriggerFromServer = useAppSelector(
    (state) =>
      selectQuestStartTriggerFields(state, questPrototypeId, startTriggerId),
    isEqual
  );

  const {
    useValueWithChanges: useQuestStartTriggerWithChanges,
    addChange,
    getChangeSet,
  } = useChangeTracker(startTriggerFromServer);
  const startTrigger = useQuestStartTriggerWithChanges();

  const assigneePickerRef = useRef<UserPickerController>(null);
  const snackbar = useContext(SnackbarContext);

  const [saveSuccess, setSaveSuccess] = useState(false);
  const { execute: save, isLoading: isSaving } = usePromise(async () => {
    // Analytics.trackEvent("Save Quest Schedule");

    if (assigneePickerRef.current) {
      // Flushing will process any text in the input field
      // and update the changset.
      await assigneePickerRef.current.flush();
    }

    const changeSet = getChangeSet();
    if (!isChangeSetTrackingValidValue(changeSet)) {
      console.error(
        "Failed to save as there are no start trigger changes being tracked."
      );
      snackbar.sendMessage(
        "Failed to save. Please try again later.",
        SnackbarSeverity.WARNING
      );
      return;
    }

    const changedStartTrigger = changeSet.valueWithChanges;

    const errorMessage = getValidationErrorMessage(changedStartTrigger);

    if (errorMessage) {
      snackbar.sendMessage(errorMessage, SnackbarSeverity.WARNING);
      return;
    }

    if (changeSet.hasUnsavedChanges) {
      const unsavedFields = extractUnsavedFields(
        changeSet,
        editableFieldsForType(changedStartTrigger.type)
      );
      changeSet.markPending();
      await updateQuestStartTrigger(
        questPrototypeId,
        startTriggerId,
        unsavedFields
      )
        .then(() => {
          changeSet.markSaved();
          setSaveSuccess(true);
          snackbar.sendMessage("Settings Saved.", SnackbarSeverity.NOTICE);
          setTimeout(() => {
            navigation.navigate("Quest", {
              questId,
              screen: "QuestConfiguration",
            });
            // TODO:
            //  Remove the below workaround for the save success being stuck
            //  after navigating. I think it can be removed when we switch from
            //  a Tab Router to a Stack router.
            setTimeout(() => {
              setSaveSuccess(false);
            }, 500);
          }, 200);
        })
        .catch(() => {
          const message = "Couldn't save schedule. Try again later.";
          snackbar.sendMessage(message, SnackbarSeverity.WARNING);
          // if save failed, reset the local to previous value to avoid confusion to the user
          changeSet.rollbackChanges(changeSet.changesByStatus.pending);
        });
    } else {
      navigation.navigate("Quest", { questId, screen: "QuestConfiguration" });
    }
  });

  return startTrigger ? (
    <>
      <StartConfigurationView
        startTrigger={startTrigger}
        addChange={addChange}
        assigneePickerRef={assigneePickerRef}
        disabled={isSaving}
      />
      <Button
        testID="save-and-continue-button"
        buttonType={"primary"}
        title={"Save and continue"}
        onPress={save}
        loading={isSaving}
        success={saveSuccess}
      />
    </>
  ) : (
    <Text $warning={true}>No start trigger found</Text>
  );
};

interface StartConfigurationViewProps extends PropsWithChildren {
  startTrigger: StartTriggerFields;
  assigneePickerRef: React.Ref<UserPickerController>;
  addChange: UseChangeTrackerResult<StartTriggerFields>["addChange"];
  disabled?: boolean;
}

const StartConfigurationView: React.FC<StartConfigurationViewProps> = ({
  startTrigger,
  assigneePickerRef,
  addChange,
  disabled = false,
}) => {
  const onChangeAssignees = useCallback(
    (userIds: string[]) => {
      addChange(
        (st) =>
          (st.startConfiguration.assignees = userIds.map((userId) => ({
            userId,
          })))
      );
    },
    [addChange]
  );

  if (!startTrigger) {
    return <Text>No start trigger found</Text>;
  }

  switch (startTrigger.type) {
    case "BASIC":
      return (
        <ConfigurationSectionCard title="Basic Start Settings"></ConfigurationSectionCard>
      );

    case "SCHEDULE":
      return (
        <>
          <ConfigurationSectionCard title="Schedule">
            <EditScheduleView
              startTrigger={startTrigger}
              addChange={
                addChange as UseChangeTrackerResult<
                  StartTriggerFieldsForType<"SCHEDULE">
                >["addChange"]
              }
              disabled={disabled}
            />
          </ConfigurationSectionCard>
          <ConfigurationSectionCard title="Assignees">
            <AssigneePicker
              startConfigurationId={startTrigger!.startConfigurationId!}
              onChangeAssignees={onChangeAssignees}
              ref={assigneePickerRef}
            />
          </ConfigurationSectionCard>
        </>
      );

    case "PUBLIC":
      return (
        <ConfigurationSectionCard title="Link options">
          <ListItem
            // icon="turn-off"
            text="Enable share link"
            onPress={() => addChange((st) => (st.enabled = !st.enabled))}
            actionComponent={
              <Switch
                testID="public-url-toggle"
                switchOn={startTrigger.enabled}
                onSwitch={() => addChange((st) => (st.enabled = !st.enabled))}
                loading={false}
                readOnly={disabled}
              />
            }
          />
        </ConfigurationSectionCard>
      );

    case "KIOSK":
      return (
        <ConfigurationSectionCard title="Kiosk Mode Settings">
          <Text>Configure kiosk mode options</Text>
          {/* Add kiosk mode configuration options */}
        </ConfigurationSectionCard>
      );

    default:
      return (
        <Text>
          Unsupported trigger type: {(startTrigger as { type: string }).type}
        </Text>
      );
  }
};

interface NewCardProps extends ViewProps {
  title: string;
  ctaText?: string;
  onHeaderCTA?: OnPress;
}
export const NewCard: React.FC<NewCardProps> = ({
  title,
  ctaText,
  onHeaderCTA,
  children,
  ...containerProps
}) => {
  return (
    <Container {...containerProps}>
      <HeaderRow>
        <Text size={"mediumLargeBold"}>{title}</Text>
        {ctaText ? (
          <BasePressable onPress={onHeaderCTA}>
            <CTAText size={"mediumBold"}>{ctaText}</CTAText>
          </BasePressable>
        ) : null}
      </HeaderRow>
      {children}
    </Container>
  );
};

const CTAText = styled(Text)`
  color: #511ead;
`;
const HeaderRow = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;
const Container = styled.View`
  border-color: #d9d9d9;
  border-width: 1px;
  border-radius: 22px;
  padding: 25px 20px 20px;
`;

const ConfigurationSectionCard = styled(NewCard)`
  margin-bottom: 8px;
`;

type EditScheduleViewProps = {
  startTrigger: StartTriggerFieldsForType<"SCHEDULE">;
  addChange: UseChangeTrackerResult<
    StartTriggerFieldsForType<"SCHEDULE">
  >["addChange"];
  disabled?: boolean;
};
export const EditScheduleView: React.FC<EditScheduleViewProps> = ({
  startTrigger,
  addChange,
  disabled = false,
}) => {
  const { onboarding } = useQuestContext();
  const userDefaultTimezone = Localization.useCalendars()?.[0]?.timeZone;
  useLayoutEffect(() => {
    // Set default values on mount
    addChange((st) => {
      if (onboarding && !st.enabled) {
        st.enabled = true;
      }
      if (!st.config.runSchedule) {
        st.config.runSchedule = DEFAULT_INITIAL_SCHEDULE;
      }
      if (!st.config.runScheduleTimezone) {
        st.config.runScheduleTimezone =
          userDefaultTimezone ?? "America/Detroit";
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const toggleScheduleEnabled = useCallback(() => {
    addChange((startTrigger) => {
      const newScheduleEnabled = !startTrigger.enabled;
      startTrigger.enabled = newScheduleEnabled;
      Analytics.trackEvent(
        `${newScheduleEnabled ? "Enable" : "Disable"} Quest Schedule`
      );
    });
  }, [addChange]);

  const setScheduleAndTimezone = useCallback(
    (schedule: string, timezone: string) => {
      addChange((st) => {
        st.config.runSchedule = schedule;
        st.config.runScheduleTimezone = timezone;
      });
    },
    [addChange]
  );

  return (
    <>
      <ListItem
        text="Enable Quest Schedule"
        icon="turn-off"
        onPress={toggleScheduleEnabled}
        disabled={disabled}
        actionComponent={
          <Switch
            switchOn={startTrigger.enabled}
            onSwitch={toggleScheduleEnabled}
            testID="schedule-quest-switch"
            readOnly={disabled}
          />
        }
      />
      <SchedulerInput
        schedule={startTrigger.config.runSchedule!}
        timezone={startTrigger.config.runScheduleTimezone!}
        onChange={setScheduleAndTimezone}
        readOnly={disabled}
      />
    </>
  );
};

function getValidationErrorMessage(
  startTrigger: StartTriggerFields
): string | undefined {
  switch (startTrigger.type) {
    case "BASIC": {
      return;
    }
    case "SCHEDULE": {
      if (
        !startTrigger.config.runSchedule?.trim() &&
        startTrigger.config.runSchedule !== null
      ) {
        return "Please enter a valid schedule";
      } else if (
        startTrigger.config.runSchedule &&
        !startTrigger.config.runScheduleTimezone
      ) {
        return "Please select a timezone";
      } else if (
        startTrigger.enabled &&
        startTrigger.startConfiguration.assignees.length === 0
      ) {
        // Let them save with zero assignees if it is disabled.
        return "Please add at least one assignee.";
      }
      return;
    }
    case "PUBLIC": {
      return;
    }
    case "KIOSK": {
      return;
    }
    default: {
      return "Update the app to use this new functionality.";
    }
  }
}

const selectQuestStartTriggerFields = createSelector(
  [
    (state) => state,
    (state, questPrototypeId, startTriggerId) =>
      selectQuestStartTriggerByComboId(
        state,
        `${questPrototypeId}:${startTriggerId}`
      ),
  ],
  (state, startTrigger) => {
    if (!startTrigger) return undefined;
    let startConfiguration;
    if (startTrigger.startConfigurationId) {
      startConfiguration = selectQuestStartConfigurationById(
        state,
        startTrigger.startConfigurationId
      );
      if (startConfiguration) {
        startConfiguration = {
          ...startConfiguration,
          assignees: startConfiguration.assignmentIds
            ?.map((assignmentId) => {
              const assignment = selectAssignmentById(state, assignmentId);
              if (!assignment) {
                return undefined;
              }
              return {
                userId: assignment.assigneeId,
              };
            })
            .filter(isNotUndefined),
          reviewers: startConfiguration.reviewerIds
            ?.map((reviewerId) => {
              const reviewer = selectReviewerById(state, reviewerId);
              if (!reviewer) {
                return undefined;
              }
              return {
                userId: reviewer.userId,
              };
            })
            .filter(isNotUndefined),
        };
      }
    }
    if (startConfiguration) {
      const startTriggerEditData = {
        ...startTrigger,
        startConfiguration: startConfiguration,
      };

      return startTriggerEditData as StartTriggerWithConfig<
        typeof startTriggerEditData
      >;
    } else {
      const error = new Error(
        "Unexpected: Start Configuration not found in cache."
      );
      sentry.captureException(error, {
        extra: {
          startTrigger,
          startConfigurationsInCache: state.cache.questStartConfigurations,
        },
      });
    }
  }
);

type StartTriggerFields = Exclude<
  ReturnType<typeof selectQuestStartTriggerFields>,
  undefined
>;

type StartTriggerFieldsForType<T extends QuestStartTriggerType> =
  StartTriggerFields extends infer ST
    ? ST extends { type?: T }
      ? ST
      : never
    : never;

function editableFieldsForType(startTriggerType: QuestStartTriggerType) {
  switch (startTriggerType) {
    case "BASIC":
      return [["startConfiguration", "assignees"]];
    case "SCHEDULE":
      return [
        ["enabled"],
        ["config", "runSchedule"],
        ["config", "runScheduleTimezone"],
        ["startConfiguration", "assignees"],
      ];
    case "PUBLIC":
      return [["enabled"]];
    case "KIOSK":
      return [];
    default:
      return [];
  }
}
